import { connect } from 'react-redux'
import {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { 
  Typography,
  Button
} from '@material-ui/core'
import { getFreeDiskspace, getTargetsChart } from '../actions'
import { useEffect } from 'react';
import MyResponsivePie from './piechart';
import DashboardSidebar from './dashboardSidebar';
import { getPercent } from '../commons';
import { getThemeProps } from '@material-ui/styles';
import { theme } from '../themes/theme';

import { FiExternalLink } from 'react-icons/fi'; 
import { MdAddCircle } from 'react-icons/md'; 
const externalLink = <FiExternalLink />;
const addNew = <MdAddCircle size={40} />;



const useStyles = makeStyles((theme, props) => ({
  dataContainer: {
    padding: "30px",
    height: "100%",
    width: "100%",
  },
  row: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start"
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  optionsRow: {
      display: "flex",
      width: "20%",
      height: "48px",
      paddingLeft: "3%",
      alignItems: "center"
  },
  btnRow: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
      alignItems: "center"
  },
  title: {
    fontFamily: theme.typography.listTitle.fontFamily,
    fontSize: theme.typography.listTitle.fontSize,
    fontWeight: theme.typography.listTitle.fontWeight,
    lineHeight: theme.typography.listTitle.lineHeight,
    letterSpacing: theme.typography.listTitle.letterSpacing,
    color: theme.palette.onSurface.mediumEmphasis,
    marginBottom: "25px"
  },
  smalltitle: {
    fontFamily: theme.typography.listTitle.fontFamily,
    fontSize: theme.typography.listTitle.fontSize,
    fontWeight: theme.typography.listTitle.fontWeight,
    lineHeight: theme.typography.listTitle.lineHeight,
    letterSpacing: theme.typography.listTitle.letterSpacing,
    color: theme.palette.background.text,
    marginBottom: "5px"
  },  
  subtitle: {
    fontSize: theme.typography.listTitle.fontSize,
    marginTop: "30px"
  },
  resources: {
    marginBottom: "4px",
    color: theme.palette.primary.medium,
  },
  primaryBtn: {
    "&:hover": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    }
  },  
}));

function Dashboard(props) {
  const classes = useStyles()
  const [data, setData] = useState([])
  const [percentage, setPercentage] = useState()

 useEffect(()=>{
   props.getFreeDiskspace()
   props.getTargetsChart()
 }, [props.user])
 
 // replace hardcoded value
 useEffect(()=>{
   const percentage = getPercent(props.freeDiskspace?.diskSpace, 53687091)
   setPercentage(percentage)
 }, [props.freeDiskspace])

  useEffect(()=>{
    setData([
      {
        "id": "running",
        "label": "running",
        "value": props.targetsChart?.count?.[3]?.count,
        "color": "rgb(4,32,64)", // mauro
      },
      {
        "id": "pending",
        "label": "pending",
        "value": props.targetsChart?.count?.[0]?.count,
        "color": theme.palette.primary.light, // mauro 
      },
      {
        "id": "successful",
        "label": "successful",
        "value": props.targetsChart?.count?.[1]?.count,
        "color": "rgb(12,96,192)", // mauro
      },
      {
        "id": "failed",
        "label": "failed",
        "value": props.targetsChart?.count?.[2]?.count,
        "color": theme.palette.error.main, // mauro
      },
    ]
  )
  }, [props.targetsChart])


  return (
    <>
        <div className={classes.container}>
            <div className={classes.btnRow}>
                <div className={classes.optionsRow}>
                    <Typography 
                        variant="subtitle2" 
                        className="options"
                    >
                        Help
                    </Typography>
                </div>
            </div>
        </div>
        <div className={classes.dataContainer}>
            <Typography 
                className={classes.title}
            >
                Overview
            </Typography>
            <Typography 
                variant="h5"
            >
                Welcome back {props.user.name} {props.user.lastName}!
            </Typography>
            <Typography 
                variant="subtitle1"
                className={classes.subtitle}
            >
                Target analysis status
            </Typography>
            <div className={classes.row}>
                <div className={classes.column}>
                    <MyResponsivePie data={data}/>
                    <Typography variant="subtitle2">
                        <Typography className={classes.smalltitle}>                      
                          Useful Resources
                        </Typography>
                        <p className={classes.resources}>
                        <Button 
                            variant="contained" 
                            disableElevation 
                            color="primary"
                            className={classes.primaryBtn}
                            onClick={()=>{window.open("https://www.ebi.ac.uk/biomodels/", "_blank")}}
                        >
                            <Typography variant="button">                        
                                EMBL-EBI BioModels {externalLink}
                            </Typography>
                        </Button>    
                        &nbsp;
                        <Button 
                            variant="contained" 
                            disableElevation 
                            color="primary"
                            className={classes.primaryBtn}
                            onClick={()=>{window.open("http://bigg.ucsd.edu/", "_blank")}}
                        >
                            <Typography variant="button">                        
                                BiGG Models {externalLink}
                            </Typography>
                        </Button>    
                        </p>
                        <p className={classes.resources}>
                        <Button 
                            variant="contained" 
                            disableElevation 
                            color="primary"
                            className={classes.primaryBtn}
                            onClick={()=>{window.open("https://www.vmh.life/", "_blank")}}
                        >
                            <Typography variant="button">                        
                                Virtual Metabolic Human {externalLink}
                            </Typography>
                        </Button>       
                        &nbsp;
                        <Button 
                            variant="contained" 
                            disableElevation 
                            color="primary"
                            className={classes.primaryBtn}
                            onClick={()=>{window.open("https://hmdb.ca/", "_blank")}}
                        >
                            <Typography variant="button">                        
                                Human Metabolome Database {externalLink}
                            </Typography>
                        </Button>  
                        </p>
                        <p className={classes.resources}>
                          <Button 
                              onClick={()=>{}}
                              disabled={true}
                          >
                            {addNew}
                          </Button>
                          Add Button Here
                        </p>
                    </Typography>                
                </div>
                <DashboardSidebar 
                  diskspace={props.freeDiskspace?.diskSpace} 
                  percentage={percentage}
                />
            </div>
        </div>
    </>
   );
}
const mapStateToProps = (state ) => {
  return {
    user: state.updates.user,
    freeDiskspace: state.updates.freeDiskspace,
    targetsChart: state.updates.targetsChart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getFreeDiskspace: (data) => {dispatch(getFreeDiskspace(data))},
      getTargetsChart: (data) => {dispatch(getTargetsChart(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
