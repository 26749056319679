const initialState = {
  token: localStorage.getItem('token'),
  permissions: JSON.parse(localStorage.getItem('permissions') || '{}' ),
  success: null,
  modelId: null,
  user: JSON.parse(localStorage.getItem('user') || '{}' ),
  nbFileC: null,
  nbFileF: null,
  image: null
};

const updates = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_PERMISSIONS': {
      return {
        ...state,
        permissions: action.payload
      }
    }
    case 'STORE_TOKEN': {
      return {
        ...state,
        token: action.payload
      }
    }
    case 'STORE_USER': {
      return {
        ...state,
        user: action.payload
      }
    }
    case 'USER_INFO_LOADED': {
      return {
        ...state,
        userInfo: action.payload
      }
    }
    case 'USER_INFO_UPDATED': {
      return {
        ...state,
        updatedUserInfo: action.payload
      }
    }
    case 'NOTIFICATIONS_TOGGLED': {
      return {
        ...state,
        updatedUserInfo: action.payload
      }
    }
    case 'MODELS_LOADED': {
      return {
        ...state,
        models: action.payload
      }
    }
    case 'PHENOTYPES_LOADED': {
      return {
        ...state,
        phenotypes: action.payload
      }
    }
    case 'PHENOTYPE_LOADED': {
      return {
        ...state,
        phenotype: action.payload
      }
    }
    case 'PHENOMODEL_LOADED': {
      return {
        ...state,
        modelPhenotypes: action.payload
      }
    }
    case 'STORE_PHENO_IDS': {
      return {
        ...state,
        phenoIds: action.payload
      }
    }
    case 'STORE_PHENO_S3': {
      return {
        ...state,
        phenoS3: action.payload
      }
    }
    case 'STORE_PHENO_TO_ASSIGN': {
      return {
        ...state,
        phenoAssign: action.payload
      }
    }
    case 'RESET_PHENO_TO_ASSIGN': {
      return {
        ...state,
        phenoAssign: null
      }
    }
    case 'PHENOTYPE_UPDATED': {
      return {
        ...state,
        updatedPhenotype: action.payload
      }
    }
    case 'PHENOTYPE_DELETED': {
      return {
        ...state,
        phenotypes: action.payload
      }
    }
    case 'PHENOTYPE_CREATED': {
      return {
        ...state,
        phenotype: action.payload
      }
    }
    case 'DATASET_DELETED': {
      return {
        ...state,
        deletedDataset: action.payload
      }
    }
    case 'PHENOTYPE_ARCHIVED': {
      return {
        ...state,
        phenotypes: action.payload
      }
    }
    case 'PHENOTYPE_CLONED': {
      return {
        ...state,
        clonedPhenotype: action.payload
      }
    }
    case 'RESET_PHENOTYPE': {
      return {
        ...state,
        phenotype: null
      }
    }
    case 'TARGETS_LOADED': {
      return {
        ...state,
        targets: action.payload
      }
    }
    case 'TARGET_LOADED': {
      return {
        ...state,
        target: action.payload
      }
    }
    case 'TARGET_CREATED': {
      return {
        ...state,
        newTarget: action.payload
      }
    }
    case 'TARGET_UPDATED': {
      return {
        ...state,
        updatedTarget: action.payload
      }
    }
    case 'TARGET_ARCHIVED': {
      return {
        ...state,
        targets: action.payload
      }
    }
    case 'TARGET_DELETED': {
      return {
        ...state,
        warningMsg: !Array.isArray(action.payload) ? action.payload : null,
        targetId: action.targetId,
        targets: Array.isArray(action.payload) ? action.payload : state.targets
      }
    }
    case 'TARGET_RAN': {
      return {
        ...state,
        notebook: action.payload,
      }
    }
    case 'RESET_WARNING': {
      return {
        ...state,
        warningMsg: null,
        targetId: null
      }
    }
    case 'NOTEBOOKS_LOADED': {
      return {
        ...state,
        notebooks: action.payload
      }
    } 
    case 'NOTEBOOK_LOADED': {
      return {
        ...state,
        notebook: action.payload
      }
    }
    case 'NOTEBOOK_UPDATED': {
      return {
        ...state,
        updatedNotebook: action.payload
      }
    }
    case 'NOTEBOOK_ARCHIVED': {
      return {
        ...state,
        notebooks: action.payload
      }
    }
    case 'NOTEBOOK_DELETED': {
      return {
          ...state,
          warningMsg: !Array.isArray(action.payload) ? action.payload : null,
          notebookId: action.targetId,
          notebooks: Array.isArray(action.payload) ? action.payload : state.notebooks
      }
    }
    case 'ARCHIVE_LOADED': {
      
      return {
        ...state,
        archive: action.payload,
      }
    }
    case 'ALL_MODELS_LOADED': {
      return {
        ...state,
        allModels: action.payload
      }
    }
    case 'PUBLIC_MODELS_LOADED': {
      return {
        ...state,
        publicModels: action.payload
      }
    }
    case 'ALL_PHENOTYPES_LOADED': {
      return {
        ...state,
        allPhenotypes: action.payload
      }
    }
    case 'ALL_TARGETS_LOADED': {
      return {
        ...state,
        allTargets: action.payload
      }
    }
    case 'ALL_NOTEBOOKS_LOADED': {
      return {
        ...state,
        allNotebooks: action.payload
      }
    }
    case 'LOGIN': {
      return {
        ...state,
        loggedInUser: action.payload
      }
    }
    case 'MODEL_CREATED': {
      return {
        ...state,
        newModel: action.payload
      }
    }
    case 'MODEL_LOADED': {
      return {
        ...state,
        model: action.payload
      }
    }
    case 'MODEL_UPDATED': {
      return {
        ...state,
        updatedModel: action.payload,
      }
    }
    case 'MODEL_UPLOADED': {
      return {
        ...state,
        model: action.payload
      }
    }
    case 'PUBLIC_MODEL_UPLOADED': {
      return {
        ...state,
        publicModel: action.payload
      }
    }
    case 'MODEL_CLONED': {
      return {
        ...state,
        models: action.payload
      }
    }
    case 'MODEL_DELETED': {
      return {
        ...state,
        warningMsg: action.payload.msg,
        modelId: action.modelId,
        models: action.payload.msg ? state.models : action.payload
      }
    }
    case 'MODEL_ARCHIVED': {
      return {
        ...state,
        models: action.payload
      }
    }
    case 'NOTIFICATIONS_LOADED': {
      return {
        ...state,
        unreadNotifications: action.payload
      }
    }
    case 'NOTIFICATION_READ': {
      return {
        ...state,
        unreadNotifications: action.payload
      }
    }
    case 'RESET_TARGET': {
      return {
        ...state,
        target: action.payload
      }
    }
    case 'RESET_NEW_TARGET': {
      return {
        ...state,
        newTarget: action.payload
      }
    }
    case 'RESET_PUBLIC_MODEL': {
      return {
        ...state,
        publicModel: null
      }
    }
    case 'DISKSPACE_LOADED': {
      return {
        ...state,
        freeDiskspace: action.payload
      }
    }
    case 'TARGETS_CHART_LOADED': {
      return {
        ...state,
        targetsChart: action.payload
      }
    }
    case 'NB_FILE_LOADED': {
      let tableC
      let tableF
      let image
      if (action.name === "tableC") {
        tableC = action.payload
      } else  if (action.name === "tableF") {
        tableF = action.payload
      } else {
        image = action.payload
      }
    
      return {
        ...state,
        nbFileC: tableC,
        nbFileF: tableF,
        image: image
      }
    }
    case 'IMAGE_RESET': {
      return {
        ...state,
        image: null
      }
    }
    default:
      return state;
  }
}

export default updates