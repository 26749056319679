import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getModel, updateModel, createPhenotype, getPhenotype, resetPhenotype } from '../actions'
import { useParams, useHistory } from 'react-router-dom'
import { 
    Paper,
    Typography,
    Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone-uploader'
import ModelStatus from './modelstatus'
//import uploadIcon from './assets/upload-icon.svg'
//import libraryIcon from './assets/library-icon.svg'
//import publicIconDisabled from './assets/public-icon-disabled.svg'
import targetIconWhite from './assets/target-icon-white.svg'
import phenotypeIconWhite from './assets/phenotypes-icon-white.svg'
import phenotypeIconDisabled from './assets/phenotypes-icon-disabled.svg'
import targetIconDisabled from './assets/target-icon-disabled.svg'

import { FiUpload, FiBookOpen } from 'react-icons/fi';
import { BsGlobe } from 'react-icons/bs';
import { BiTargetLock } from 'react-icons/bi';  
import { RiFingerprintLine } from 'react-icons/ri'; 
const uploadIcon = <FiUpload />;
const libraryIcon = <FiBookOpen />;
const publicIconDisabled = <BsGlobe />;
const targetsIcon = <BiTargetLock />;
const phenotypesIcon = <RiFingerprintLine/>;



const useStyles = makeStyles((theme, props) => ({
    row: {
        display: "flex",
        width: "100%"
    },
    root: {
        padding: 0,
    },
    container: {
        height: "100%",
        width: "35%", 
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "0px", // mauro changed it was 50px
        marginTop: "20px",
        padding: "0px 60px" // mauro changed it was 100px
    },
    btnRow: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%"
    },
    primaryBtn: {
        height: "36px",
        margin: "5px 0px",
        "&:hover": {
            background: theme.palette.primary.main
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.onPrimary.disabled
        }
    },
    input: {
        width: "100%",
        height: "100%",
    },
    label: {
        width: "100%",
        height: "100%",
    },
    dropzone: {
        width: "100%"
    },
    error: {
        backgroundColor: "red"
    },
    success: {
        background: theme.palette.primary.main
    },
    buttonContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: 'wrap',
        cursor: "pointer" // mauro
    },
    icon: {
        color: theme.palette.primary.contrastText,
        marginRight: "5px"
    },
    iconDisabled: {
        color: theme.palette.primary.light,
        marginRight: "5px"
    }    
  }));

function ModelSelect(props) {
    const classes = useStyles()
    const params = useParams()
    const history = useHistory()    
    const [uploadStatus, setUploadStatus] = useState("")

    const getUploadParams = (fileWithMeta) => { 
        const file = new FormData() 
        file.append('file', fileWithMeta.file) 
    
        return { 
            url: process.env.REACT_APP_API_URL+'/api/model/upload', 
            method: 'post',
            file, 
            fields: {modelId: params.modelId},
            headers:{'X-Token': localStorage.getItem("token")} ,
        } 
    }

    // checks the status of the model upon mounting of the component
    useEffect(()=>{
        if (props.publicLibraryProgress === "in progress") {
            props.setValidationStatus("in progress")
            props.setDistributionStatus("in progress")
        } else if (props.publicLibraryProgress === "reset") {
            if (props.publicModel?.validation === 1 && props.publicModel?.ssdistribution === 3) {
                props.setValidationStatus("done")
                props.setDistributionStatus("in progress")
            } else if (props.publicModel?.validation === 0 && props.publicModel?.ssdistribution === 0) {
                props.setValidationStatus("pending")
                props.setDistributionStatus("pending")
            } else if (props.publicModel?.validation === 1 && props.publicModel?.ssdistribution === 3) {
                props.setValidationStatus("done")
                props.setDistributionStatus("in progress")
            } else if (props.publicModel?.validation === 1 && props.publicModel?.ssdistribution === 1) {
                props.setValidationStatus("done")
                props.setDistributionStatus("done")
            } else if (props.publicModel?.validation === 1) {
                props.setValidationStatus("done")
            } else if (props.publicModel?.validation === 2) {
                props.setValidationStatus("error")
                props.setDistributionStatus("error")
            } else if (props.publicModel?.validation === 3 && props.publicModel?.ssdistribution === 3) {
                props.setValidationStatus("in progress")
                props.setDistributionStatus("in progress")
            } 
        } else if (props.model?.validation === 0 && props.model?.ssdistribution === 0) {
            props.setValidationStatus("pending")
            props.setDistributionStatus("pending")
        } else if (props.model?.validation === 1 && props.model?.ssdistribution === 3) {
            props.setValidationStatus("done")
            props.setDistributionStatus("in progress")
        } else if (props.model?.validation === 1 && props.model?.ssdistribution === 1) {
            props.setValidationStatus("done")
            props.setDistributionStatus("done")
        } else if (props.model?.validation === 1) {
            props.setValidationStatus("done")
        } else if (props.model?.validation === 2) {
            props.setValidationStatus("error")
            props.setDistributionStatus("error")
        } else if (props.model?.validation === 3 && props.model?.ssdistribution === 3) {
            props.setValidationStatus("in progress")
            props.setDistributionStatus("in progress")
        } 
    }, [props.model, props.publicLibraryProgress, props.publicModel])

    useEffect(()=>{
        props.resetPhenotype()
    }, [])

    useEffect(()=>{
        if (props.phenotype) {
            history.push(`/phenotypes/${props.phenotype?.id}`)
        }
    }, [props.phenotype])

// called every time a file's `status` changes
const handleChangeStatus = ({ meta, file, xhr, remove }, status) => {
    if (!status) {
        setUploadStatus("pending")
    } else if (
            status === "error_upload" || 
            status === "error" || 
            status ==="exception_upload"
        ) 
    {
        setUploadStatus("error")
    } else if (status === "done") {
        remove()
        setUploadStatus("done")
    }  else if (status) {
        setUploadStatus("in progress")
    }
}   

const handleCreatePheno = (id) => {
    props.createPhenotype({ModeldbId: id})
}

const DropZoneBtn = ({accept, onFiles}) => {
    return (
        <>
            <Button 
                variant="contained" 
                color="primary" 
                className={classes.primaryBtn}
                classes={{root: classes.root, label: classes.label}}
                fullWidth
                disableElevation
            >
                <label className={classes.label}>
                    <div className={classes.buttonContainer}>
                        <span className={classes.icon}>
                            {uploadIcon}&nbsp;
                        </span>
                        <Typography variant="button">Upload a model</Typography>
                        <input
                            className={classes.input}
                            style={{ display: 'none', width:"100%" }}
                            type="file"
                            accept={accept}
                            onChange={e => { 
                                onFiles(Array.prototype.slice.call(e.target.files)) 
                            }}
                        />
                    </div>
                </label>
            </Button>
        </>
    )
}

const getButtons = () => {
    if (
            uploadStatus === "pending" || 
            uploadStatus === "error" ||
            props.validationStatus === "error" ||
            props.validationStatus === "pending" ||
            props.distributionStatus === "error" ||
            props.publicLibraryProgress === "in progress"
        ) {
        return (
            <>
            <Typography variant="h6">Select the model</Typography>
            <Dropzone
                fullWidth
                classNames={{
                    dropzone: classes.dropzone
                }}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                InputComponent={DropZoneBtn}
                PreviewComponent={null}
                inputWithFilesContent={null}
                maxFiles={1}
                multiple={false}
                accept="text/xml"
            />
            <Button 
                variant="contained" 
                color="primary" 
                className={classes.primaryBtn}
                fullWidth
                disableElevation
                onClick={()=>props.setLibrary(true)}
            >
                <span className={classes.icon}>{libraryIcon}&nbsp;</span>
                <Typography variant="button">
                    Browse library
                </Typography>
            </Button>
            <Button 
                variant="contained" 
                color="primary" 
                className={classes.primaryBtn}
                disableElevation
                fullWidth
                disabled={true}
            >
                <span className={classes.icon}>{publicIconDisabled}&nbsp;</span>
                <Typography variant="button">
                    Browse Public Repository
                </Typography>
            </Button>
            </>
        )
    } else {
        return (
            <>
            <Button 
                variant="contained" 
                color="primary" 
                className={classes.primaryBtn}
                fullWidth
                disableElevation
                onClick={()=>{handleCreatePheno(props.model.id)}}
                disabled={
                    uploadStatus === "in progress" || 
                    props.distributionStatus === "in progress" ||
                    props.validationStatus === "in progress" ? true : false}
            >
                <span className={
                    uploadStatus === "in progress" || 
                    props.distributionStatus === "in progress" ||
                    props.validationStatus === "in progress"
                    ? 
                    classes.icon 
                    : 
                    classes.iconDisabled
                }>
                    {phenotypesIcon}&nbsp;
                </span>                 
                <Typography variant="button">                   
                    Use in Phenotype                
                </Typography>
            </Button>
            <Button 
                variant="contained" 
                color="primary" 
                className={classes.primaryBtn}
                disableElevation
                fullWidth
                disabled={
                    uploadStatus === "in progress" || 
                    props.distributionStatus === "in progress" ||
                    props.validationStatus === "in progress" 
                    ? 
                    true 
                    : 
                    false
                }
                onClick={()=>{history.push({pathname: "/targets/new", state: { modelId: props.model.id }})}}
            >
                <span className={
                    uploadStatus === "in progress" || 
                    props.distributionStatus === "in progress" ||
                    props.validationStatus === "in progress"
                    ? 
                    classes.icon 
                    : 
                    classes.iconDisabled
                }>
                    {targetsIcon}&nbsp;
                </span>                
                <Typography variant="button">
                    Use in Target Analysis
                </Typography>
            </Button>
            </>
        )
    } 

}

return (
        <Paper 
            className={classes.container} 
            elevation={0}
        >
            {getButtons()}
            <ModelStatus 
                uploadStatus={uploadStatus} 
                model={props.model} 
                validationStatus={props.validationStatus}
                distributionStatus={props.distributionStatus}
                publicLibraryProgress={props.publicLibraryProgress}
            />
        </Paper>
    )
}

const mapStateToProps = (state ) => {
    return {   
        model: state.updates.model,
        user: state.updates.user,
        phenotype: state.updates.phenotype,
        publicModel: state.updates.publicModel
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getModel: (data) => {dispatch(getModel(data))},
        updateModel: (data) => {dispatch(updateModel(data))},
        createPhenotype: (data) => {dispatch(createPhenotype(data))},
        getPhenotype: (data) => {dispatch(getPhenotype(data))},
        resetPhenotype: (data) => {dispatch(resetPhenotype(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ModelSelect)