import { makeStyles } from '@material-ui/core/styles';
import { 
    CircularProgress,
    Box,
    Typography,
    Divider,
    Button
  } from '@material-ui/core'
//import computerIcon from './assets/computer-icon.svg'
import { getByteSize } from '../commons';

import { useHistory  } from 'react-router'; // mauro


import { FiDatabase } from 'react-icons/fi';
import { RiComputerLine } from 'react-icons/ri';
//import { theme } from '../themes/theme'; // i don't know this was here
const computerIcon = <RiComputerLine size={35} />; // mauro






const useStyles = makeStyles((theme, props) => ({
    sidebarContainer: {
        width: "50%",
        height: "90%",
        padding: "0px 20px 20px 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        marginRight: "100px"
    },
    progressBackground: {
        position: "absolute",
        color: "#EFEEF3",
    },
    boldTitle: {
        fontSize: "18px"
    },
    storage: {
        fontWeight: 700
    },
    row: {
        display: "flex",
        alignItems: "center",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        height: "100%",
        marginLeft: "20px"
    },
    primaryBtn: {
        width: "120px",
        height: "36px",
        "&:hover": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main
        }
    },
    outlinedBtn: {
        width: "120px",
        height: "36px",
        borderColor: theme.palette.borderColor.onSurface 
    },
    storageIcon: {
        color: theme.palette.primary.main
    }
  }));

export function DashboardSidebar(props) {
    const classes = useStyles();
    const history = useHistory(); // mauro


    return (
        <div className={classes.sidebarContainer}>
            <Typography
                variant="h6"
                className={classes.boldTitle}
            >
                
                Subscription
            </Typography>
            <div className={classes.row}>
                <Box 
                    position="relative" 
                    display="inline-flex" 
                    className={classes.box}
                >
                    <CircularProgress 
                        variant="determinate" 
                        value={100} 
                        size={80}
                        thickness={2.5}
                        className={classes.progressBackground}
                    />
                    <CircularProgress 
                        variant="determinate" 
                        value={props.percentage} 
                        size={80}
                        thickness={2.5}
                    />    
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <span className={classes.storageIcon}>
                            {computerIcon}
                        </span>
                    </Box>
                </Box>
                <div className={classes.column}>
                    <Typography variant="h5" className={classes.storage}>
                        {getByteSize(props.diskspace)}
                    </Typography>
                    <Typography variant="body1">
                        Storage Space
                    </Typography>
                </div>
            </div>
            
            <Button 
                variant="contained" 
                disableElevation color="primary"
                className={classes.primaryBtn}
                onClick={()=>{window.open("https://netabolics.ai/#contacts", "_blank")}}
            >
                <Typography variant="button">                        
                    Upgrade
                </Typography>
            </Button>
            <Typography
                variant="h6"
                className={classes.boldTitle}
            >
            <Divider />
            <br />
                News
            </Typography>
            <div>
                <Typography variant="overline">
                    update - 13 days ago
                </Typography>
                <Typography variant="h6">
                    AI-Enabled Biosimulations
                </Typography>
                <Typography variant="body2">
                    The integration of AI/ML and ODE-based model simulations promises to transform pharmaceutical industry...
                </Typography>
            </div>
            
            <div>
                <Typography variant="overline">
                    update - 20 days ago
                </Typography>
                <Typography variant="h6">
                    Target Deconvolution
                </Typography>
                <Typography variant="body2">
                    NETABOLICS® can identify the biological target(s) responsible for the transition from...
                </Typography>
            </div>
            <Button 
                variant="outlined" 
                color="primary"
                className={classes.outlinedBtn}
                onClick={()=>{history.push("/documentation")}}
            >
                <Typography variant="button">
                    Read More
                </Typography>
            </Button>
        </div>

    )
}

export default DashboardSidebar