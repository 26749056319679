import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { 
    getUserInfo, 
    updateUserInfo, 
    toggleNotifications 
} from '../actions'
import { 
    Typography, 
    Button, 
    TextField, 
    Paper, 
    Divider, 
    Switch, 
    Tooltip 
} from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check'
import { isFlag } from "../commons"
import SubDrawer from "./subdrawer"
import Avatar from "./avatar"


import { HiOutlineMail } from 'react-icons/hi';
const emailIcon = <HiOutlineMail />;


const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: "240px", // mauro it was 360px
        padding: "0px 40px 40px 40px",
        height: "100%"
    },
    row: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    cardRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    title: {
        fontFamily: theme.typography.listTitle.fontFamily,
        fontSize: theme.typography.listTitle.fontSize,
        fontWeight: theme.typography.listTitle.fontWeight,
        lineHeight: theme.typography.listTitle.lineHeight,
        letterSpacing: theme.typography.listTitle.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis
    },
    outlinedBtn: {
        width: "100px",
        height: "35px",
        borderColor: theme.palette.borderColor.onSurface
    },
    primaryBtn: {
        width: "120px",
        height: "36px"
    },
    form: {
        marginTop: "20px",
    },
    textField: {
        width: "350px",
        height: "40px",
        marginBottom: "40px",
        marginRight: "10px"
    },
    fieldLabel: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    permissionsCard: {
        width: "360px",
        padding: "25px"
    },
    permissionsTextDisabled: {
        color: theme.palette.onSurface.disabled
    },
    card: {
        background: theme.palette.background.row,
        padding: "24px",
        width: "350px",
        height: "158px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "10px",
        marginBottom: "20px"
    },
    boldTitle: {
        fontFamily: theme.typography.boldTitle.fontFamily,
        fontSize: theme.typography.boldTitle.fontSize,
        fontWeight: theme.typography.boldTitle.fontWeight,
        lineHeight: theme.typography.boldTitle.lineHeight,
        letterSpacing: theme.typography.boldTitle.letterSpacing,
    },
    infoContainer: {
        width: "90%" // mauro: it was 60%
    },
    switchRows: {
        height: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    thumbOff: {
        color: "black",
    },
    tooltip: {
        borderRadius: "4px"
    }
  }));

function Support(props) {
    const Email = 2
    const Notification = 1
    const classes = useStyles()
    const listTitle = "Support"
    const listItems = ["Active Tickets", "Closed Tickets", "Open New Ticket", "Live Chat"]
    const [isChecked, setIsChecked] = useState({
        email: isFlag(props.userInfo?.notification, Email),
        notification: isFlag(props.userInfo?.notification, Notification)
    });
    const [isDisabled, setIsDisabled] = useState(true)
    const [fields, setFields] = useState({
        name: props.userInfo?.name || "",
        lastName: props.userInfo?.lastName || "",
        email: props.userInfo?.email ||"",
        password: props.userInfo?.password || "",
        institution: props.userInfo?.institution || "",
        researcher: props.userInfo?.researcher || ""
    })

    useEffect(()=>{
        props.getUserInfo({userId: props.user.id})
    }, [props.user, props.updatedUserInfo])

    useEffect(()=>{
        setFields({
            name: props.userInfo?.name || "",
            lastName: props.userInfo?.lastName || "",
            email: props.userInfo?.email ||"",
            password: props.userInfo?.password || "",
            institution: props.userInfo?.institution || "",
            researcher: props.userInfo?.researcher || ""
        })
        setIsChecked({
            email: isFlag(props.userInfo?.notification, Email),
            notification: isFlag(props.userInfo?.notification, Notification)
        })
    }, [props.userInfo, props.updatedUserInfo])


    const handleChange = (event) => {
        if (event.target.name === "email") {
            props.toggleNotifications({userId: props.user.id, flag: Email})
        } else {
            props.toggleNotifications({userId: props.user.id, flag: Notification})
        }
      };

    const handleInputChange = (e) => {
        setFields({...fields, [e.target.name]: e.target.value})
    }

    const handleClick = () => {
        if (isDisabled === false) {
            props.updateUserInfo({
                userId: props.user.id,
                name: fields.name,
                lastName: fields.lastName,
                institution: fields.institution,
                researcher: fields.researcher
            })
        }
        setIsDisabled(!isDisabled)
    }


    return (
        <>
        <SubDrawer 
            listTitle={listTitle}
            listItems={listItems} 
            menuOpen={props.menuOpen}
        />
        <div className={classes.container}>
            <div className={classes.row}>
                <Typography className={classes.title}>
                    Support Requests
                </Typography>
            </div>
            <div className={classes.infoContainer}>
                <Typography variant="subtitle2">
                    Support tickets are not yet active.
                    <br />
                    Please contact us online or send us an email to "support".
                    <br />
                    <br />
                    <Button 
                        variant="contained" 
                        disableElevation 
                        color="primary"
                        className={classes.primaryBtn}
                        onClick={()=>{window.open("https://netabolics.ai/#contacts", "_blank")}}
                    >
                        <Typography variant="button">
                            Contact Us {emailIcon}
                        </Typography>
                    </Button>
                </Typography>

            </div>
        </div>
        </>
    )

}

const mapStateToProps = (state /*, ownProps*/) => {
    return {   
        user: state.updates.user,
        userInfo: state.updates.userInfo,
        updatedUserInfo: state.updates.updatedUserInfo
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: (data) => {dispatch(getUserInfo(data))},
        updateUserInfo: (data) => {dispatch(updateUserInfo(data))},
        toggleNotifications: (data) => {dispatch(toggleNotifications(data))},
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Support)