import { 
    AppBar, 
    Badge, 
    Popper, 
    Paper,
    ClickAwayListener 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { getNotifications } from '../actions';
import logoIcon from './assets/logoIcon.svg'
import notificationIcon from './assets/notification-icon.svg'
import Avatar from './avatar';
import Notifications from './notifications'

import logo from './assets/Netabolics-Logo-v3-W-small.png' // mauro

const useStyles = makeStyles((theme) => ({
   bar: {
       height: "48px",
       display: "flex",
       flexDirection: "row",
       alignItems: "center",
       justifyContent: "space-between",
       paddingLeft: "80px", // mauro: for logo at 32px(height) this should be 68px
       paddingRight: "40px",
       background: theme.palette.primary.dark
   },
   row: {
       display: "flex",
       justifyContent: "space-around",
       alignItems: "center",
       width: "10%"
   },
   icon: {
       width: "20px",
       height: "20px"
   },
   popper: {
       marginTop: "10px"
   }
  }));

function Appbar(props) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    
    useEffect(()=>{
        props.getNotifications()
    }, [])

    const handleToggle = (e) => {
        setIsOpen(!isOpen)
        setAnchorEl(e.currentTarget)
    }
 
    return (
        <AppBar className={classes.bar} elevation={0}>
            {/*<img height="32px" src={logo} alt="logo" />*/}
            <span>Netabolics<sup>&reg;</sup>&nbsp;<b>IntelliGEM</b>&trade;</span>
            <div 
                className={classes.row}
                >
                <Badge 
                    badgeContent={props.unreadNotifications?.length} 
                    color="error"
                    onClick={handleToggle}
                >
                    <img 
                        src={notificationIcon} 
                        alt="notification icon" 
                        className={classes.icon}                                           
                    />
                </Badge>
                <Popper 
                    open={isOpen}
                    anchorEl={anchorEl}
                    transition
                    disablePortal
                    placement="bottom-end"
                    className={classes.popper}
                >     
                    <Paper>
                    <ClickAwayListener onClickAway={handleToggle}>
                        <div>
                        <Notifications 
                            isOpen={isOpen} 
                            unreadNotifications={props.unreadNotifications}
                        />
                        </div>
                    </ClickAwayListener >
                    </Paper>
                </Popper>
                <Avatar />
            </div>
        </AppBar>
    )
}

const mapStateToProps = (state ) => {
    return {
        unreadNotifications: state.updates.unreadNotifications
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getNotifications: (data) => {dispatch(getNotifications(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);