import { useEffect } from 'react'
import { connect } from 'react-redux'
import { getTarget, updateTarget } from '../actions'
import { useHistory, useParams } from 'react-router-dom'
import { 
    Paper,
    Typography,
    TextareaAutosize,
    Button
} from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles';
import { parseDate, parseTime } from '../commons'


const useStyles = makeStyles((theme, props) => ({
    row: {
        display: "flex",
        width: "100%",
        alignItems: "baseline"
    },
    icon: {
        marginLeft: "30px"
    },
    root: {
        minWidth: "250px",
        marginRight: "50px",
        color: theme.palette.onSurface.mediumEmphasis
    },
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "60%",
        marginLeft: "50px",
        marginTop: "20px"
    },
    textarea: {
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        resize: "none",
        width: "100%",
        outline: theme.palette.borderColor.onSurface,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
        padding: "15px"
    },
    btnRow: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%"
    },
    primaryBtn: {
        justifySelf: "flex-end",
        "&:hover": {
            background: theme.palette.primary.main
        }
    }, 
    fieldOutline: {
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        width: "646px",
        height: "40px"
    },
    details: {
        marginLeft: "50px",
        marginBottom: "20px"
    },
    chip: {
        background: theme.palette.secondary[600],
        color: "white",
        borderRadius: "unset",
        "&:hover": {
            background: theme.palette.secondary[600],
            color: "white",
        }
    },
  }));

function TargetInfo(props) {
    const params = useParams()
    const classes = useStyles()
    const history = useHistory()

    useEffect(()=>{
        props.getTarget({targetId: params.targetId})
    }, [])

    return (
        <Paper
            elevation={0}
            className={classes.container}
        >
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}} >
                    Description
                </Typography>
                <TextareaAutosize 
                    className={classes.textarea} 
                    rowsMin={1}
                    value={props.description || ""}
                    onChange={(e)=>props.handleInputChange(e)}
                    name="description"
                    placeholder="Type a description"
                />
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    Reference Phenotype
                </Typography>
                <Button
                    className={clsx(classes.chip)}
                    onClick={()=>history.push(`/phenotypes/${props.target?.Phenotypes?.[0]?.id}`)}
                >
                    <Typography variant="caption">
                        {props.target?.Phenotypes?.[0]?.s3name?.split("/")[2]}
                    </Typography>
                </Button>
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    Reference model
                </Typography>
                <Button
                    className={clsx(classes.chip)}
                    onClick={()=>history.push(`/models/${props.target?.Phenotypes?.[0]?.ModeldbId}`)}
                >
                    <Typography variant="caption">
                        {props.target?.s3name?.split("/")[1]}
                    </Typography>
                </Button>
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    Created by
                </Typography>
                <Typography variant="body1" >                   
                    {props.user?.name} {props.user?.lastName} ({props.user?.institution}, {props.user?.email} )
                </Typography>
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    On
                </Typography>
                <Typography variant="body1" >
                    {parseDate(props.target?.createdAt)} - {parseTime(props.target?.createdAt)}
                </Typography>
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    Last modified
                </Typography>
                <Typography variant="body1" >
                    {parseDate(props.target?.updatedAt)} - {parseTime(props.target?.updatedAt)}
                </Typography>
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    Target Analysis ID
                </Typography>
                <TextareaAutosize 
                    className={classes.textarea} 
                    rowsMin={1}
                    value={props.target?.id || ""}
                    onChange={(e)=>props.handleInputChange(e)}
                    name="targetID"
                    disabled
                />
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    Notes
                </Typography>
                <TextareaAutosize 
                    className={classes.textarea} 
                    rowsMin={6}
                    value={props.notes || ""}
                    onChange={(e)=>props.handleInputChange(e)}
                    name="notes"
                    placeholder="Type your notes"
                />
            </div>
        </Paper>
        
    )
}

const mapStateToProps = ( state ) => {
    return {   
        target: state.updates.target,
        user: state.updates.user
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getTarget: (data) => {dispatch(getTarget(data))},
        updateTarget: (data) => {dispatch(updateTarget(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(TargetInfo)