import { useState } from "react";
import { connect } from "react-redux"
import { useParams } from "react-router";
import { 
    Typography, 
    Paper,
    Checkbox,
    Tabs,
    Tab,
    TextField,
    Button,
    Divider,
    Tooltip,
    MenuItem,
    CircularProgress
} from "@material-ui/core";
import { 
    getPhenotype, 
    getModel, 
    getPhenoModel, 
    deleteDataset 
} from "../actions";
import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { parseDate, parseTime } from "../commons";
import CheckIcon from '@material-ui/icons/Check';
import Dropzone from 'react-dropzone-uploader'
import trashIcon from './assets/trash-icon.svg'
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close'
import DialogBox from './dialog'


import { FiUpload } from 'react-icons/fi';
const uploadIcon = <FiUpload />;



const useStyles = makeStyles((theme) => ({
    container: {
        margin: "20px 50px",
        width: "100%"
    },
    row: {
        height: "52px",
        background: theme.palette.background.row,
        // add color from/in theme file
        border: `1px solid #DDE1E6`,
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
        alignItems: "center",
        marginBottom: "40px"
    },
    column: {
        display: "flex",
        flexDirection: "column"
    },
    time: {
        color: theme.palette.onSurface.disabled
    },
    datasetRow: {
        height: "93px",
        background: theme.palette.background.row,
        display: "flex",
        alignItems: "center",
        padding: "0px 20px",
        justifyContent: "space-between"
    },
    typeField: {
        background: theme.palette.common.white,
        width: "315px",
        margin: "0px 20px",
    },
    root: {
        height: "40px",  
    },
    btnRoot: {
        padding: 0
    },
    input: {
        padding: "11.5px 14px",
        // add label center
    },
    btnInput: {
        width: "100%",
        height: "100%",
    },
    label: {
        width: "100%",
        height: "100%",
    },
    outlinedBtn: {
        margin: "0px 20px",
        height: "40px"
    },
    filename: {
        margin: "0px 20px"
    },
    check: {
        display: "flex",
        alignItems: "center"
    },
    innerRow: {
        display: "flex",
        alignItems: "center"
    },
    addBtn: {
        borderRadius: "50%",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: "40px",
        height: "60px",
        padding: "unset",
        marginTop: "5px",
        "&:hover": {
            background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        }
    },
    disabledTitle: {
        color: theme.palette.onSurface.disabled,
        marginBottom: "40px"
    },
    title: {
        margin: "20px 0 40px",
        color: theme.palette.onSurface.mediumEmphasis
    },
    doneBtnRow: {
        position: "fixed",
        bottom: 0,
        background: theme.palette.background.row,
        height: "56px",
        width: "100%"
    },
    primaryBtn: {
        "&:hover": {
            background: theme.palette.primary.main
        },
        position: "absolute",
        right: "5%",
        top: "20%"
    },
    buttonContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: 'wrap',
    },
  }));

function AddDataset(props) {
    const classes = useStyles();
    const params = useParams()
    const [statuses, setStatuses] = useState({})
    const [filenames, setFilenames] = useState([])
    const [newPertubation, setNewPertubation] = useState(1)
    const [openDialog, setOpenDialog] = useState(false)
    const [datasetToDelete, setDatasetToDelete] = useState("")

    useEffect(()=>{  
        if (props.phenotype?.ModeldbId) {
            props.getModel({modelId: props.phenotype?.ModeldbId})
        }
        
    }, [props.phenotype?.ModeldbId])
    
    useEffect(()=>{
        let filenamesArr = []
        let statusesObj = {}
        props.phenotype?.Perturbations?.forEach((Perturbation)=>{
            filenamesArr.push(Perturbation.s3name)
            statusesObj[Perturbation.s3name] = "done"
        })        
        setFilenames([...filenames, ...filenamesArr])
        setStatuses({...statuses, ...statusesObj})
    }, [props.phenotype?.Perturbations])
    
    useEffect(()=>{
        setNewPertubation(filenames.length !== 0 ? filenames.length : 1)
    }, [filenames])

    const getModelStatus = (status) => {
        if (status === 0) {
            return (
                <Typography 
                    variant="subtitle2" 
                    className={classes.statusRunning}
                >
                    Pending
                </Typography>)
        } else if (status === 1) {
            return <CheckIcon color="primary"/>
        } else if (status === 2) {
            return (
                <Typography variant="subtitle2" color="error">
                    Failed
                </Typography>
            )  
        }
    }

    const getUploadStatus = (filename) => {
        if (statuses?.[filename] === "error") {
            return <CloseIcon color="error"/>            
        } else if (statuses?.[filename] === "done") {
            return <CheckIcon color="primary"/>
        } else if (statuses?.[filename] === "in progress") {
            return (
                <div>
                    <CircularProgress variant="indeterminate" color="secondary"/>
                    <Typography variant="overline">Checking...</Typography>
                </div>
            )
        } else {
            return null
        }

    }

    const getUploadParams = (fileWithMeta) => { 
        setFilenames([...filenames, fileWithMeta.file.name])
        const file = new FormData() 
        file.append('file', fileWithMeta.file) 
    
        return { 
            url: process.env.REACT_APP_API_URL+'/api/phenotype/dataset', 
            method: 'post',
            file, 
            fields: {phenotypeId: params.phenotypeId},
            headers:{'X-Token': localStorage.getItem("token")} ,
        } 
    }

    const handleChangeStatus = ({ meta, file, xhr }, status) => {
        if (meta.status === "error_upload" || meta.status === "error" || meta.status === "rejected_file_type") {
            setStatuses({...statuses, [meta.name]: "error"})
        } else if (meta.status === "done") {
            setStatuses({...statuses, [meta.name]: "done"})
            props.getPhenotype({phenotypeId: params.phenotypeId})
        } else if (!meta.status) {
            setStatuses({...statuses, [meta.name]: "pending"})
        } else if (meta.status) {
            setStatuses({...statuses, [meta.name]: "in progress"})
        }
    }

    const DropZoneBtn = ({accept, onFiles}) => {
        return (
            <>
                <Button 
                    variant="outlined" 
                    color="primary" 
                    className={classes.outlinedBtn}
                    classes={{root: classes.btnRoot, label: classes.label}}
                    fullWidth
                    disableElevation
                >
                    <label className={classes.label}>
                    <div className={classes.buttonContainer}>
                        <span>{uploadIcon}&nbsp;</span>
                        <Typography variant="button">Upload dataset</Typography>
                        <input
                            className={classes.btnInput}
                            style={{ display: 'none' }}
                            type="file"
                            accept={accept}
                            onChange={e => { 
                                onFiles(Array.prototype.slice.call(e.target.files)) 
                            }}
                        />
                        </div>
                    </label>
                </Button>
            </>
        )
    }

    const addNewPertubation = () => {
        setNewPertubation(newPertubation + 1)
    }

    const deleteNewDataset = (filename) => {
        if (props.phenotype?.Perturbations?.length === 0) {
            const index = filenames?.indexOf(filename);
            if (index > -1) {
                filenames?.splice(index, 1);
            }
            setNewPertubation(newPertubation - 1)
            delete statuses[filename]
        } else if (props.phenotype?.Perturbations.some(e => e.s3name === filename)) {
            setOpenDialog(true)
            setDatasetToDelete(filename)
        } else {
            const index = filenames?.indexOf(filename);
            if (index > -1) {
              filenames?.splice(index, 1);
            }
            setNewPertubation(newPertubation - 1)
            delete statuses[filename]           
        }
    }
    
    const deleteDataset = () => {
        props.phenotype.Perturbations.map((Perturbation)=>{
            if (Perturbation.s3name === datasetToDelete) {
                props.deleteDataset({phenotypeId: props.phenotype.id, perturbationId: Perturbation.id})
            }
            return datasetToDelete
        })     
        const index = filenames?.indexOf(datasetToDelete);
            if (index > -1) {
              filenames?.splice(index, 1);
            }
            setNewPertubation(newPertubation - 1)
            delete statuses[datasetToDelete]
            setOpenDialog(false)
            setDatasetToDelete("")
    }

    const renderNewPertubationRows = () => {
        let newPertubationRows = []
        for (let i = 0; i < newPertubation; i++) {
            newPertubationRows.push(
                <Paper elevation={0} className={classes.datasetRow} >
                <div className={classes.innerRow}>
                <Typography variant="body2">
                    Type of
                </Typography>
                <TextField 
                    variant="outlined" 
                    value="Custom (.dat)"
                    disabled
                    classes={{root: classes.root}}
                    InputProps={{
                        classes: {
                          input: classes.input,
                        }
                      }}
                    className={classes.typeField}
                >
                    <MenuItem>
                        Custom (.dat)
                    </MenuItem>
                </TextField>
                </div>
                <div className={classes.innerRow}>
                <Typography 
                    variant="body2"
                >
                    File
                </Typography>
                {filenames[i] ? 
                    <Typography variant="subtitle2" className={classes.filename}>
                        {filenames?.[i]}
                    </Typography>
                    : 
                    <Dropzone
                        fullWidth
                        classNames={{
                            dropzone: classes.dropzone
                        }}
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        InputComponent={DropZoneBtn}
                        PreviewComponent={null}
                        inputWithFilesContent={null}
                        maxFiles={1}
                        multiple={false}
                        accept=".dat"
                    />
            } 
            </div>

            {getUploadStatus(filenames?.[i])}

                <div>
                    {(statuses[filenames?.[i]] === "done" || statuses[filenames?.[i]] === "error") && 
                        <Button onClick={()=> {deleteNewDataset(filenames?.[i])}}>
                        
                            <img src={trashIcon} alt="trash icon"/>
                        </Button>
                    }
                </div>
            </Paper>
            )
        }
        return newPertubationRows
    }

    const isDoneDisabled = () => {
        if (Object.keys(statuses).length !== 0 && Object.keys(statuses).every((k) => statuses[k] === "done")) {
            return false
        } else {
            return true
        }     
    }

    return (
        <>
        <div className={classes.container}>
            <Typography 
                variant="h6"
                className={classes.disabledTitle}
            >
                1. Select one model
            </Typography>
            <Paper 
                elevation={0} 
                className={classes.row}
            >
                <div className={classes.check}>
                <Checkbox 
                    checked 
                    color="primary"
                />
                <Typography variant="body2">
                    {props.model?.name}
                </Typography>
                </div>
                <div className={classes.column}>
                    <Typography variant="body2">
                        {parseDate(props.model?.createdAt)}
                    </Typography>
                    <Typography className={classes.time} variant="subtitle2">
                        {parseTime(props.model?.createdAt)}
                    </Typography>
                </div>
                <div>
                    {getModelStatus(props.model?.validation)}
                </div>
                <div>
                    {getModelStatus(props.model?.ssdistribution)}
                </div>
            </Paper>
            <Divider />
            <Typography 
                variant="h6"
                className={classes.title}
            >
                2. Add pertubations
            </Typography>
            <Tabs 
                value={0} 
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab 
                    label="ADD DATASET" 
                />
                <Tab label="ADD PARAMETERS" disabled/>
            </Tabs>
    {renderNewPertubationRows()}        
    <Tooltip title="Add extra datasets" arrow>
        <Button 
            className={classes.addBtn}
            onClick={()=>{addNewPertubation()}}
            disabled={Object.keys(statuses).every((k) => statuses[k] === "done")? false : true}
        >
            <AddIcon />
        </Button>
    </Tooltip>
        </div>
        <div className={classes.doneBtnRow}>
            <Button
                className={classes.primaryBtn}
                variant="contained"
                color="primary"
                disableElevation
                disabled={isDoneDisabled()}
            >
                <Typography onClick={()=>props.send('DONE')}>
                    Done
                </Typography>
            </Button>
            </div>
            <DialogBox 
                open={openDialog}
                msg="Deleting the dataset will trigger a revalidation of the phenotype."
                resetWarning={()=>setOpenDialog(false)}
                forceDelete={deleteDataset}
            />
            </>
    )
}

const mapStateToProps = (state) => {
    return {   
        phenotype: state.updates.phenotype,
        updatedPhenotype: state.updates.updatedPhenotype,
        phenoModel: state.updates.phenoModel,
        newModel: state.updates.newModel,
        model: state.updates.model,
        deletedDataset: state.updates.deletedDataset
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getPhenoModel: (data) => {dispatch(getPhenoModel(data))},
        getModel: (data) => {dispatch(getModel(data))},
        getPhenotype: (data) => {dispatch(getPhenotype(data))},
        deleteDataset: (data) => {dispatch(deleteDataset(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(AddDataset)