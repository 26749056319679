import { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux'
import { 
    getArchive, 
    archiveModel,
    getAllModels,
    getAllPhenotypes,
    getAllTargets,
    getAllNotebooks,
    archiveTarget,
    archivePhenotype,
    archiveNotebook,
    deleteModel,
    deletePhenotype,
    deleteTarget,
    deleteNotebook,
    resetWarning
 } from '../actions'
import { 
    TableContainer, 
    Table, 
    TableHead, 
    TableCell, 
    TableRow, 
    TableBody, 
    Typography, 
    Paper,
    TablePagination,
    TableSortLabel,
    TableFooter,
    Checkbox,
    Tabs,
    Tab
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import orderBy from 'lodash/orderBy';
import DialogBox from './dialog'
import ArchiveRows from './archiverows';
import PublicLibrary from './publiclibrary';
import { hasPermissions } from '../commons';


import { BsCalendar3 } from 'react-icons/bs';
const dateSymbol = <BsCalendar3 />;



const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: "50px",
        paddingRight: "50px",
    },
    table: {
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        marginTop: "10px",
        marginBottom: "50px"
    },
    root: {
        border: "0px",
        "&:nth-of-type(4n+1)": {
            backgroundColor: theme.palette.background.row,
        },
        "&:hover": {
            background: "rgba(34, 170, 161, 0.08)"
        }
    },
    rootExpanded: {
        background: "rgba(34, 170, 161, 0.08)",
        verticalAlign: "top",
        height: "52px",
        whiteSpace: "wrap",
        border: "0px",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around"
    },
    row: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "50%",
    },
    cells: {
        border: "0px",
        height: "52px",
    },
    visible: {
        visibility: "visible",
        cursor: "pointer",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "baseline"
    },
    hidden: {
        visibility: "hidden",
        cursor: "pointer",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "baseline"
    },
    cellCollapsed: {
        paddingBottom: 0, 
        paddingTop: 0,
        borderBottom: 0
    },
    rowExpanded: {
        padding: "20px",
        height: "130px",
        background: "rgba(34, 170, 161, 0.08)",
    },
    rowActive: {
        background: "rgba(34, 170, 161, 0.08)"
    },
    pagination: {
        borderTop: "1px solid",
        borderColor: theme.palette.borderColor.onSurface
    },
    statusRunning: {
        color: theme.palette.secondary[600]
    },
    time: {
        color: theme.palette.onSurface.disabled
    },
    details: {
        marginLeft: "50px",
        marginBottom: "20px"
    },
    btnRow: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
    },
    optionsRow: {
        alignItems: "center",
        display: "flex",
        width: "20%",
        marginLeft: "40px",
        height: "46px"
    },
    primaryBtn: {
        height: "48px",
        justifySelf: "flex-end",
        "&:hover": {
            background: theme.palette.primary.main
        }
    },
    notes: {
        overflow: "hidden",
        maxWidth: "100px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        border: "0px",
        alignSelf: "flex-start"
    },
    chip: {
        background: theme.palette.secondary[600],
        color: "white",
        borderRadius: "unset",
        "&:hover": {
            background: theme.palette.secondary[600],
            color: "white",
        }
    },
    expandIcon: {
        alignSelf: "center",
    },
    iconRoot: {
        height: "0.8em"
    },
    rowName: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    listTitle: {
        fontFamily: theme.typography.listTitle.fontFamily,
        fontWeight: theme.typography.listTitle.fontWeight,
        fontSize: theme.typography.listTitle.fontSize,
        lineHeight: theme.typography.listTitle.lineHeight,
        letterSpacing: theme.typography.listTitle.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis,
        marginTop: "20px"
    },
    actionsBtn: {
        "&:hover": {
            backgroundColor: "unset"
        }
    },
    tableHead: {
        backgroundColor: theme.palette.primary.main,
        height: "100px", 
    },
    tableHeadText: {
        color: theme.palette.primary.contrastText,   
    }
  }));


function Archive(props) {
    const classes = useStyles()
    const [isChecked, setIsChecked] = useState(false)
    const [value, setValue] = useState(0);


    useEffect(()=>{
        if (isChecked) {
            props.getAllModels()
            props.getAllPhenotypes()
            props.getAllTargets()
            props.getAllNotebooks()
        } else {
            props.getArchive()
        }
    }, [isChecked, props.targets, props.models, props.phenotypes, props.notebooks])

    const columns = [
        "Item name", 
        "Created", 
        "Visible in list", 
        "Type", 
        "Actions",
    ]

    const unarchive = (id, type) => {
        if (type === 'models') {
            props.archiveModel({modelId: id})
        } else if (type === 'phenotypes') {
            props.archivePhenotype({phenotypeId: id})
        } else if (type === 'targets') {
            props.archiveTarget({targetId: id})
        } else if (type === 'notebooks') {
            props.archiveNotebook({notebookId: id})
        }
    }

     const deleteType = (id, type) => {
        if (type === 'models') {
            props.deleteModel({modelId: id})
        } else if (type === 'phenotypes') {
            props.deletePhenotype({phenotypeId: id})
        } else if (type === 'targets') {
            props.deleteTarget({targetId: id})
        } 
     }

    const forceDelete = () => {
        if (props.modelId) {
            props.deleteModel({modelId: props.modelId, force: true})
        } else if (props.phenotypeId) {
            props.deletePhenotype({phenotypeId: props.phenotypeId, force: true})
        } else if (props.targetId) {
            props.deleteTarget({targetId: props.targetId, force: true})
        } 
    }
    
    const handleChange = () => {
        setIsChecked(!isChecked)
    }

    const handleTabChange = (e, newValue) => {
        setValue(newValue);
      }

    return (
        <>
        <div className={classes.btnRow}>
        <div className={classes.optionsRow}>
                    <Typography variant="subtitle2" className={classes.options}>
                        Help
                    </Typography>
                </div>
        </div>
        <div className={classes.container}>
        {
            hasPermissions(props.permissions, {admin: "O"}) ? 
                <Tabs 
                    value={value} 
                    onChange={handleTabChange} 
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Archive" />
                    <Tab label="Public Library" />
                </Tabs> 
                : 
                null
        }
        <div value={value} index={0} hidden={value !== 0}>
        <Typography className={classes.listTitle}>
            Archive
        </Typography>
        <div>
        <div className={classes.row}>
            <Checkbox 
                checked={isChecked} 
                onChange={handleChange}
                color="primary"
            />
            <Typography>Show all</Typography>
        </div>
        </div>
        <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow >
                       {columns.map((column, index)=>{
                           return (
                               <TableCell 
                                    variant="head"
                                    align={column === "Actions" ? "center" : "left"} 
                                    key={index}
                                >
                                    <Typography variant="subtitle2" className={classes.tableHeadText}>
                                        {column === "Created" ? dateSymbol : null} 
                                        &nbsp;                                          
                                        {column}
                                    </Typography>
                                </TableCell>
                           )
                       })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <ArchiveRows 
                        rows={isChecked ? props.allModels : props.archive?.models} 
                        typeName="Model" 
                        type="models"
                        unarchive={unarchive}
                        deleteType={deleteType}
                    />
                    <ArchiveRows 
                        rows={isChecked ? props.allPhenotypes : props.archive?.phenotypes} 
                        typeName="Phenotype" 
                        type="phenotypes"
                        unarchive={unarchive}
                        deleteType={deleteType}
                    />
                    <ArchiveRows 
                        rows={isChecked ? props.allTargets : props.archive?.targets} 
                        typeName="Target" 
                        type="targets"
                        unarchive={unarchive}
                        deleteType={deleteType}
                    />
                    <ArchiveRows 
                        rows={isChecked ? props.allNotebooks : props.archive?.notebooks} 
                        typeName="Notebooks" 
                        type="notebooks"
                        unarchive={unarchive}
                        deleteType={deleteType}
                    />                    
                </TableBody>
            </Table>
        </TableContainer>
        </div>
        </div>
        <PublicLibrary value={value} index={1} hidden={value !== 1}/>
        <DialogBox 
            open={props.warningMsg ? true : false}
            msg={props.warningMsg}
            forceDelete={forceDelete}
            resetWarning={()=>props.resetWarning()}
        />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.updates.user,
        permissions: state.updates.permissions,
        models: state.updates.models,
        phenotypes: state.updates.phenotypes,
        targets: state.updates.targets,
        notebooks: state.updates.notebooks,
        archive: state.updates.archive,
        allModels: state.updates.allModels,
        allPhenotypes: state.updates.allPhenotypes,
        allTargets: state.updates.allTargets,
        allNotebooks: state.updates.allNotebooks,
        warningMsg: state.updates.warningMsg,
        modelId: state.updates.modelId
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getArchive: (data) => {dispatch(getArchive(data))},       
        archiveModel: (data) => {dispatch(archiveModel(data))},
        archiveTarget: (data) => {dispatch(archiveTarget(data))},
        archivePhenotype: (data) => {dispatch(archivePhenotype(data))},
        archiveNotebook: (data) => {dispatch(archiveNotebook(data))},        
        getAllModels: (data) => {dispatch(getAllModels(data))},
        getAllPhenotypes: (data) => {dispatch(getAllPhenotypes(data))},
        getAllTargets: (data) => {dispatch(getAllTargets(data))},
        getAllNotebooks: (data) => {dispatch(getAllNotebooks(data))},
        deleteModel: (data) => {dispatch(deleteModel(data))},
        deletePhenotype: (data) => {dispatch(deletePhenotype(data))},
        deleteTarget: (data) => {dispatch(deleteTarget(data))},
        deleteNotebook: (data) => {dispatch(deleteNotebook(data))},
        resetWarning: (data) => {dispatch(resetWarning(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Archive)