const initialState = {
    error: null,
    errorMessage: null
   };
   
function errorReducer(state = initialState, action){
    switch (action.type) {
        case 'LOGIN_ERROR': {
          return {
            ...state,
            error: action.payload,
            errorMessage: action.payload.errorMessage
          }
        }
        case 'MODELS_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'MODEL_CREATED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'MODEL_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'MODEL_UPDATED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'MODEL_CLONED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'MODEL_DELETED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'MODEL_ARCHIVED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'ALL_MODELS_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'PUBLIC_MODELS_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'PUBLIC_MODEL_UPLOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'PHENOTYPES_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'ALL_PHENOTYPES_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'PHENOTYPE_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        } 
        case 'PHENOTYPE_UPDATED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'PHENOTYPE_DELETED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'PHENOTYPE_CREATED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'PHENOMODEL_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'DATASET_DELETED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'PHENOTYPE_ARCHIVED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'PHENOTYPE_CLONED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'TARGETS_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'TARGET_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'ALL_TARGETS_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'TARGET_CREATED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'TARGET_UPDATED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'TARGET_ARCHIVED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'TARGET_DELETED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'NOTEBOOKS_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'NOTEBOOK_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'ALL_NOTEBOOKS_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'NOTEBOOK_UPDATED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'NOTIFICATIONS_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'NOTEBOOK_ARCHIVED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'NOTIFICATION_READ_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'ARCHIVE_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'FILE_DOWNLOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'DISKSPACE_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'USER_INFO_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'TARGETS_CHART_LOADED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'USER_INFO_UPDATED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'NOTIFICATIONS_TOGGLED_ERROR': {
            return {
                ...state,
                error: action.payload,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'ERROR': {
            return {
                ...state,
                error: action.payload.error,
                errorMessage: action.payload.errorMessage
            }
        }
        case 'RESET_ERROR': {
            return {
                ...state,
                error: null,
                errorMessage: null
            }
        }
        default:
          return state;
      }
   }

export default errorReducer