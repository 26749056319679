import { createMuiTheme } from '@material-ui/core/styles';
import { NoEncryption } from '@material-ui/icons';

// for icons see https://react-icons.github.io/react-icons/

export const fontFamilyNames = "Sintony, 'Atyp Display', 'Bw Gradual', Roboto, sans-serif"; // mauro 

export const theme = createMuiTheme({
    palette: {
        primary: {
            variant: "#666666", //"#136F63",
            main: "rgb(8,64,128)", // mauro: it was "#22AAA1",
            dark: "#222222",
            maindark: "rgb(31,52,73)",
            medium: "#3a3a3a",
            light: '#e8e8e8', // mauro
            contrastText: "#FFFFFF",
            overlayDragged: "rgb(8,64,128)", //"rgba(34, 170, 161, 0.08)"
        },
        onPrimary: {
            highEmphasis: "#FFFFFF",
            mediumEmphasis: "rgba(255, 255, 255, 0.74)",
            disabled: "rgba(255, 255, 255, 0.38)",
            dark: "rgba(255, 255, 255, 0.08)",
            divider: "rgba(255, 255, 255, 0.16)"
        },
        secondary: {
            light: "#A3D7F5",
            main: '#c8c8c8', // "#84CAF1", // disabled buttons background
            dark: "#317BA3",
            contrastText: "#FFFFFF",
            600: "rgb(8,64,128)", //"#468FB7" // INLINE RESOURCE BOXES
        },
        error: {
            main: "#AA002B", // "#FF004B",
            contrastText: "#FFFFFF"
        },
        background: {
            grey: "#EDEDF1",
            default: "#FFFFFF",
            text: "#000000",
            row: "rgba(245, 245, 245, 0.56)",
            rowHover: "rgb(16,96,192, 0.10)",
        },
        surface: {
            main: "#FFFFFF",
            overlay: "rgba(33, 33, 33, 0.08)"
        },
        onSurface: {
            highEmphasis: "rgba(0, 0, 0, 0.87)",
            mediumEmphasis: "rgba(0, 0, 0, 0.6)",
            disabled: "rgba(0, 0, 0, 0.38)"
        },
        borderColor: {
            onSurface: "rgba(0, 0, 0, 0.12)"
        },
        checkbox: {
            disabled: "rgba(34, 170, 161, 0.38)"
        },
        text: {
            primary: "#000000",
            //secondary: "#FFFFFF"   // tablesortlabel color (but messes up other things)
        }
    },
    typography: {
        h1: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "96px",
            lineHeight: "112px",
            letterSpacing: "-4.5px",
        },
        h2: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "60px",
            lineHeight: "72px",
            letterSpacing: "-3.5px",
        },
        h3: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "48px",
            lineHeight: "56px",
            letterSpacing: "-2.5px",
        },
        h4: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "36px",
            lineHeight: "36px",
            letterSpacing: "-1.5px",
        },
        h5: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "24px",
            letterSpacing: "-1.0px",
        },
        h6: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "-0.5px",
        },
        listTitle: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            letterSpacing: "-0.5px",
        },
        boldTitle: {
            fontFamily: fontFamilyNames,
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: "-0.5px"
        },
        subtitle1: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "-0.5px",
        },
        subtitle2: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "-0.5px",
        },
        body1: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "-0.5px",
        },
        body2: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "-0.5px",
        },
        button: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "-0.5px",
            textTransform: "none",
        },
        caption: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.4px",
        },
        overline: {
            fontFamily: fontFamilyNames,
            fontWeight: 400,
            fontSize: "10px",
            lineHeight: "16px",
            letterSpacing: "1.5--px",
            textTransform: "uppercase"
        },
    },
    shape: {
        borderRadius: "0px"
    }
})

// overline and button typography has textTransform as well