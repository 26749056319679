import { connect } from 'react-redux'
import { useHistory } from 'react-router';
import { useEffect, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx'
import { 
    getPhenotypes, 
    createPhenotype, 
    updatePhenotype, 
    deletePhenotype,
    archivePhenotype,
    clonePhenotype,
    resetPhenotype
} from '../actions'
import { 
    TableContainer, 
    Table, 
    TableHead, 
    TableCell, 
    TableRow, 
    TableBody, 
    Button, 
    Typography, 
    Paper,
    Collapse,
    TablePagination,
    TableSortLabel,
    TextField,
    TableFooter
} from '@material-ui/core'
import Search from './search';
import ActionsMenu from './actionsmenu'
import actionsIcon from './assets/actions-icon.svg'
import CheckIcon from '@material-ui/icons/Check';
import { parseDate, parseTime } from '../commons'
import orderBy from 'lodash/orderBy';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import dataIcon from './assets/data.svg'

import { BsCalendar3 } from 'react-icons/bs';
import { GoPlus } from 'react-icons/go';
const plusSymbol = <GoPlus />;
const dateSymbol = <BsCalendar3 />;


const useStyles = makeStyles((theme) => ({
    container: {
        padding: "20px 50px"
    },
    table: {
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        marginTop: "10px",
    },
    root: {
        border: "0px",
        "&:nth-of-type(4n+1)": {
            backgroundColor: theme.palette.background.row,
        },
        "&:hover": {
            background: theme.palette.background.rowHover,
        }
    },
    rootExpanded: {
        background: theme.palette.background.rowHover,
        verticalAlign: "top",
        height: "52px",
        whiteSpace: "wrap"
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around"
    },
    row: {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-start",
        width: "50%",
    },
    cells: {
        border: "0px",
        height: "52px"
    },
    visible: {
        visibility: "visible",
        cursor: "pointer",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "baseline"
    },
    hidden: {
        visibility: "hidden",
        cursor: "pointer",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "baseline"
    },
    cellCollapsed: {
        paddingBottom: 0, 
        paddingTop: 0,
        borderBottom: 0
    },
    rowExpanded: {
        padding: "20px",
        background: theme.palette.background.rowHover,
    },
    rowActive: {
        background: theme.palette.background.rowHover,
    },
    pagination: {
        borderTop: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        color: theme.palette.onSurface.mediumEmphasis
    },
    statusRunning: {
        color: theme.palette.secondary[600]
    },
    time: {
        color: theme.palette.onSurface.disabled
    },
    details: {
        marginLeft: "50px",
        marginBottom: "20px"
    },
    btnRow: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        alignItems: "center"
    },
    optionsRow: {
        display: "flex",
        width: "20%",
        marginLeft: "40px"
    },
    primaryBtn: {
        height: "48px",
        justifySelf: "flex-end",
        "&:hover": {
            background: theme.palette.primary.main
        }
    },
    notes: {
        overflow: "hidden",
        maxWidth: "100px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        border: "0px",
        alignSelf: "flex-start"
    },
    chip: {
        background: theme.palette.secondary[600],
        color: "white",
        borderRadius: "unset",
        "&:hover": {
            background: theme.palette.secondary[600],
            color: "white",
        }
    },
    expandIcon: {
        alignSelf: "center",
    },
    iconRoot: {
        height: "0.8em"
    },
    rowName: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    listTitle: {
        fontFamily: theme.typography.listTitle.fontFamily,
        fontWeight: theme.typography.listTitle.fontWeight,
        fontSize: theme.typography.listTitle.fontSize,
        lineHeight: theme.typography.listTitle.lineHeight,
        letterSpacing: theme.typography.listTitle.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis,
        marginBottom: "20px"
    },
    actionsBtn: {
        "&:hover": {
            backgroundColor: "unset"
        }
    },
    tableHead: {
        backgroundColor: theme.palette.primary.main,
        height: "100px", 
    },
    tableHeadText: {
        color: theme.palette.primary.contrastText,   
    }
  }));

function PhenoList(props) {
    const classes = useStyles()
    const history = useHistory()
    const [rows, setRows] = useState([])
    const [hover, setHover] = useState(null)
    const [isOpen, setIsOpen] = useState(null)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortedBy, setSortedBy] = useState()
    const [direction, setDirection] = useState()
    const [rowId, setRowId] = useState()
    const [rename, setRename] = useState()
    const [name, setName] = useState("")

    useEffect(()=>{
        props.getPhenotypes()
    }, [props.updatedPhenotype, props.deletedPhenotype, props.clonedPhenotype])

    useEffect(()=>{
        setRows(props.phenotypes)
    }, [props.phenotypes])

    useEffect(()=>{
        props.resetPhenotype()
    }, [])

    const columns = [
        "Phenotype name", 
        "Created", 
        "Type of Pertubation", 
        "Steady-State Variants",
        "Notes",
        "Actions"
    ]

    const getMatches = (matches, input) => {
        setRows(input !== "" ? matches : props.phenotypes)
    }

    // change when we get validation functionality
    const getStatus = (status) => {
        if (status === 0) {
            return (
                <Typography 
                    variant="subtitle2" 
                    className={classes.statusRunning}
                >
                    Pending
                </Typography>)
        } else if (status === 1) {
            return <CheckIcon color="primary"/>
        } else if (status === 2) {
            return (
                <Typography variant="subtitle2" color="error">
                    Failed
                </Typography>
            )  
        }
    }

    const handleToggle = (e, id) => {
        setRowId(id)
        setIsMenuOpen(!isMenuOpen)
        setAnchorEl(e.currentTarget)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      const handleSortingDirection = (name) => {
        if (sortedBy !== name) {
            setSortedBy(name)
            setDirection('desc')
        } else {
            if (direction === 'asc') {
                setDirection('desc')
            } else {
                setDirection('asc')
            }            
        }
        handleSorting(name)
    }

    const handleKeyPress = (e, id) => {
        if (e.key === 'Enter') {
            props.updatePhenotype({phenotypeId: id, name})
            setName("")
            setRename(false)
        }
    }

    const handleSorting = (name) => {
        const rowsArray = rows
        let orderedRows
        if (name === "Phenotype name") {
            orderedRows = orderBy(rowsArray, [row => row.name.toLowerCase()], [direction])
        } else if (name === "Created") {
            orderedRows = orderBy(rowsArray, [row => row.createdAt], [direction])
        } else if (name === "Type of Pertubation") {
            orderedRows = orderBy(rowsArray, [row => row.type], [direction])
        } else if (name === "Steady-State Variants") {
            orderedRows = orderBy(rowsArray, [row => row.ssvariants], [direction])           
        } else if (name === "Notes") {
            orderedRows = orderBy(rowsArray, [row => row.data?.notes?.toLowerCase()], [direction])
        }
        setRows(orderedRows)
    }

    const handleExpand = (rowId) => { 
        if (isOpen !== rowId)  {
            setIsOpen(rowId)
        } else {
            setIsOpen(null)
        }
    }

    const deletePhenotype = (id) => {
        props.deletePhenotype({phenotypeId: id})
    }

    const archivePhenotype = (id) => {
        props.archivePhenotype({phenotypeId: id})
    }

    const clonePhenotype = (id) => {
        props.clonePhenotype({phenotypeId: id})
    }

    return (
        <>
        <div className={classes.btnRow}>
        <div className={classes.optionsRow}>
                    <Typography variant="subtitle2" className={classes.options}>
                        Help
                    </Typography>
                </div>
            <Button 
                variant="contained" 
                color="primary" 
                className={classes.primaryBtn}
                onClick={()=>history.push("/phenotypes/new")}
                disableElevation
            >
                <Typography variant="button">
                    New Phenotype &nbsp; {plusSymbol}
                </Typography>
            </Button>
        </div>
        <div className={classes.container}>
        <Typography className={classes.listTitle}>
            Phenotypes list
        </Typography>
        <Search rows={props.phenotypes} sendMatches={getMatches} type="phenotypes"/>
        <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow >
                       {columns.map((column)=>{
                           return (
                               <TableCell 
                                    variant="head"
                                    align={column === "Actions" ? "center" : "left"} 
                                    key={column}
                                    width={column === "Notes" ? "250px" : "150px"}                                    
                                >
                                    <Typography variant="subtitle2" className={classes.tableHeadText}>
                                        {column === "Created" ? dateSymbol : null} 
                                        &nbsp;                                           
                                        {column}
                                        {column !== "Actions" ?<TableSortLabel
                                            direction={direction} 
                                            onClick={()=>{handleSortingDirection(column)}}                                           
                                        /> : null}
                                    </Typography>
                                </TableCell>
                           )
                       })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row)=>{
                        return(
                            <Fragment key={row.id}>
                                <TableRow 
                                    key={row.id} 
                                    classes={{root: clsx({
                                        [classes.rootExpanded]: isOpen === row.id,
                                        [classes.root]: isOpen !== row.id
                                    })}} 
                                    onMouseEnter={()=>{setHover(row.id)}} 
                                    onMouseLeave={()=>{setHover(null)}}>
                                        <TableCell 
                                            className={clsx(classes.column, classes.cells)} 
                                            >
                                            {rename === row.id ? 
                                                <TextField 
                                                    variant="outlined"
                                                    label="Type new name"
                                                    value={name}
                                                    onKeyPress={(e)=>handleKeyPress(e, row.id)} 
                                                    onChange={(e)=>setName(e.target.value)}
                                                    size="small"
                                                />
                                                :
                                                <>
                                                <Typography variant="body2" >
                                                {row.name}
                                                </Typography>
                                            <Typography 
                                                variant="caption"
                                                className={
                                                    (hover === row.id && 
                                                    isOpen !== row.id) || 
                                                    isOpen === row.id ? 
                                                    classes.visible 
                                                    : 
                                                    classes.hidden
                                                }
                                                color="primary"
                                                onClick={()=>handleExpand(row.id)}
                                            >
                                                See {isOpen === row.id ? "less" : "more"}
                                                {isOpen === row.id ?
                                                        <ExpandLessRoundedIcon 
                                                            className={classes.expandIcon}
                                                            classes={{root: classes.iconRoot}}
                                                        />
                                                        :
                                                        <ExpandMoreRoundedIcon 
                                                            className={classes.expandIcon}
                                                            classes={{root: classes.iconRoot}}
                                                        />
                                                    }
                                            </Typography>
                                            </>
                                            }
                                        </TableCell>
                                            
                                        <TableCell className={classes.cells} >
                                            <div className={classes.column}>
                                                <Typography variant="body2">
                                                    {parseDate(row.createdAt)}
                                                </Typography>
                                                <Typography 
                                                    variant="body2" 
                                                    className={classes.time}>
                                                        {parseTime(row.createdAt)}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        {/* tu sam stala */}
                                        <TableCell className={classes.cells}>
                                            <Typography variant="body2">
                                                Dataset
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.cells}>
                                            {getStatus(row.ssvariants)}
                                        </TableCell>
                                        <TableCell 
                                                className={clsx(classes.cells)}
                                                classes={{root: clsx({
                                                         [classes.notes]: isOpen !== row.id,
                                                         [classes.notesOpen]: isOpen === row.id
                                                })
                                                }}
                                            >
                                                <Typography variant="caption">
                                                    {row.data?.notes}
                                                </Typography>
                                            </TableCell>
                                        <TableCell align="center" className={classes.cells} >
                                            <Button 
                                                onClick={(e)=>handleToggle(e, row.id)}
                                                classes={{root: classes.actionsBtn}}
                                            >
                                                <img src={actionsIcon} alt="actions icon" />
                                            </Button>
                                            <ActionsMenu 
                                                id={rowId}
                                                type="phenotypes"
                                                isMenuOpen={isMenuOpen} 
                                                anchorEl={anchorEl} 
                                                handleToggle={(e)=>handleToggle(e)}
                                                setRename={setRename}
                                                delete={deletePhenotype}
                                                archive={archivePhenotype}
                                                clone={clonePhenotype}
                                            />
                                        </TableCell>
                                </TableRow>
                                <TableRow
                                    classes={{root: clsx({
                                        [classes.rowExpanded]: isOpen === row.id, 
                                    })}}
                                >
                                    <TableCell className={classes.cellCollapsed} colSpan={6}>
                                        <Collapse 
                                            in={isOpen === row.id} 
                                            className={classes.column}
                                        >
                                            <div className={classes.row}>
                                               <Typography 
                                                    variant="body1" 
                                                    className={classes.details}
                                                >
                                                    Description: 
                                                </Typography>
                                               <Typography 
                                                    variant="caption" 
                                                    className={classes.details}
                                                >
                                                    {row.data.description}
                                                </Typography>
                                            </div>
                                            <div className={classes.row}>
                                               <Typography 
                                                    variant="body1" 
                                                    className={classes.details}
                                                >
                                                    Resources: 
                                                </Typography>
                                               <Typography 
                                                    variant="caption" 
                                                    className={classes.details}
                                                >
                                                    <Button
                                                        className={classes.chip}
                                                        endIcon={<img src={dataIcon} alt="data icon"/>}
                                                        onClick={()=>history.push(`/phenotypes/${row.id}`)}
                                                    >
                                                        <Typography variant="caption">
                                                            {row.s3name.split("/")[2]}
                                                        </Typography>
                                                    </Button>
                                                </Typography>
                                            </div>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </Fragment>
                        )
                    })}
                </TableBody>
                <TableFooter>
                <TableRow>
                <TablePagination 
                    classes={{toolbar: classes.pagination}}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    count={(rows && rows.length) || 0} 
                    colSpan={6}
                    rowsPerPageOptions={[10, 25, 50]}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        phenotypes: state.updates.phenotypes,
        newPhenotype: state.updates.newPhenotype,
        phenotype: state.updates.phenotype,
        updatedPhenotype: state.updates.updatedPhenotype,
        clonedPhenotype: state.updates.clonedPhenotype
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getPhenotypes: (data) => {dispatch(getPhenotypes(data))},
        createPhenotype: (data) => {dispatch(createPhenotype(data))},
        updatePhenotype: (data) => {dispatch(updatePhenotype(data))},
        deletePhenotype: (data) => {dispatch(deletePhenotype(data))},
        archivePhenotype: (data) => {dispatch(archivePhenotype(data))},
        clonePhenotype: (data) => {dispatch(clonePhenotype(data))},
        resetPhenotype: (data) => {dispatch(resetPhenotype(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(PhenoList)