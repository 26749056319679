import { useState } from 'react';
import { 
    Drawer, 
    Button, 
    Typography, 
    Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory  } from 'react-router';

import logo from './assets/Netabolics-Logo-v3-B-small.png' // mauro

//import menuIcon from './assets/menu-icon.svg'
//import dashboardIcon from './assets/dashboard-icon.svg'
import dashboardIconGreen from './assets/dashboard-icon-green.svg'
//import documentationIcon from './assets/documentation-icon.svg'
import documentationIconGreen from './assets/documentation-icon-green.svg'
//import archiveIcon from './assets/archive-icon.svg'
import archiveIconGreen from './assets/archive-icon-green.svg'
//import modelsIcon from './assets/model-icon.svg'
import modelsIconGreen from './assets/model-icon-green.svg'
//import notebooksIcon from './assets/notebook-icon.svg'
import notebooksIconGreen from './assets/notebook-icon-green.svg'
//import phenotypesIcon from './assets/phenotypes-icon.svg'
import phenotypesIconGreen from './assets/phenotypes-icon-green.svg'
//import settingsIcon from './assets/settings-icon.svg'
import settingsIconGreen from './assets/settings-icon-green.svg'
//import supportIcon from './assets/support-icon.svg'
import supportIconGreen from './assets/support-icon-green.svg'
//import targetsIcon from './assets/target-icon.svg'
import targetsIconGreen from './assets/target-icon-green.svg'
//import logo from './assets/logo.svg'

// mauro: change icons
import { BiHelpCircle, BiHome, BiNetworkChart, BiGroup, BiLayer, BiTargetLock, BiGridAlt } from 'react-icons/bi';  
import { AiFillHome, AiOutlineAreaChart, AiOutlineHome } from 'react-icons/ai'; 
import { TiChartBarOutline, TiDocumentText } from 'react-icons/ti'; 
import { BsGrid3X3GapFill, BsChatDots, BsArchive } from 'react-icons/bs'; 
import { FaChartLine, FaArchive } from 'react-icons/fa'; 
import { GiHamburgerMenu, GiBodyHeight } from 'react-icons/gi'; 
import { MdOutlineAccountTree, MdClose, MdHelpOutline, MdOutlineContactSupport, MdDashboard, MdOutlineDashboard, MdOutlineSettings } from 'react-icons/md'; 
import { RiBookReadLine, RiBookletLine, RiBarChart2Line, RiArchiveDrawerFill, RiArchiveLine, RiFingerprintLine } from 'react-icons/ri'; 
import { BsPersonLinesFill, BsBarChartLine } from 'react-icons/bs';
import { VscNotebook } from 'react-icons/vsc'; 
import { ImMenu, ImCross } from 'react-icons/im'; 
import { CgClose, CgMenuGridO } from 'react-icons/cg'; 
const dashboardIcon = <BiHome />; 
const modelsIcon = <MdOutlineAccountTree />; 
const phenotypesIcon = <RiFingerprintLine/>;
const targetsIcon = <BiTargetLock />;
const notebooksIcon = <RiBookletLine />;
const archiveIcon = <RiArchiveLine />;
const documentationIcon = <RiBookReadLine />;
const supportIcon = <MdOutlineContactSupport />;
const settingsIcon = <MdOutlineSettings />;
const menuIcon =  <GiHamburgerMenu />;
const menuIconOpen =  <CgClose />;



const useStyles = makeStyles((theme) => ({
    paper: {
     background:  theme.palette.background.grey,
     display: "flex",
     flexDirection: "column",
     justifyContent: "space-between",
     overflow: "hidden",
     border: "none"
    },
    openDrawer: {
        width: "256px",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
    },
    closedDrawer: {
        width: "60px",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowX: 'hidden',
    },
    text: {
        textTransform: "unset",
    },
    button: {
        height: "48px",
        display: "flex",
        justifyContent: "flex-start",
        padding: 0,
        borderLeft: "4.2px solid transparent",
    },
    column: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    icon: {
        width: "60px",
        display: "flex",
        justifyContent: "center"
    },
    divider: {
        color: "blue",
        height: "1px",
        width: "100%"
    },
    buttonRoot: {
        background: theme.palette.background.default,
        borderRadius: "0px",
        borderLeft: "4.2px solid",
        borderLeftColor: theme.palette.primary.main,
        color: theme.palette.primary.main
    },
    buttonRootDark: {
        background: theme.palette.primary.default,
        borderRadius: "0px",
        borderLeft: "4.2px solid",
        borderLeftColor: theme.palette.primary.main,
        color: theme.palette.primary.main
    },
    logo: {
        marginBottom: "20px",
        width: "100%"
    },
    itemTopSelected: {
        color: theme.palette.primary.main
    },
    itemTopNotSelected: {
        color: theme.palette.primary.dark
    },
    itemBottomSelected: {
        color: theme.palette.primary.main
    },
    itemBottomNotSelected: {
        color: theme.palette.primary.dark
    },
    smallDivider: {
        height: "32px"
    }
  }));

function Sidebar(props) {
    const classes = useStyles();
    const [selectedTop, setSelectedTop] = useState(null)
    const [selectedBottom, setSelectedBottom] = useState(null)
    const history = useHistory()

    const buttons = {
        top: [
            {src: dashboardIcon, srcSelected: dashboardIconGreen, text: "Dashboard", link: "/dashboard"},
            {src: modelsIcon, srcSelected: modelsIconGreen, text: "Models", link: "/models"},
            {src: phenotypesIcon, srcSelected: phenotypesIconGreen, text: "Phenotypes", link: "/phenotypes"},
            {src: targetsIcon, srcSelected: targetsIconGreen, text: "Targets Analysis", link: "/targets"},
            {src: notebooksIcon, srcSelected: notebooksIconGreen, text: "Notebooks", link: "/notebooks"},
            {src: archiveIcon, srcSelected: archiveIconGreen, text: "Archive", link: "/archive"},
        ],
        bottom: [
            {src: documentationIcon, srcSelected: documentationIconGreen, text: "Documentation", link: "/documentation"},
            {src: supportIcon, srcSelected: supportIconGreen, text: "Support", link: "/support"},
            {src: settingsIcon, srcSelected: settingsIconGreen, text: "Settings", link: "/settings"}
        ]
    }

    const selectTop = (button) => {
        setSelectedTop(buttons.top.indexOf(button))
        setSelectedBottom(null)
        history.push(button.link)
    }

    const selectBottom = (button) => {
        setSelectedBottom(buttons.bottom.indexOf(button))
        setSelectedTop(null)
        history.push(button.link)
    }

    return (
        <Drawer 
            variant="permanent"
            classes={{
                paper: clsx(classes.paper, {
                    [classes.openDrawer]: props.open,
                    [classes.closedDrawer]: !props.open,
                }),
            }}
        >
            <div className={classes.column} >
                <Button 
                    className={clsx(classes.button, classes.logo)}
                    classes={{text: classes.text}} 
                    disableRipple
                    startIcon={
                        <span className={classes.icon}>
                            {props.open ? menuIconOpen : menuIcon}
                        </span>
                    }
                    onClick={()=>props.setOpen()}>                    
                        {/*<img height="32px" src={logo} alt="logo" />*/}
                </Button>
                <Divider className={classes.smallDivider} />
                {
                    buttons.top.map((button) => {
                        return (
                            <Button 
                                className={classes.button}
                                classes={{
                                    text: classes.text, 
                                    root: clsx({
                                        [classes.buttonRoot]: selectedTop === buttons.top.indexOf(button), 
                                        [classes.text]: !selectedTop === buttons.top.indexOf(button),
                                    })
                            }} 
                            key={button.src}
                            fullWidth
                            onClick={()=>selectTop(button)}
                            startIcon={
                                <span className={classes.icon}>
                                        <span 
                                            className={selectedTop === buttons.top.indexOf(button) ? classes.itemTopSelected : classes.itemTopNotSelected} 
                                            classes={{startIcon: classes.icon}} 
                                            >
                                        {button.src}
                                        </span>
                                    </span>
                                    }
                                    >
                                        <Typography variant="subtitle2">
                                            {button.text}
                                        </Typography>
                            </Button>
                        )
                    })
                }
            </div>
            <div className={classes.column}>
                <Divider />
                {
                    buttons.bottom.map((button) => {
                        return (
                            <Button 
                                className={classes.button}
                                classes={{
                                    text: classes.text, 
                                    root: clsx({
                                        [classes.buttonRootDark]: selectedBottom === buttons.bottom.indexOf(button), 
                                        [classes.text]: !selectedBottom === buttons.bottom.indexOf(button),
                                    })
                                }} 
                                key={button.src}
                                fullWidth
                                onClick={()=>selectBottom(button)}
                                startIcon={
                                    <span className={classes.icon}>
                                        <span 
                                            className={selectedBottom === buttons.bottom.indexOf(button) ? classes.itemBottomSelected : classes.itemBottomNotSelected} 
                                            classes={{startIcon: classes.icon}} 
                                            >
                                        {button.src}
                                        </span>
                                    </span>
                                    }
                            >
                                    <Typography variant="subtitle2">
                                        {button.text}
                                    </Typography>
                            </Button>
                        )
                    })
                }
            </div>
        </Drawer>
    )
}

export default Sidebar;