import { connect } from 'react-redux'
import { Snackbar, makeStyles } from '@material-ui/core'
import {useState, useEffect} from 'react'
import { resetError } from '../actions'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiSnackbarContent-root': {
            background: theme.palette.error.main
        }
    }
    
}));

function ErrorSnackbar(props) {
    const classes = useStyles()

    const [errorMsg, setErrorMsg] = useState("")

    useEffect(()=>{
        setErrorMsg(props.errorMessage)
    }, [props.errorMessage])

      const handleClose=()=>{
        setErrorMsg("")
        props.resetError()
      }

    return (  
        <>
              <Snackbar 
                classes={{root: classes.root}}
                open={errorMsg ? true : false}
                message={errorMsg} 
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={3000}
                onClose={handleClose}
              />              
        </>     
        )
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        errorMessage: state.errorReducer.errorMessage
    }
  }
  
const mapDispatchToProps = (dispatch) => {
   return {
        resetError: (data) => { dispatch(resetError(data)) },
   }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ErrorSnackbar)