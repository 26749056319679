import {
    Dialog,
    Typography,
    Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialog: {
        // width: "300px",
        // height: "40px",
        padding: "50px",
    },
    row: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "10px"
    },
    primaryBtn: {
        height: "40px",
        width: "80px",
        marginRight: "10px",
        "&:hover": {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        }
    }
  }));

function DialogBox(props) {
    const classes = useStyles()
    
    return (
        <Dialog 
            open={props.open}
            className={classes.dialog}
            classes={{paper: classes.dialog}}
        >
            <Typography>
                {props.msg}
            </Typography>
            <Typography variant="subtitle1">
                Are you sure you want to proceed?
            </Typography>
            <div className={classes.row}>
            <Button 
                onClick={props.forceDelete} 
                variant="contained" 
                disableElevation
                color="primary"
                className={classes.primaryBtn}
            >
                Delete
            </Button>
            <Button 
                onClick={props.resetWarning} 
                variant="outlined" 
                disableElevation
                color="primary"
                className={classes.secondaryBtn}
            >
                Cancel
            </Button>
            </div>
        </Dialog>
    )
}

export default DialogBox