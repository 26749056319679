import { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { 
    getPublicModels,
    uploadPublicModel,
    resetPublicModel
} from '../actions'
import { 
    TableContainer, 
    Table, 
    TableHead, 
    TableCell, 
    TableRow, 
    TableBody, 
    Typography, 
    Paper,
    TablePagination,
    TableSortLabel,
    TableFooter,
    Checkbox,
    Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Search from './search';
import { parseDate, parseTime, getByteSize } from '../commons'
import orderBy from 'lodash/orderBy';
import { lastIndexOf } from 'lodash';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "20px 50px",
        width: "100%"
    },
    table: {
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        marginTop: "10px",
    },
    root: {
        border: "0px",
        verticalAlign: "top",
        "&:nth-of-type(4n+1)": {
            backgroundColor: theme.palette.background.row,
        },
        "&:hover": {
            background: "rgba(34, 170, 161, 0.08)"
        }
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around"
    },
    cells: {
        border: "0px",
        height: "52px"
    },
    pagination: {
        borderTop: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        color: theme.palette.onSurface.mediumEmphasis
    },
    time: {
        color: theme.palette.onSurface.disabled
    },
    listTitle: {
        fontFamily: theme.typography.listTitle.fontFamily,
        fontWeight: theme.typography.listTitle.fontWeight,
        fontSize: theme.typography.listTitle.fontSize,
        lineHeight: theme.typography.listTitle.lineHeight,
        letterSpacing: theme.typography.listTitle.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis,
        marginBottom: "20px"
    },
    primaryBtn: {
        width: "120px",
        height: "36px",
        "&:hover": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main
        },
        margin: "20px 0px 0px 0px",
        "&.MuiButton-contained.Mui-disabled": {
            color: theme.palette.onPrimary.disabled,
            backgroundColor: theme.palette.primary.main
        },
    },
    outlinedBtn: {
        width: "120px",
        height: "36px",
        borderColor: theme.palette.borderColor.onSurface,
        margin: "20px 20px 0px 0px"
    },
    btnRow: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    }
  }));


  function LibraryList(props) {
    const classes = useStyles()
    const params = useParams()
    const history = useHistory()
    const [rows, setRows] = useState([])
    const [hover, setHover] = useState(null)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(8)
    const [sortedBy, setSortedBy] = useState()
    const [direction, setDirection] = useState()
    const [checked, setChecked] = useState(null)
    const [key, setKey] = useState(null)


    useEffect(()=>{
        props.getPublicModels()
    }, [props.updatedModel, props.clonedModel])

    useEffect(()=>{
       props.resetPublicModel()
    }, [])

    useEffect(()=>{
        setRows(props.publicModels)
    }, [props.publicModels])

    useEffect(()=>{
        if (props.publicModel) {
            history.push("/models")
        }
    }, [props.publicModel])

    const columns = [
        "Select",
        "Model name",
        "Uploaded", 
        "Size"
    ]

    const getMatches = (matches, input) => {
        setRows(input !== "" ? matches : props.publicModels)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSortingDirection = (columnName) => {
        if (sortedBy !== columnName) {
            setSortedBy(columnName)
            setDirection('desc')
        } else {
            if (direction === 'asc') {
                setDirection('desc')
            } else {
                setDirection('asc')
            }            
        }
        handleSorting(columnName)
    }

    const handleSorting = (name) => {
        const rowsArray = rows
        let orderedRows
        if (name === "Model name") {
            orderedRows = orderBy(rowsArray, [row => row.Key.toLowerCase()], [direction])
        } else if (name === "Uploaded") {
            orderedRows = orderBy(rowsArray, [row => row.LastModified], [direction])
        } else if (name === "Size") {
            orderedRows = orderBy(rowsArray, [row => row.Size], [direction])
        }
        setRows(orderedRows)
    }

    const handleChange = (id, key) => {
        if (id === checked) {
            setChecked(null)
        } else {
            setChecked(id)
            setKey(key)
        }
    }
    
    const handleSelection = () => {
        props.selectPublicModel(params.modelId, key)
        props.setPublicLibraryProgress("in progress")
        props.setLibrary(false)
    }

    return (
        <>
        <div className={classes.container} > 
        <Typography className={classes.listTitle}>
            Public Library
        </Typography>
        <Search rows={props.publicModels} sendMatches={getMatches} type="public models"/>
        <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow >
                       {columns.map((column)=>{
                           return (
                               <TableCell 
                                    variant="head"
                                    align="left"
                                    key={column}  
                                    width={column === "Select" ? "30px" : "350px"}                                
                                >
                                    <Typography variant="subtitle2">
                                        {column}
                                        <TableSortLabel
                                            direction={direction} 
                                            onClick={()=>{handleSortingDirection(column)}}                                           
                                        />
                                    </Typography>
                                </TableCell>
                           )
                       })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row)=>{
                            return(
                                <Fragment key={row.LastModified} >
                                    <TableRow 
                                        classes={{root: classes.root}}
                                        onMouseEnter={()=>{setHover(row.LastModified)}} 
                                        onMouseLeave={()=>{setHover(null)}}
                                        
                                    >
                                        <TableCell 
                                            className={classes.cells} 
                                        >
                                                <Checkbox
                                                    color="primary"
                                                    checked={row.LastModified === checked}
                                                    onChange={()=>handleChange(row.LastModified, row.Key)}
                                                />
                                        </TableCell>
                                        <TableCell 
                                            className={clsx(classes.column, classes.cells)} 
                                        >
                                            <Typography variant="body2" >
                                            {row.Key.substring(row.Key.lastIndexOf("/")+1)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.cells} >
                                            <div className={classes.column}>
                                                <Typography variant="body2">
                                                    {parseDate(row.LastModified)}
                                                </Typography>
                                                <Typography 
                                                    variant="body2" 
                                                    className={classes.time}
                                                >
                                                    {parseTime(row.LastModified)}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.cells} >
                                            <div className={classes.column}>
                                                <Typography variant="body2">
                                                    {getByteSize(row.Size)}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                        )
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination 
                            classes={{toolbar: classes.pagination}}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            count={(rows && rows.length) || 0} 
                            colSpan={6}
                            rowsPerPageOptions={[8, 15, 30]}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>   
        <div className={classes.btnRow}>
                <Button 
                    variant="outlined" 
                    disableElevation 
                    color="primary"
                    onClick={()=>{history.goBack()}}
                    className={classes.outlinedBtn}
                >
                    <Typography variant="button">
                        Cancel
                    </Typography>
                </Button >
                <Button 
                    variant="contained" 
                    disableElevation 
                    color="primary"
                    disabled={checked ? false : true}
                    onClick={()=>handleSelection()}
                    className={classes.primaryBtn}
                    classes={{disabled: classes.primaryBtnDisabled}}
                >
                    <Typography variant="button" >
                        Continue
                    </Typography>
                </Button>
        </div>     
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        publicModels: state.updates.publicModels,
        publicModel: state.updates.publicModel,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getPublicModels: (data) => {dispatch(getPublicModels(data))},
        uploadPublicModel: (data) => {dispatch(uploadPublicModel(data))},
        resetPublicModel: (data) => {dispatch(resetPublicModel(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(LibraryList)