import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { 
    getUserInfo, 
    updateUserInfo, 
    toggleNotifications 
} from '../actions'
import { 
    Typography, 
    Button, 
    TextField, 
    Paper, 
    Divider, 
    Switch, 
    Tooltip 
} from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check'
import { isFlag } from "../commons"
import SubDrawer from "./subdrawer"
import Avatar from "./avatar"

const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: "240px", // mauro it was 360px
        padding: "0px 40px 40px 40px",
        height: "100%"
    },
    row: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    cardRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    title: {
        fontFamily: theme.typography.listTitle.fontFamily,
        fontSize: theme.typography.listTitle.fontSize,
        fontWeight: theme.typography.listTitle.fontWeight,
        lineHeight: theme.typography.listTitle.lineHeight,
        letterSpacing: theme.typography.listTitle.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis
    },
    outlinedBtn: {
        width: "100px",
        height: "35px",
        borderColor: theme.palette.borderColor.onSurface
    },
    primaryBtn: {
        width: "120px",
        height: "36px"
    },
    form: {
        marginTop: "20px",
    },
    textField: {
        width: "350px",
        height: "40px",
        marginBottom: "40px",
        marginRight: "10px"
    },
    fieldLabel: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    permissionsCard: {
        width: "360px",
        padding: "25px"
    },
    permissionsTextDisabled: {
        color: theme.palette.onSurface.disabled
    },
    card: {
        background: theme.palette.background.row,
        padding: "24px",
        width: "350px",
        height: "158px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "10px",
        marginBottom: "20px"
    },
    boldTitle: {
        fontFamily: theme.typography.boldTitle.fontFamily,
        fontSize: theme.typography.boldTitle.fontSize,
        fontWeight: theme.typography.boldTitle.fontWeight,
        lineHeight: theme.typography.boldTitle.lineHeight,
        letterSpacing: theme.typography.boldTitle.letterSpacing,
    },
    infoContainer: {
        width: "90%", // mauro: it was 60%
        marginTop: "20px",
    },
    switchRows: {
        height: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    thumbOff: {
        color: "black",
    },
    tooltip: {
        borderRadius: "4px"
    }
  }));

function Documentation(props) {
    const Email = 2
    const Notification = 1
    const classes = useStyles()
    const listTitle = "Documentation"
    const listItems = ["Introduction",
        "Dashboard", 
        "Models", "Phenotypes", "Target Analysis",
        "Notebooks", "Archive",
        "Support", "Settings"
    ]
    const [isChecked, setIsChecked] = useState({
        email: isFlag(props.userInfo?.notification, Email),
        notification: isFlag(props.userInfo?.notification, Notification)
    });
    const [isDisabled, setIsDisabled] = useState(true)
    const [fields, setFields] = useState({
        name: props.userInfo?.name || "",
        lastName: props.userInfo?.lastName || "",
        email: props.userInfo?.email ||"",
        password: props.userInfo?.password || "",
        institution: props.userInfo?.institution || "",
        researcher: props.userInfo?.researcher || ""
    })

    useEffect(()=>{
        props.getUserInfo({userId: props.user.id})
    }, [props.user, props.updatedUserInfo])

    useEffect(()=>{
        setFields({
            name: props.userInfo?.name || "",
            lastName: props.userInfo?.lastName || "",
            email: props.userInfo?.email ||"",
            password: props.userInfo?.password || "",
            institution: props.userInfo?.institution || "",
            researcher: props.userInfo?.researcher || ""
        })
        setIsChecked({
            email: isFlag(props.userInfo?.notification, Email),
            notification: isFlag(props.userInfo?.notification, Notification)
        })
    }, [props.userInfo, props.updatedUserInfo])


    const handleChange = (event) => {
        if (event.target.name === "email") {
            props.toggleNotifications({userId: props.user.id, flag: Email})
        } else {
            props.toggleNotifications({userId: props.user.id, flag: Notification})
        }
      };

    const handleInputChange = (e) => {
        setFields({...fields, [e.target.name]: e.target.value})
    }

    const handleClick = () => {
        if (isDisabled === false) {
            props.updateUserInfo({
                userId: props.user.id,
                name: fields.name,
                lastName: fields.lastName,
                institution: fields.institution,
                researcher: fields.researcher
            })
        }
        setIsDisabled(!isDisabled)
    }


    return (
        <>
        <SubDrawer 
            listTitle={listTitle}
            listItems={listItems} 
            menuOpen={props.menuOpen}
        />
        <div className={classes.container}>
            <div className={classes.row}>
                <Typography className={classes.title}>
                    Welcome to the Netabolics&reg; <b>IntelliGEM&trade;</b> cloud platform documentation
                </Typography>
            </div>
            <div className={classes.infoContainer}>
                <Typography variant="subtitle2">

                    <p>
                    NETABOLICS® Software-as-a-Service (SaaS) platform couples biosimulations with branches of artificial intelligence (AI) overlaid onto first principles of physics and biology to address problems and unmet needs in predicting the biological effects of new drugs.
                    </p>

                    <img width="500px" src="/documentation/preview-2.png" alt="platform"/>

                    <br />
                    <p>
                    Our platform features:
                    <ul>
                        <li>One license type, automatic updates</li>
                        <li>Personalized upgrades (disk space, RAM, CPU/GPU)</li>
                        <li>Secure login (TFA or blockchain-based SSI), data protection, and data privacy</li>  
                        <li>User roles and permissions handling (RBAC)</li>                       
                        <li>Cloud computing, data storage, and data backup</li>
                        <li>Scheduling and email notification system</li>
                        <li>Ticket-based support, live chat, and interactive documentation</li>   
                        <li>Full access to input and output files (models and simulations)</li>                    
                        <li>Integration with Python® Notebooks or Matlab® Live Scripts</li>
                        <li>Compatibility with community and industry standard file types</li>
                    </ul>
                    </p>

                    <br />
                    <p>
                    Our methods feature:                    
                    <ul>                    
                        <li>State-of-the-art computational methods for Systems Biology (sysBio)</li>
                        <li>Proprietary AI-powered algorithms for seamless construction of genome-scale models</li>
                        <li>Full compliance with non-equilibrium thermodynamics</li>
                    </ul>
                    </p>

                    <br />
                    <p>
                    Applications range from drug target selection (deconvolution/validation) to biomarkers identification and more.

                    The platform allows to get insights into biological targets (enzymes/receptors/pathways).
                    </p>

                    <br />
                    <p>
                    <b>Target Deconvolution (Phenotype-Based)</b>
                    <br />
                    NETABOLICS® can estimate the biological target(s) responsible for the transition from healthy to diseased phenotypes, in order to obtain a prioritized list of target genes and/or proteins.
                    </p>

                    <br />
                    <p>
                    <b>Target Validation (Target-Based)</b>
                    <br />
                    NETABOLICS® can estimate drug efficacy and toxicity on both healthy and diseased phenotypes for one or more biological targets, in order to improve the efficiency of experiments aimed at assessing drug's target functional evaluation.
                    </p>

                    <br />
                    <p>
                    <b>Multi-Target Pharmacology/Combination Therapy</b>
                    <br />
                    NETABOLICS® can estimate the best combination of biological targets suitable for obtaining a specific outcome (e.g., healthy phenotype), in order to guide the drug design process.                    
                    </p>

                    <br /><br />

                </Typography>           
            </div>
        </div>
        </>
    )

}

const mapStateToProps = (state /*, ownProps*/) => {
    return {   
        user: state.updates.user,
        userInfo: state.updates.userInfo,
        updatedUserInfo: state.updates.updatedUserInfo
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: (data) => {dispatch(getUserInfo(data))},
        updateUserInfo: (data) => {dispatch(updateUserInfo(data))},
        toggleNotifications: (data) => {dispatch(toggleNotifications(data))},
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Documentation)