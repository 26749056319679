import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { 
    getNotebook, 
    updateNotebook, 
    getNotebookFile
} from '../actions'
import { useParams } from 'react-router-dom'
import { 
    Paper,
    Typography,
    Button,
    TextField,
    Tabs,
    Tab
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import NotebookInfo from './notebookinfo'
import NotebookStatus from './notebookstatus'
import editIcon from './assets/edit-icon.svg'
import NbResultTable from './nbresulttable'

const useStyles = makeStyles((theme, props) => ({
    column: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "70%"
    },
    titleRow: {
        display: "flex",
        marginLeft: "50px",
        marginTop: "20px",
        alignItems: "baseline"
    },
    dataRow: {
        display: "flex",
        height: "100%"
    },
    optionsRow: {
        display: "flex",
        width: "20%",
        justifyContent: "space-around"
    },
    icon: {
        marginLeft: "30px",
        height: "20px"
    },
    root: {
        marginRight: "40px",
        width: "300px"
    },
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "50%",
        marginLeft: "50px"
    },
    textarea: {
        minWidth: "50%"
    },
    btnRow: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        alignItems: "center"
    },
    secondaryBtn: {
        height: "48px",
        "&:hover": {
            background: theme.palette.secondary.main
        }
    },
    options: {
        display: "block",
        marginLeft: "40px"
    },
    errorBanner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 30px",
        width: "100%",
        height: "48px",
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    }
  }));

function Notebook(props) {
    const params = useParams()
    const classes = useStyles()
    const [editName, setEditName] = useState()
    const [fields, setFields] = useState({
        name: (props.notebook?.name) || "",
        description: (props.notebook?.data?.description) || "",
        notes: (props.notebook?.data?.notes) || ""
    })
    const [tableC, setTableC] = useState()
    const [tableF, setTableF] = useState()
    const [tabValue, setTabValue] = useState(0)
    
    useEffect(()=>{
        props.getNotebook({notebookId: params.notebookId})
    }, [props.updatedNotebook])
    
    useEffect(()=>{
        if (props.notebook?.status === 1) {
            props.getNotebookFile({key: props.notebook?.s3name+"data_prefix_ctable.json", name: "tableC"})
            props.getNotebookFile({key: props.notebook?.s3name+"data_prefix_ftable.json", name: "tableF"})
        }
    }, [props.notebook])

    useEffect(()=>{
        (async () => {
            if (props.nbFileC) {
                let nbFile = await props.nbFileC.text();
                let result = JSON.parse(nbFile)
                setTableC(result)
            } 
            if (props.nbFileF) {
                let nbFile = await props.nbFileF.text();
                let result = JSON.parse(nbFile)
                setTableF(result)
            }
        })()
    }, [props.nbFileC, props.nbFileF])

    useEffect(()=>{
        setFields({
            name: props.notebook?.name,
            description: props.notebook?.data?.description,
            notes: props.notebook?.data?.notes
        })
    }, [props.notebook, props.updatedNotebook])

    
    const handleInputChange = (e) => {
        setFields({...fields, [e.target.name]: e.target.value})
    } 

    const handleSave= () => {
        props.updateNotebook({
            notebookId: params.notebookId, 
            name: fields.name, 
            notes: fields.notes,
            description: fields.description
        })
        setEditName(false)
    }

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
      }
    
    return (
        <Paper
            elevation={0}
            className={classes.column}
        >   
            <div className={classes.btnRow}>
                <Typography 
                    variant="subtitle2" 
                    className={classes.options}
                >
                    Help
                </Typography>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    disableElevation
                    className={classes.secondaryBtn}
                    onClick={handleSave}
                >
                    <Typography variant="button">
                        Save
                    </Typography>
                </Button>
            </div>
            <div className={classes.titleRow}>          
                {
                    editName ? 
                        
                            <TextField
                                variant="outlined" 
                                classes={{root: classes.root, focused: classes.focused}}
                                value={fields.name}
                                onChange={handleInputChange}
                                name="name"
                                placeholder={fields.name}
                                label="Insert new name"
                            />
                    :
                        <>
                            <Typography variant="h5" >
                                {props.notebook?.name}
                            </Typography>
                            <img 
                                src={editIcon} 
                                alt="edit icon" 
                                className={classes.icon}
                                onClick={()=>{setEditName(true)}}
                            />
                        </>
                }
            </div>
            <div className={classes.dataRow}>
                
                        <NotebookInfo 
                            description={fields.description}
                            notes={fields.notes}
                            handleInputChange={handleInputChange}
                        />               
            <NotebookStatus />
            </div>
            <div >
                <div className={classes.titleRow}>
                <Tabs 
                    value={tabValue} 
                    onChange={handleTabChange} 
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Species" />
                    <Tab label="Reactions" />
                </Tabs>
                </div>
            <div value={tabValue} index={0} hidden={tabValue !== 0}>
                <NbResultTable tableC={tableC} notebook={props.notebook}/>
            </div>
            <div value={tabValue} index={1} hidden={tabValue !== 1}>
                <NbResultTable tableF={tableF} notebook={props.notebook}/>
            </div>
            </div>
        </Paper>
    )
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {   
        notebook: state.updates.notebook,
        updatedNotebook: state.updates.updatedNotebook,
        nbFileC: state.updates.nbFileC,
        nbFileF: state.updates.nbFileF,

    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getNotebook: (data) => {dispatch(getNotebook(data))},
        getNotebookFile: (data) => {dispatch(getNotebookFile(data))},
        updateNotebook: (data) => {dispatch(updateNotebook(data))},
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Notebook)