import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getModel, updateModel, deleteModel, resetTarget, resetNewTarget, resetPhenoToAssign, uploadPublicModel, resetPublicModel } from '../actions'
import { useHistory, useParams } from 'react-router-dom'
import { 
    Paper,
    Typography,
    Button,
    TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import ModelInfo from './modelinfo'
import ModelSelect from './modelselect'
import editIcon from './assets/edit-icon.svg'
import LibraryList from './librarylist'


import { BiSave } from 'react-icons/bi';
const saveIcon = <BiSave size={20} />;


const useStyles = makeStyles((theme, props) => ({
    column: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%"
    },
    titleRow: {
        display: "flex",
        marginLeft: "50px",
        marginTop: "20px",
        alignItems: "baseline",
    },
    dataRow: {
        display: "flex",
        height: "100%"
    },
    optionsRow: {
        display: "flex",
        width: "20%",
        justifyContent: "space-around"
    },
    icon: {
        marginLeft: "30px", 
        height: "20px"
    },
    root: {
        marginRight: "40px",
        minWidth: "300px"
    },
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "50%",
        marginLeft: "50px"
    },
    textarea: {
        minWidth: "50%"
    },
    btnRow: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        alignItems: "center"
    },
    secondaryBtn: {
        height: "48px",
        "&:hover": {
            background: theme.palette.secondary.main
        }
    },
    options: {
        display: "block",
        margin: "30px"
    },
    errorBanner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 30px",
        width: "100%",
        height: "48px",
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    }
  }));

function Model(props) {
    const params = useParams()
    const classes = useStyles()
    const history = useHistory()
    const [editName, setEditName] = useState()
    const [library, setLibrary] = useState(false)
    const [fields, setFields] = useState({
        name: (props.model?.name) || "",
        description: (props.model?.data?.description) || "",
        notes: (props.model?.data?.notes) || ""
    })
    const [errorBanner, setErrorBanner] = useState(false)
    const [validationStatus, setValidationStatus] = useState("")
    const [distributionStatus, setDistributionStatus] = useState("")
    const [publicLibraryProgress, setPublicLibraryProgress] = useState("")


    useEffect(()=>{
        props.getModel({modelId: params.modelId})
        props.resetNewTarget()
        props.resetPhenoToAssign()
    }, [props.updatedModel])

    useEffect(()=>{
        if (props.publicModel) {
            setPublicLibraryProgress("reset")
        }
    }, [props.publicModel])

    useEffect(()=>{
        setFields({
            name: props.model?.name,
            description: props.model?.data?.description,
            notes: props.model?.data?.notes
        })
    }, [props.model, props.updatedModel])

    useEffect(()=>{
        if (props.model?.validation === 2) {
            setErrorBanner(true)
        } else {
            setErrorBanner(false)
        }
    }, [props.model, props.updatedModel])

    
    const handleInputChange = (e) => {
        setFields({...fields, [e.target.name]: e.target.value})
    } 
    
    const handleDelete = () => {
        props.deleteModel({modelId: params.modelId})
        history.push("/models")
    }

    const handleSave= () => {
        props.updateModel({
            modelId: params.modelId, 
            name: fields.name, 
            notes: fields.notes,
            description: fields.description
        })
        setEditName(false)
    }

    const selectPublicModel = (modelId, key) => {
        props.uploadPublicModel({modelId: modelId, key: key})
    }

    return (
        <Paper
            elevation={0}
            className={classes.column}
        >       
            {errorBanner ? 
                <div className={classes.errorBanner}>
                    <Typography variant="body2">
                        The validation of this model has failed, you can find out more about it on the report. 
                    </Typography>
                    <Typography variant="button">
                        Download report 
                    </Typography>
                </div>
                :
                null
            }
            <div className={classes.btnRow}>
                <div className={classes.optionsRow}>
                    <Typography variant="subtitle2" className="options">View</Typography>
                    <Typography 
                        variant="subtitle2" 
                        className="options"
                        onClick={handleDelete}
                    >
                        Delete
                    </Typography>
                    <Typography variant="subtitle2" className="options">Help</Typography>
                </div>
                <Button 
                    variant="contained" 
                    color="primary" 
                    disableElevation
                    className={classes.secondaryBtn}
                    onClick={handleSave}
                >
                    <span>{saveIcon}&nbsp;</span>
                    <Typography variant="button">
                        Save
                    </Typography>
                </Button>
            </div>
            <div className={classes.titleRow}>          
                {
                    editName ? 
                        
                            <TextField
                                variant="outlined" 
                                classes={{root: classes.root, focused: classes.focused}}
                                value={fields.name}
                                onChange={handleInputChange}
                                name="name"
                                placeholder={fields.name}
                                label="Insert new name"
                                fullWIdth
                            />
                    :
                        <>
                            <Typography variant="h5" >
                                {props.model && props.model.name}
                            </Typography>
                            <img 
                                src={editIcon} 
                                alt="edit icon" 
                                className={classes.icon}
                                onClick={()=>{setEditName(true)}}
                            />
                        </>
                }
            </div>
            <div className={classes.dataRow}>
                {library ?
                    <LibraryList 
                        setLibrary={setLibrary} 
                        selectPublicModel={selectPublicModel}
                        setPublicLibraryProgress={setPublicLibraryProgress}
                    />
                    :
                    <>
                        <ModelInfo 
                            description={fields.description}
                            notes={fields.notes}
                            handleInputChange={handleInputChange}
                        />
                        <ModelSelect 
                            setLibrary={setLibrary}
                            validationStatus={validationStatus}
                            distributionStatus={distributionStatus}
                            setValidationStatus={setValidationStatus}
                            setDistributionStatus={setDistributionStatus}
                            publicLibraryProgress={publicLibraryProgress}
                        />
                    </>
                }
            </div>
        </Paper>
    )
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {   
        model: state.updates.model,
        updatedModel: state.updates.updatedModel,
        publicModel: state.updates.publicModel
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getModel: (data) => {dispatch(getModel(data))},
        updateModel: (data) => {dispatch(updateModel(data))},
        deleteModel: (data) => {dispatch(deleteModel(data))},
        resetTarget: (data) => {dispatch(resetTarget(data))},
        resetPhenoToAssign: (data) => {dispatch(resetPhenoToAssign(data))},
        resetNewTarget: (data) => {dispatch(resetNewTarget(data))},
        uploadPublicModel: (data) => {dispatch(uploadPublicModel(data))},
        resetPublicModel: (data) => {dispatch(resetPublicModel(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Model)