import { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux'
import clsx from 'clsx'
import { 
    TableContainer, 
    Table, 
    TableHead, 
    TableCell, 
    TableRow, 
    TableBody,  
    Typography, 
    Paper,
    Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import ImgModal from './imgModal';
import { getNotebookFile, resetImage } from '../actions';


// mauro: icons
import { VscGraphLine } from 'react-icons/vsc'; 
import { FiExternalLink } from 'react-icons/fi'; 
const imgPreviewIcon = <VscGraphLine size={20} />;
const externalLink = <FiExternalLink />;



const useStyles = makeStyles((theme) => ({
    container: {
        padding: "0px 50px 50px 50px" // mauro: it was "0px 150px 50px 50px"
    },
    table: {
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        marginTop: "0px",
    },
    root: {
        border: "0px",
        "&:nth-of-type(2n+1)": {
            backgroundColor: theme.palette.background.row,
        },
        "&:hover": {
            background: "rgba(34, 170, 161, 0.08)"
        }
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around"
    },
    cells: {
        border: "0px",
        height: "52px",
    },
    rowActive: {
        background: "rgba(34, 170, 161, 0.08)"
    },
    listTitle: {
        fontFamily: theme.typography.listTitle.fontFamily,
        fontWeight: theme.typography.listTitle.fontWeight,
        fontSize: theme.typography.listTitle.fontSize,
        lineHeight: theme.typography.listTitle.lineHeight,
        letterSpacing: theme.typography.listTitle.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis,
        marginBottom: "20px"
    },
    links: {
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.secondary.main
        },
        "&:visited": {
            color: theme.palette.secondary.dark
        }
    },
    img: {
        cursor: "pointer"
    },
    pvalueBox: {
        width: "100px",
        height: "40px",
        backgroundColor: theme.palette.primary.main,
    },
    primaryBtn: {
        "&:hover": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main
        }
    },     
    hmdbImg: {
        width: "80px",
        filter: "grayscale(100%)"
    }
  }));


function NbResultTable(props) {
    const classes = useStyles()
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])

    const columns_to_show = [0, 8, 1, 3, 4, 6, 7]  // mauro


    // mauro: I added this because accessing columns sometimes failed
    // probably because ajax is slower than react rendering
    const columnLabel = (cols, col) => {
        if (cols[col] == undefined) {
            return("");
        }
        return (cols[col].label);
    }


    useEffect(()=>{
        if (props.tableC) {
            setRows(props.tableC.values)
            setColumns(props.tableC.columns)
        }
        if (props.tableF) {
            setRows(props.tableF.values)
            setColumns(props.tableF.columns)
        }
    }, [props.tableC, props.tableF])



    const handleShowImg = (img) => {
        props.getNotebookFile({key: props.notebook?.s3name + img})
    }

    const hmdbStructureImg = (link) => {
        let idx = link.indexOf("/HMDB");
        let str = link.substr(idx);
        let newlink = "https://hmdb.ca/structures" + str + "/image.svg";
        return (newlink);
    }



    return (
        <>
        <div className={classes.container}>
        <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow >
                        {columns_to_show?.map((column, index)=>{
                            return(
                                <TableCell variant="head" align="left" key={column}>
                                    <Typography variant="subtitle2">
                                        {columnLabel(columns, column)}
                                    </Typography>
                                </TableCell>
                            )
                        })}           
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row, index)=>{
                        return(
                            <Fragment key={index}>
                                <TableRow key={index} classes={{root: classes.root}}>
                                        <TableCell className={clsx(classes.column, classes.cells)}>
                                            {row[columns[0].id]}
                                            {/*
                                            <img className={classes.hmdbImg} 
                                                src={hmdbStructureImg(row[columns[7].id]["hmdb"])} 
                                                alt="" />
                                            */}
                                        </TableCell>   

                                        <TableCell className={classes.cells} >
                                            {Object.keys(row[columns[8].id]).map((value)=>{
                                                return (
                                                    <p 
                                                        key={row[columns[8].id][value]} 
                                                        onClick={()=>{handleShowImg(row[columns[8].id][value])}}
                                                        className={classes.img}
                                                    >
                                                        {imgPreviewIcon}
                                                    </p>
                                                )
                                            })}
                                        </TableCell>                                
                                        <TableCell className={classes.cells}>
                                            {row[columns[1].id]}
                                        </TableCell>
                                        <TableCell className={classes.cells} >
                                            {row[columns[3].id]}                                                 
                                        </TableCell>
                                        <TableCell className={classes.cells} >
                                            {row[columns[4].id]}
                                        </TableCell>
                                        <TableCell className={classes.cells} >
                                            {row[columns[6].id]}
                                        </TableCell>
                                        <TableCell className={classes.cells} >
                                            {Object.keys(row[columns[7].id]).map((value)=>{
                                                return (
                                                    <p key={row[columns[7].id][value]}>
                                                        <a 
                                                            href={row[columns[7].id][value]}
                                                            className={classes.links}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {row[columns[7].id][value]}
                                                        </a>
                                                    </p>
                                                )
                                            })}
                                            <p>
                                                <br></br>
                                                <Button 
                                                    variant="contained" 
                                                    disableElevation 
                                                    color="primary"
                                                    className={classes.primaryBtn}
                                                    onClick={()=>{window.open("https://platform.opentargets.org/search?page=1&q="+row[columns[0].id], "_blank")}}
                                                >
                                                    <Typography variant="button">
                                                        Search on OpenTargets {externalLink}
                                                    </Typography>
                                                </Button>                                   
                                            </p>
                                        </TableCell>

                                </TableRow>
                            </Fragment>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <ImgModal 
            open={props.image ? true : false} 
            image={props.image}
            resetImage={props.resetImage}
        />
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        image: state.updates.image
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNotebookFile: (data) => {dispatch(getNotebookFile(data))},
        resetImage: () => {dispatch(resetImage())}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(NbResultTable)