import { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import { 
    getPublicModels
} from '../actions'
import { 
    TableContainer, 
    Table, 
    TableHead, 
    TableCell, 
    TableRow, 
    TableBody, 
    Button, 
    Typography, 
    Paper,
    TablePagination,
    TableSortLabel,
    TableFooter,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Search from './search';
import { parseDate, parseTime } from '../commons'
import orderBy from 'lodash/orderBy';
import Dropzone from 'react-dropzone-uploader'
import uploadIcon from './assets/upload-icon.svg'


import { BsCalendar3 } from 'react-icons/bs';
const dateSymbol = <BsCalendar3 />;


const useStyles = makeStyles((theme) => ({
    container: {
        padding: "20px 50px"
    },
    table: {
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        marginTop: "10px",
    },
    root: {
        border: "0px",
        verticalAlign: "top",
        "&:nth-of-type(2n)": {
            backgroundColor: theme.palette.background.row,
        },
        "&:hover": {
            background: "rgba(34, 170, 161, 0.08)"
        }
    },
    rootExpanded: {
        background: "rgba(34, 170, 161, 0.08)",
        verticalAlign: "top",
        height: "52px",
        whiteSpace: "wrap"
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around"
    },
    row: {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-end",
        width: "100%",
        marginTop: "30px"
    },
    cells: {
        border: "0px",
        height: "52px"
    },
    visible: {
        visibility: "visible",
        cursor: "pointer",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "baseline"
    },
    hidden: {
        visibility: "hidden",
        cursor: "pointer",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "baseline"
    },
    cellCollapsed: {
        paddingBottom: 0, 
        paddingTop: 0,
        borderBottom: 0,
    },
    rowExpanded: {
        padding: "20px",
        background: "rgba(34, 170, 161, 0.08)",
    },
    rowActive: {
        background: "rgba(34, 170, 161, 0.08)"
    },
    pagination: {
        borderTop: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        color: theme.palette.onSurface.mediumEmphasis
    },
    statusRunning: {
        color: theme.palette.secondary[600]
    },
    time: {
        color: theme.palette.onSurface.disabled
    },
    details: {
        marginLeft: "50px",
        marginBottom: "20px"
    },
    btnRow: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        alignItems: "center"
    },
    optionsRow: {
        display: "flex",
        width: "20%",
        marginLeft: "40px"
    },
    primaryBtn: {
        height: "48px",
        display: "flex",
        alignItems: "center",
        "&:hover": {
            background: theme.palette.primary.main
        }
    },
    notes: {
        overflow: "hidden",
        maxWidth: "100px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        border: "0px",
        alignSelf: "flex-start"
    },
    notesOpen: {
        height: "52px",
        overflow: "visible"
    },
    chip: {
        background: theme.palette.secondary[600],
        color: "white",
        borderRadius: "unset",
        "&:hover": {
            background: theme.palette.secondary[600],
            color: "white",
        }
    },
    expandIcon: {
        alignSelf: "center",
    },
    iconRoot: {
        height: "0.8em"
    },
    rowName: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    listTitle: {
        fontFamily: theme.typography.listTitle.fontFamily,
        fontWeight: theme.typography.listTitle.fontWeight,
        fontSize: theme.typography.listTitle.fontSize,
        lineHeight: theme.typography.listTitle.lineHeight,
        letterSpacing: theme.typography.listTitle.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis,
        marginBottom: "20px"
    },
    actionsBtn: {
        "&:hover": {
            backgroundColor: "unset"
        }
    },
    buttonContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: 'wrap',
    },
    icon: {
        marginRight: "5px"
    },
    tableHead: {
        backgroundColor: theme.palette.primary.main,
        height: "100px", 
    },
    tableHeadText: {
        color: theme.palette.primary.contrastText,   
    }
  }));


  function PublicLibrary(props) {
    const classes = useStyles()
    const params = useParams()
    const [rows, setRows] = useState([])
    const [hover, setHover] = useState(null)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortedBy, setSortedBy] = useState()
    const [direction, setDirection] = useState()
    const [status, setStatus] = useState("")


    useEffect(()=>{
        props.getPublicModels()
    }, [props.updatedModel, props.clonedModel, status])

    useEffect(()=>{
        setRows(props.publicModels)
    }, [props.publicModels])

    const columns = [
        "Model name",
        "Uploaded", 
    ]

    const getMatches = (matches, input) => {
        setRows(input !== "" ? matches : props.publicModels)
    }

    const DropZoneBtn = ({accept, onFiles}) => {
        return (
            <div className={classes.row}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    className={classes.primaryBtn}
                    classes={{root: classes.root, label: classes.label}}
                    disableElevation
                >
                    <label className={classes.label}>
                        <div className={classes.buttonContainer}>
                        <img src={uploadIcon} alt="upload icon" className={classes.icon}/>
                        <Typography variant="button">Upload public model</Typography>
                        <input
                            className={classes.input}
                            style={{ display: 'none' }}
                            type="file"
                            accept={accept}
                            onChange={e => { 
                                onFiles(Array.prototype.slice.call(e.target.files)) 
                            }}
                        />
                        </div>
                    </label>
                </Button>
            </div>
        )
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSortingDirection = (columnName) => {
        if (sortedBy !== columnName) {
            setSortedBy(columnName)
            setDirection('desc')
        } else {
            if (direction === 'asc') {
                setDirection('desc')
            } else {
                setDirection('asc')
            }            
        }
        handleSorting(columnName)
    }

    const handleSorting = (name) => {
        const rowsArray = rows
        let orderedRows
        if (name === "Model name") {
            orderedRows = orderBy(rowsArray, [row => row.Key.toLowerCase()], [direction])
        } else if (name === "Uploaded") {
            orderedRows = orderBy(rowsArray, [row => row.LastModified], [direction])
        } 
        setRows(orderedRows)
    }

    const getUploadParams = (fileWithMeta) => { 
        const file = new FormData() 
        file.append('file', fileWithMeta.file) 
    
        return { 
            url: process.env.REACT_APP_API_URL+'/api/library/upload', 
            method: 'post',
            file, 
            fields: {modelId: params.modelId},
            headers:{'X-Token': localStorage.getItem("token")} ,
        } 
    }

    // called every time a file's `status` changes
const handleChangeStatus = ({ meta, file, xhr }, status) => {
    if (status === "done") {
        setStatus("done")
    } else {
        setStatus("in progress")
    }
}

    return (
        <>
        <div className={classes.container} hidden={props.value !== 1}> 
        <Typography className={classes.listTitle}>
            Public Library
        </Typography>
        <Search rows={props.publicModels} sendMatches={getMatches} type="public models"/>
        <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow >
                       {columns.map((column, index)=>{
                           return (
                               <TableCell 
                                    variant="head"
                                    align="left"
                                    key={index}                                 
                                >
                                    <Typography variant="subtitle2" className={classes.tableHeadText}>
                                        {column === "Uploaded" ? dateSymbol : null} 
                                        &nbsp;                                          
                                        {column}
                                        <TableSortLabel
                                             direction={direction} 
                                            onClick={()=>{handleSortingDirection(column)}}                                           
                                        />
                                    </Typography>
                                </TableCell>
                           )
                       })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row)=>{
                            return(
                                    <TableRow 
                                        key={row.id}
                                        classes={{root: classes.root}}
                                        onMouseEnter={()=>{setHover(row.id)}} 
                                        onMouseLeave={()=>{setHover(null)}}
                                        
                                    >
                                            <TableCell 
                                                className={clsx(classes.column, classes.cells)} 
                                            >
                                                <Typography variant="body2" >
                                                {row.Key.substring(row.Key.lastIndexOf("/")+1)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.cells} >
                                                <div className={classes.column}>
                                                    <Typography variant="body2">
                                                        {parseDate(row.LastModified)}
                                                    </Typography>
                                                    <Typography 
                                                        variant="body2" 
                                                        className={classes.time}>
                                                            {parseTime(row.LastModified)}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                    </TableRow>
                        )
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination 
                            classes={{toolbar: classes.pagination}}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            count={(rows && rows.length) || 0} 
                            colSpan={6}
                            rowsPerPageOptions={[10, 25, 50]}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        <Dropzone
                fullWidth
                classNames={{
                    dropzone: classes.dropzone
                }}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                InputComponent={DropZoneBtn}
                PreviewComponent={null}
                inputWithFilesContent={null}
                multiple={true}
                accept="text/xml"
            />
        
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        publicModels: state.updates.publicModels,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getPublicModels: (data) => {dispatch(getPublicModels(data))},
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(PublicLibrary)