import { ResponsivePie } from '@nivo/pie'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme, props) => ({
    piechartContainer: {
        marginLeft: "15%",
        height: "60%",  // mauro: this was 100% but text after piechart was too low
        width: "50%",
        padding: "0px 20px 20px 20px",
        display: "flex",
        flexDirection: "column",
    }
  }));

const MyResponsivePie = ({data}) => {
    const classes=useStyles()

      return (
        <div className={classes.piechartContainer}>
          <ResponsivePie
              colors={{ datum: 'data.color' }}
              data={data}
              width={400}
              height={400}
              margin={{ top: 40, right: 100, bottom: 80, left: 100 }}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor="white"
              legends={[
                  {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 56,
                      itemsSpacing: 0,
                      itemWidth: 100,
                      itemHeight: 18,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: 'circle',
                      effects: [
                          {
                              on: 'hover',
                              style: {
                                  itemTextColor: '#000'
                              }
                          }
                      ]
                  }
              ]}
          />
        </div>
      )
}

export default MyResponsivePie