import { createMachine } from 'xstate';

export const phenoTargetMachine = createMachine({
    id: 'target-phenotype',
    initial: 'stepOne',
    states: {
      stepOne: {
        on: { 
            NEXT: 'stepTwo'
          }
      },
      stepTwo: {
        on: { 
            PREVIOUS: 'stepOne',
            NEXT: 'stepThree'
        }
      },
      stepThree: {
          on: {
            PREVIOUS: 'stepTwo',
            DONE: 'done'
          }
      },
      done: {}
    }
  });