import {Fragment} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    Drawer,
    List,
    Typography,
    Divider,
    ListItem,
} from '@material-ui/core';
import Search from './search'

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        backgroundColor: theme.palette.primary.medium,
        color: theme.palette.onPrimary.mediumEmphasis,
        width: "240px", // mauro it was 360px
        padding: "20px 0px 0px 0px",
        marginTop: "48px" // mauro (to see logo)
    },
    drawerContainerMenuClosed: {
        left: "60px"
    },
    drawerContainerMenuOpen: {
        left: "256px"
    },
    listTitle: {
        paddingLeft: "20px"
    },
    listItem: {
        paddingLeft: "32px",
        height: "48px",
        "&:hover": {
            backgroundColor: theme.palette.primary.maindark
        }
    },
    divider: {
        background: theme.palette.onPrimary.divider,
        marginTop: "40px"
    },
    itemText: {
        color: theme.palette.onPrimary.highEmphasis
    },
    itemTextBold: {
        color: theme.palette.onPrimary.highEmphasis,
        fontWeight: "bold"
    }    
}));

function SubDrawer({listTitle, listItems, menuOpen}) {
    const classes = useStyles()

    const logout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('permissions')
        window.location.reload();
      }

    // add search, transition
    return (
        <Drawer
            className={classes.drawerContainer}
            variant="persistent"
            anchor="left"
            open
            classes={{
                paperAnchorLeft: clsx(classes.drawerContainer, {
                    [classes.drawerContainerMenuClosed]: !menuOpen,
                    [classes.drawerContainerMenuOpen]: menuOpen
                })
            }}
        >
        <div>
            <Typography className={classes.listTitle}>
                {listTitle}
            </Typography>
        </div>
        {/* <Search /> */}
        <List className={classes.list}>
          {listItems.map((text, index) => (
              <Fragment key={index}>
                  {text === "Sign Out" ? <Divider className={classes.divider} /> : null}
                    <ListItem 
                        button 
                        className={classes.listItem}
                        onClick={text === "Sign Out" ? logout : null} // mauro
                    >
                        <Typography variant="subtitle2" className={text === "Sign Out" ? classes.itemTextBold : classes.itemText}>
                            {text}
                        </Typography>
                    </ListItem>
                    
            </Fragment>
          ))}
        </List>      
      </Drawer>
    )

} 

export default SubDrawer
