import { createMachine } from 'xstate';

export const phenoMachine = createMachine({
    id: 'phenotype',
    initial: 'pending',
    states: {
      pending: {
        on: { 
            DATASET: 'dataset', 
            DONE: 'done' 
          }
      },
      dataset: {
        on: { 
            DONE: 'done' 
        }
      },
      done: {
        on: {
          DATASET: 'dataset',
        }
      }
    }
  });
  