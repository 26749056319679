import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getPhenotype, updatePhenotype, deletePhenotype, resetNewTarget, resetPhenoToAssign } from '../actions'
import { useHistory, useParams } from 'react-router-dom'
import { useMachine } from '@xstate/react';
import { phenoMachine } from '../commons/phenostatemachine'
import { 
    Paper,
    Typography,
    Button,
    TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import PhenotypeInfo from './phenotypeinfo'
import editIcon from './assets/edit-icon.svg'
import AddDataset from './adddataset';

import { BiSave } from 'react-icons/bi';
const saveIcon = <BiSave size={20} />;


const useStyles = makeStyles((theme, props) => ({
    column: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%"
    },
    titleRow: {
        display: "flex",
        marginLeft: "50px",
        marginTop: "20px",
        alignItems: "center"
    },
    dataRow: {
        display: "flex",
        height: "100%"
    },
    optionsRow: {
        display: "flex",
        width: "20%",
        justifyContent: "space-around"
    },
    icon: {
        marginLeft: "30px",
        height: "20px"
    },
    root: {
        marginRight: "40px",
        minWidth: "300px"
    },
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "50%",
        marginLeft: "50px"
    },
    textarea: {
        minWidth: "50%"
    },
    btnRow: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        alignItems: "center"
    },
    secondaryBtn: {
        height: "48px",
        "&:hover": {
            background: theme.palette.secondary.main
        }
    },
    options: {
        display: "block",
        margin: "30px"
    },
    errorBanner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 30px",
        width: "100%",
        height: "48px",
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    }
  }));

function Phenotype(props) {
    const params = useParams()
    const classes = useStyles()
    const [state, send] = useMachine(phenoMachine);
    const history = useHistory()
    const [editName, setEditName] = useState()
    const [fields, setFields] = useState({
        name: (props.phenotype?.name) || "",
        description: (props.phenotype?.data?.description) || "",
        notes: (props.phenotype?.data?.notes) || ""
    })

    useEffect(()=>{   
            props.getPhenotype({phenotypeId: params.phenotypeId}) 
    }, [props.updatedPhenotype])

    useEffect(()=>{
        setFields({
            name: props.phenotype?.name,
            description: props.phenotype?.data?.description,
            notes: props.phenotype?.data?.notes
        })
    }, [props.phenotype, props.updatedPhenotype])
    
    useEffect(()=>{
        if (props.phenotype?.Perturbations?.length > 0) {
            send('DONE')
        } else {
           send('DATASET')
        }      
    }, [props.phenotype])

    useEffect(()=>{
        getState()
    }, [state])

    useEffect(()=>{
        props.resetNewTarget()
        props.resetPhenoToAssign()
    }, [])
    
    const handleInputChange = (e) => {
        setFields({...fields, [e.target.name]: e.target.value})
    } 
    
    const handleDelete = () => {
        props.deletePhenotype({phenotypeId: params.phenotypeId})
        history.push("/phenotypes")
    }

    const handleSave = () => {
        props.updatePhenotype({
            phenotypeId: params.phenotypeId, 
            name: fields.name, 
            notes: fields.notes,
            description: fields.description
        })
        setEditName(false)
    }

    const getState = () => {
        switch (state.value) {
            case 'pending':
                return <div>Loading...</div>
            case 'dataset':
              return <AddDataset phenotype={props.phenotype} send={send}/>;
            case 'done':
              return (
                <PhenotypeInfo 
                    description={fields.description}
                    notes={fields.notes}
                    handleInputChange={handleInputChange}
                    send={send}
                />
              );
            default:
              return null;
          }
    }

    return (
        <Paper
            elevation={0}
            className={classes.column}
        >       
            <div className={classes.btnRow}>
                <div className={classes.optionsRow}>
                    <Typography variant="subtitle2" className="options">View</Typography>
                    <Typography 
                        variant="subtitle2" 
                        className="options"
                        onClick={handleDelete}
                    >
                        Delete
                    </Typography>
                    <Typography variant="subtitle2" className="options">Help</Typography>
                </div>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    disableElevation
                    className={classes.secondaryBtn}
                    onClick={handleSave}
                >
                    <span>{saveIcon}&nbsp;</span>
                    <Typography variant="button">
                        Save
                    </Typography>
                </Button>
            </div>
            <div className={classes.titleRow}>          
                {
                    editName ? 
                        
                            <TextField
                                variant="outlined" 
                                classes={{root: classes.root}}
                                value={fields.name}
                                onChange={handleInputChange}
                                name="name"
                                placeholder={fields.name}
                                label="Insert new name"
                            />
                    :
                        <>
                            <Typography variant="h5" >
                                {props.phenotype?.name}
                            </Typography>
                            <img 
                                src={editIcon} 
                                alt="edit icon" 
                                className={classes.icon}
                                onClick={()=>{setEditName(true)}}
                            />
                        </>
                }
            </div>
            <div 
                className={classes.dataRow}
                >
                {getState()}                    
            </div>
        </Paper>
    )
}

const mapStateToProps = (state) => {
    return {   
        phenotype: state.updates.phenotype,
        newPhenotype: state.updates.newPhenotype,
        updatedPhenotype: state.updates.updatedPhenotype,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getPhenotype: (data) => {dispatch(getPhenotype(data))},
        updatePhenotype: (data) => {dispatch(updatePhenotype(data))},
        deletePhenotype: (data) => {dispatch(deletePhenotype(data))},
        resetNewTarget: (data) => {dispatch(resetNewTarget(data))},
        resetPhenoToAssign: (data) => {dispatch(resetPhenoToAssign(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Phenotype)