import {connect } from 'react-redux'
import {useEffect, useState} from 'react'
import { 
    Typography, 
    MenuItem,
    Select,
    Button
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme, props) => ({
    table: {
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        marginTop: "10px",
    },
    root: {
        border: "0px",
        verticalAlign: "top",
        "&:nth-of-type(2n)": {
            backgroundColor: theme.palette.background.row,
        },
        "&:hover": {
            background: "rgba(34, 170, 161, 0.08)"
        }
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around"
    },
    cells: {
        border: "0px",
        height: "52px"
    },
    pagination: {
        borderTop: "1px solid",
        borderColor: theme.palette.borderColor.onSurface
    },
    time: {
        color: theme.palette.onSurface.disabled
    },
    titleRow: {
        display: "flex",
        marginLeft: "50px",
        marginTop: "20px",
        alignItems: "baseline"
    },
    dataRow: {
        display: "flex",
        height: "100%"
    },
    optionsRow: {
        display: "flex",
        width: "20%",
        justifyContent: "space-around"
    },
    icon: {
        marginLeft: "30px",
        height: "30px"
    },
    textarea: {
        minWidth: "50%"
    },
    btnRow: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        alignItems: "center"
    },
    secondaryBtn: {
        height: "48px",
        "&:hover": {
            background: theme.palette.secondary.main
        }
    },
    options: {
        display: "block",
        margin: "30px"
    },
    errorBanner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 30px",
        width: "100%",
        height: "48px",
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    outlinedBtn: {
        margin: "20px 0px",
        height: "40px",
        width: "130px",
        borderColor: theme.palette.borderColor.onSurface,
        left: "90%"
    },
    row: {
        display: "flex",
        width: "50%",
        alignItems: "center",
        marginBottom: "10px"
    },
    select: {
        height: "100%"
    },
    phenoType: {
        color: theme.palette.onSurface.mediumEmphasis,
        minWidth: "150px"
    }
  }));

function AssignPhenotypes(props) {
    const classes = useStyles()
    const [rp, setRp] = useState(props.phenoAssign?.[0] || "")
    const [tp, setTp] = useState(props.phenoAssign?.[1] || "")
    const [ep, setEp] = useState(props.phenoAssign?.[2] || "")


    useEffect(()=>{
        setRp(props.phenoAssign?.[0])
        setTp(props.phenoAssign?.[1])
        setEp(props.phenoAssign?.[2])
    }, [props.phenoAssign])

    useEffect(()=>{
        props.setS3Names([{name: rp?.s3name, id: rp?.id}, {name: tp?.s3name, id: tp?.id}, {name: ep?.s3name, id: ep?.id}])
    }, [rp, tp, ep])


    useEffect(()=>{
        if (rp === tp) {
            setTp("")
        }
        if (rp === ep) {
            setEp("")
        }
    }, [rp])

    useEffect(()=>{
        if (tp === rp) {
            setRp("")
        }
        if (tp === ep) {
            setEp("")
        }
    }, [tp])

    useEffect(()=>{
        if (ep === rp) {
            setRp("")
        }
        if (ep === tp) {
            setTp("")
        }
    }, [ep])


    const resetAll = () => {
        setRp(props.phenoAssign?.[0] || "")
        setTp(props.phenoAssign?.[1] || "")
        setEp(props.phenoAssign?.[2] || "")
    }

 return (
    <div>
        <div className={classes.row}>
            <Typography variant="subtitle2" className={classes.phenoType}>
                Reference (RP)
            </Typography>
            <Select
                className={classes.select}
                fullWidth
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                        getContentAnchorEl: null
                    }}
                variant="outlined"
                value={rp.name || ""}
                onChange={(e)=>setRp(e.target.value)}
                displayEmpty={false}
                renderValue={(rp)=>{return rp}}
            >
                {
                    props.phenoAssign?.map((option) => (
                        <MenuItem 
                            key={option.id} 
                            value={option}
                            // disabled={option.name === rp.name}
                        >
                            {option.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </div>
        <div className={classes.row}>
            <Typography variant="subtitle2" className={classes.phenoType}>
                Transition (TP)
            </Typography>
            <Select
                displayEmpty={false}
                renderValue={(tp)=>{return tp}}
                className={classes.select}
                fullWidth
                MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                            getContentAnchorEl: null
                    }}
                variant="outlined"
                value={tp.name || ""}
                native={false}
                onChange={(e)=>setTp(e.target.value)}
            >
                {props.phenoAssign?.map((option) => (
                    <MenuItem 
                        key={option.id} 
                        value={option}
                        // disabled={option.name === tp.name}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </div>
        <div className={classes.row}>
            <Typography variant="subtitle2" className={classes.phenoType}>
                Endpoint (EP)
            </Typography>
            <Select
                displayEmpty={false}
                renderValue={(ep)=>{return ep}}
                className={classes.select}
                fullWidth
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                        getContentAnchorEl: null
                    }}
                native={false}
                variant="outlined"
                value={ep.name || ""}
                onChange={(e)=>setEp(e.target.value)}
            >
                {props.phenoAssign?.map((option) => (
                    <MenuItem 
                        key={option.id} 
                        value={option}
                        // disabled={option.name === ep.name}
                    >
                        {option.name}
                    </MenuItem>
                ))}
          </Select>
          </div>
            <Button 
                variant="outlined"
                onClick={()=>resetAll()}
                color="primary"
                className={classes.outlinedBtn}
            >
                <Typography variant="button">
                    Reset all
                </Typography>
            </Button>
    </div>
 )
}

const mapStateToProps = (state) => {
    return {
         phenoAssign: state.updates.phenoAssign,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(AssignPhenotypes)