
const mock = {
  "/papi/login": {
    data: {
      user: {
        "token": "e348e44c-cba6-4f70-a9c7-a7ebbf02483d",         
        "user": {          
          "id": 1,
          "name": "Admin",       
        "lastName": "MrAdmin",          
        "email": "martina@etonec.com",
        "password": null,          
        "institution": "Science Center",         
        "researcher": "PhD student",          
        "avatar": null,          
        "notification": false,          
        "createdAt": "2021-06-17T16:13:07.199Z",          
        "updatedAt": "2021-06-17T16:13:07.199Z",          
        "Permissions": [          
          {         
             "id": 1,
        "resource": "admin",
        "perms": "{\"admin\":\"O\"}",
        "createdAt": "2021-06-17T16:13:07.281Z",
        "updatedAt": "2021-06-17T16:13:07.281Z",
        "user_permission": {
        "createdAt": "2021-06-17T16:13:07.330Z",
        "updatedAt": "2021-06-17T16:13:07.330Z",
        "PermissionId": 1,
        "UserId": 1
        }
        }
        ]
        },
        "permissions": {
        "admin": "O"
        }
        }
    }
  },
  "/api/models": {
      data: {
        models: [
            {
              id: 1, 
              name: "model1", 
              uploaded: "11.07.1991.", 
              notes: "dvije tri o modelu", 
              validation: 0, 
              ssd: 0, 
              description: "This text is describing the model", 
              resources: "BioModels ID: 0001"
            },
            {
              id: 2, 
              name: "model2", 
              uploaded: "11.07.1991.", 
              notes: "dvije tri o modelu", 
              validation: 1, 
              ssd: 1, 
              description: "This text is describing the model", 
              resources: "BioModels ID: 0001"
            },
            {
              id: 3, 
              name: "model3", 
              uploaded: "11.07.1991.", 
              notes: "dvije tri o modelu", 
              validation: 2, 
              ssd: 2, 
              description: "This text is describing the model", 
              resources: "BioModels ID: 0001"
            },
            {
              id: 4, 
              name: "model11", 
              uploaded: "11.07.1991.", 
              notes: "dvije tri o modelu", 
              validation: 0, 
              ssd: 1, 
              description: "This text is describing the model", 
              resources: "BioModels ID: 0001"
            },
            {
              id: 5, 
              name: "model22", 
              uploaded: "11.07.1991.", 
              notes: "dvije tri o modelu", 
              validation: 0, 
              ssd: 2, 
              description: "This text is describing the model", 
              resources: "BioModels ID: 0001"
            },
            {
              id: 6, 
              name: "model33", 
              uploaded: "11.07.1991.", 
              notes: "dvije tri o modelu", 
              validation: 1, 
              ssd: 2, 
              description: "This text is describing the model", 
              resources: "BioModels ID: 0001"
            },
        ]
   }
  }
}
export const getMocks = () => { return mock }
