const errorHandler = (err, {type}, dispatch) =>{  
    switch (type) {
        case 'LOGIN_ERROR': 
            if (err.includes(422)) { 
                dispatch
                    ({ 
                        type: 'LOGIN_ERROR', 
                        payload: { 
                            error: err, 
                            errorMessage: "Please fill in with the right credentials." 
                        }
                    })   
            } else if (err.includes(500)) {
                dispatch
                    ({ 
                        type: 'LOGIN_ERROR', 
                        payload: { 
                            error: err, 
                            errorMessage: "Something went wrong, please try again." 
                        }
                    });
            }
            break
        case 'MODELS_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'MODELS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see the list of models."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'MODELS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'MODEL_CREATED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'MODEL_CREATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to create a model."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'MODEL_CREATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'MODEL_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'MODEL_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This model either does not exist or you don't have permission to see it."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'MODEL_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'MODEL_UPDATED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'MODEL_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This model either does not exist or you don't have permission to update it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'MODEL_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We weren't able to update the model information with the data you provided. Please try again."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'MODEL_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'PUBLIC_MODEL_UPLOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'PUBLIC_MODEL_UPLOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This model either does not exist or you don't have permission to use it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'PUBLIC_MODEL_UPLOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "That file does not exist."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'PUBLIC_MODEL_UPLOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'MODEL_CLONED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'MODEL_CLONED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This model either does not exist or you don't have permission to clone it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'MODEL_CLONED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "The model that has not been validated can't be cloned."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'MODEL_CLONED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'MODEL_DELETED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'MODEL_DELETED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This model either does not exist or you don't have permission to delete it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'MODEL_DELETED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't delete the model."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'MODEL_DELETED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'MODEL_ARCHIVED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'MODEL_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This model either does not exist or you don't have permission to archive it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'MODEL_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't archive the model."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'MODEL_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'ALL_MODELS_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'ALL_MODELS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see the list of all the models."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'ALL_MODELS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'PUBLIC_MODELS_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'PUBLIC_MODELS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see the list of public models."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'PUBLIC_MODELS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'ALL_PHENOTYPES_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'ALL_PHENOTYPES_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see the list of all phenotypes."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'ALL_PHENOTYPES_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'PHENOTYPES_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'PHENOTYPES_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see the list of phenotypes."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'PHENOTYPES_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'PHENOTYPE_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'PHENOTYPE_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This phenotype either does not exist or you don't have the permission to see it."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'PHENOTYPE_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'PHENOTYPE_UPDATED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'PHENOTYPE_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This phenotype either does not exist or you don't have the permission to update it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'PHENOTYPE_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't update this phenotype."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'PHENOTYPE_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'PHENOTYPE_DELETED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'PHENOTYPE_DELETED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This phenotype either does not exist or you don't have the permission to delete it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'PHENOTYPE_DELETED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You cannot delete a phenotype that is already used in a target."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'PHENOTYPE_DELETED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'PHENOTYPE_CREATED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'PHENOTYPE_CREATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have the permission to create new phenotype."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'PHENOTYPE_CREATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't create this phenotype."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'PHENOTYPE_CREATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'PHENOMODEL_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'PHENOMODEL_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have the permission to see this phenotype."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'PHENOMODEL_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'DATASET_DELETED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'DATASET_DELETED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to delete this dataset."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'DATASET_DELETED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You can't modify a phenotype included in the targets that are 'in progress'."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'DATASET_DELETED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'PHENOTYPE_ARCHIVED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'PHENOTYPE_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This phenotype either doesn't exist or you don't have permission to archive it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'PHENOTYPE_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This phenotype could not be archived"
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'PHENOTYPE_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'PHENOTYPE_CLONED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'PHENOTYPE_CLONED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This phenotype either doesn't exist or you don't have permission to clone it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'PHENOTYPE_CLONED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This phenotype could not be cloned because it has not been validated"
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'PHENOTYPE_CLONED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'TARGETS_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'TARGETS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see the list of targets."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'TARGETS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'ALL_TARGETS_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'ALL_TARGETS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see the list of all the targets."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'ALL_TARGETS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'TARGET_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'TARGET_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This target either does not exist or you don't have permission to see it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'TARGET_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "It seems like this target does not exist."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'TARGET_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'TARGET_CREATED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'TARGET_CREATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This model either does not exist or you don't have permission to create a target."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'TARGET_CREATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't create this target."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'TARGET_CREATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'TARGET_UPDATED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'TARGET_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This target either does not exist or you don't have permission to update it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'TARGET_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't update this target."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'TARGET_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'TARGET_ARCHIVED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'TARGET_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This target either does not exist or you don't have permission to archive it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'TARGET_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't archive this target."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'TARGET_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'TARGET_DELETED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'TARGET_DELETED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This target either does not exist or you don't have permission to delete it."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'TARGET_DELETED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'NOTEBOOKS_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'NOTEBOOKS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see the list of notebooks."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'NOTEBOOKS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'NOTEBOOK_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'NOTEBOOK_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This notebook either doesn't exist or you don't have permission to see it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'NOTEBOOK_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't load this notebook."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'NOTEBOOK_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't load this notebook."
                    }
                })
            }
        break
        case 'ALL_NOTEBOOKS_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'ALL_NOTEBOOKS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see the list of all the notebooks."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'ALL_NOTEBOOKS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'NOTEBOOK_UPDATED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'NOTEBOOK_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This notebook either doesn't exist or you don't have permission to update it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'NOTEBOOK_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't updated this notebook."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'NOTEBOOK_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'NOTIFICATIONS_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'NOTIFICATIONS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to read the notifications."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'NOTIFICATIONS_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'NOTEBOOK_ARCHIVED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'NOTEBOOK_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This notebook either doesn't exist or you don't have permission to archive it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'NOTEBOOK_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't archive this notebook."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'NOTEBOOK_ARCHIVED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break    
        case 'NOTIFICATION_READ_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'NOTIFICATION_READ_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "This notification eaither does not exist or you don't have permission to read it."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'NOTIFICATION_READ_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't update this notification."
                    }
                })
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'NOTIFICATION_READ_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'ARCHIVE_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'ARCHIVE_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see the list of archived items."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'ARCHIVE_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'FILE_DOWNLOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'FILE_DOWNLOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to download this file."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'FILE_DOWNLOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }    
            break
        case 'DISKSPACE_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'DISKSPACE_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see the state of your storage space."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                        type: 'DISKSPACE_LOADED_ERROR',
                        payload: {
                            error: err,
                            errorMessage: "We couldn't load this notebook."
                        }
                    })
                }
            break        
        case 'USER_INFO_LOADED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'USER_INFO_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to see your information."
                    }
                })  
            } else if (err.includes(500)) {
                dispatch
                ({
                    type: 'USER_INFO_LOADED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'USER_INFO_UPDATED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'USER_INFO_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to update your information."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'USER_INFO_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't update your information."
                    }
                })
            }else if (err.includes(500)) {
                dispatch
                ({
                    type: 'USER_INFO_UPDATED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'NOTIFICATIONS_TOGGLED_ERROR': 
            if (err.includes(403)) { 
                dispatch
                ({
                    type: 'NOTIFICATIONS_TOGGLED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "You don't have permission to update your notification settings."
                    }
                })  
            } else if (err.includes(422)) {
                dispatch
                ({
                    type: 'NOTIFICATIONS_TOGGLED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "We couldn't update your notification settings."
                    }
                })
            }else if (err.includes(500)) {
                dispatch
                ({
                    type: 'NOTIFICATIONS_TOGGLED_ERROR',
                    payload: {
                        error: err,
                        errorMessage: "Something went wrong, please try again."
                    }
                })
            }
        break
        case 'TARGETS_CHART_LOADED_ERROR': 
        if (err.includes(403)) { 
            dispatch
            ({
                type: 'TARGETS_CHART_LOADED_ERROR',
                payload: {
                    error: err,
                    errorMessage: "You don't have permission to see the state of your targets."
                }
            })  
        } else if (err.includes(500)) {
            dispatch
            ({
                type: 'TARGETS_CHART_LOADED_ERROR',
                payload: {
                    error: err,
                    errorMessage: "We couldn't load this notebook."
                }
            })
        }
    break
            default:
            return {
                error: err,
            }
    }
     
}    

export default errorHandler;