import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router';
import { getPhenotype, downloadZipFile } from '../actions'
import { 
    Typography,
    Button,
    CircularProgress
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import exportIcon from './assets/export-icon.svg'
import targetIcon from './assets/target-icon-white.svg'
import targetIconDisabled from './assets/target-icon-disabled.svg'

import { BiTargetLock } from 'react-icons/bi'; 
const targetsIcon = <BiTargetLock />;


const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "500px",
        backgroundColor: theme.palette.background.row,
        marginTop: "10px",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly"
    },
    column: {
        display: "flex",
        flexDirection: "column"
    },  
    downloadBtn: {
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        marginBottom: "20px",
        height: "36px",
        color: theme.palette.primary.main
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    status: {
        marginLeft: "10px"
    },
    pending: {
        color: theme.palette.surface.overlay
    },
    exportIcon: {
        marginRight: "10px"
    },
    primaryBtn: {
            height: "36px",
            margin: "5px 0px",
            "&:hover": {
                background: theme.palette.primary.main
            },
            "&.Mui-disabled": {
                backgroundColor: theme.palette.primary.main,
            color: theme.palette.onPrimary.disabled 
            }      
    },
    icon: {
        color: theme.palette.primary.contrastText,
        marginRight: "5px"
    },
    iconDisabled: {
        color: theme.palette.primary.light,
        marginRight: "5px"
    } 
  }));

function PhenoStatus(props) {
const params = useParams()
const history = useHistory()
const classes = useStyles()
const [ssvariants, setSsvariants] = useState()
const [ssvariantsOutput, setSsvariantsOutput] = useState()

useEffect(()=>{
    setSsvariants(props.phenotype?.ssvariants)
    setSsvariantsOutput(props.phenotype?.data?.output?.variants)
}, [props.phenotype])

useEffect(()=>{
    if (props.status === "done") {
        props.getPhenotype({phenotypeId: params.phenotypeId})
    }
}, [props.status])


const getStatusIcon = () => {
    if (ssvariants === 1) {
        return <CheckIcon color="primary"/>
    } else if (ssvariants === 0) {
        return <CircularProgress variant="determinate" value={100} className={classes.pending}/>
    } else if (ssvariants === 3) {
        return <CircularProgress variant="indeterminate" color="secondary"/>
    } else if (ssvariants === 2) {
        return <CloseIcon color="error"/>
    }

}

const getStatus = (ssv) => {
    if (props.status === "done" || ssv === 1) {
        return <Typography 
                    className={classes.status} 
                    color="primary" 
                    variant="subtitle2"
                >
                    DONE
                </Typography>
    } else if (props.status === "pending" || ssv === 0) {
        return <Typography 
                    className={classes.status} 
                    variant="subtitle2"
                >
                    PENDING
                </Typography>
    } else if (props.status === "error" || ssv === 2) {
        return <Typography 
                    className={classes.status} 
                    color="error" 
                    variant="subtitle2"
                >
                    FAILED
                </Typography>
    } else {
        return <Typography 
                    className={classes.status} 
                    color="primary" 
                    variant="subtitle2"
                >
                    IN PROGRESS
                </Typography>
    }

}

const handleDownload = (filename) => {
    props.downloadZipFile({key: filename, id: props.phenotype?.id, name: props.phenotype?.name})
}

const handleEdit = () => {
    props.send('DATASET')
}


    return (
        <>      
        {props.phenotype?.Targets?.length === 0 ?
            <Button 
                variant="contained" 
                color="primary" 
                className={classes.primaryBtn}
                disableElevation
                fullWidth
                disabled={props.status === "in progress" ? true : false}
                onClick={()=>{handleEdit()}}
            >
                    <Typography variant="button">
                        Edit
                    </Typography>
            </Button>
            :
            null
        }          
        <Button 
            variant="contained" 
            color="primary" 
            className={classes.primaryBtn}
            disableElevation
            fullWidth
            disabled={props.status === "in progress" ? true : false}
            onClick={()=>{history.push({pathname: "/targets/new", state: { phenotypeId: props.phenotype.id }})}}
        >
                <span classname={
                    props.status === "in progress" ? 
                    classes.iconDisabled : 
                    classes.icon
                }>
                    {targetsIcon}&nbsp;
                </span>             
                <Typography variant="button">
                    Use in Target Analysis
                </Typography>
            </Button>         
        <div className={classes.container}>
            <Typography variant="h6">
                Status
            </Typography>
            <Typography variant="subtitle2">
                Steady-State Variants
            </Typography>

            {getStatusIcon()}

            <div className={classes.row}>
                <Typography  variant="subtitle2">
                    Your phenotype is 
                </Typography>
                    {getStatus(ssvariants)}           
            </div>
            <div className={classes.row}>
                <img 
                    src={exportIcon} 
                    alt="export icon" 
                    className={classes.exportIcon}
                />
            <Typography variant="subtitle2" >
                Export
            </Typography>
            </div>
            <div className={classes.column}>
                <Button
                    className={classes.downloadBtn}
                    onClick={()=>handleDownload(props.phenotype?.s3name)}
                >
                    <Typography variant="button">
                        Datasets
                    </Typography>
                </Button>
                <Button
                    disabled={ssvariantsOutput ? false : true}
                    className={classes.downloadBtn}
                    onClick={()=>handleDownload(ssvariantsOutput)}
                >
                    <Typography variant="button">
                        Variants
                    </Typography>
                </Button>
            </div>
        </div>      
        </>
    )
}

const mapStateToProps = (state ) => {
    return {   
        phenotype: state.updates.phenotype,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getPhenotype: (data) => {dispatch(getPhenotype(data))},
        downloadZipFile: (data) => {dispatch(downloadZipFile(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(PhenoStatus)