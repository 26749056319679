import {connect} from 'react-redux'
import {useState, Fragment, useEffect} from 'react'
import {getPhenoModel, storePhenotypeIds, storePhenoToAssign} from '../actions'
import { 
    Typography, 
    Table, 
    TableBody, 
    TableHead, 
    TableRow, 
    TableCell, 
    TablePagination, 
    TableContainer, 
    Paper,
    TableFooter,
    Checkbox,
    Button,
    Divider
} from "@material-ui/core";
import { useMachine } from '@xstate/react';
import { phenoTargetMachine } from '../commons/phenotargetmachine'
import { makeStyles } from '@material-ui/core/styles';
import Search from './search'
import { parseDate, parseTime } from "../commons";
import CheckIcon from '@material-ui/icons/Check';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';

const useStyles = makeStyles((theme, props) => ({
    container: {
        margin: "20px 50px",
        // width: "100%"
    },
    table: {
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        marginTop: "10px",
        marginBottom: "20px"
    },
    root: {
        border: "0px",
        verticalAlign: "top",
        "&:nth-of-type(2n)": {
            backgroundColor: theme.palette.background.row,
        },
        "&:hover": {
            background: "rgba(34, 170, 161, 0.08)"
        }
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around"
    },
    cells: {
        border: "0px",
        height: "52px"
    },
    pagination: {
        borderTop: "1px solid",
        borderColor: theme.palette.borderColor.onSurface
    },
    time: {
        color: theme.palette.onSurface.disabled
    },
    titleRow: {
        display: "flex",
        marginLeft: "50px",
        marginTop: "20px",
        alignItems: "baseline"
    },
    dataRow: {
        display: "flex",
        height: "100%"
    },
    optionsRow: {
        display: "flex",
        width: "20%",
        justifyContent: "space-around"
    },
    icon: {
        marginLeft: "30px",
        height: "30px"
    },
    textarea: {
        minWidth: "50%"
    },
    btnRow: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        alignItems: "center"
    },
    secondaryBtn: {
        height: "48px",
        "&:hover": {
            background: theme.palette.secondary.main
        }
    },
    options: {
        display: "block",
        margin: "30px"
    },
    errorBanner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 30px",
        width: "100%",
        height: "48px",
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    outlinedBtn: {
        margin: "20px 0px",
        height: "40px",
        width: "130px",
        borderColor: theme.palette.borderColor.onSurface
    },
    row: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end"
    },
    basicRow: {
        display: "flex",
        width: "100%",
        marginTop: "20px"
    },
    steps: {
        fontSize: "18px"
    },
    subtitle: {
        color: theme.palette.onSurface.mediumEmphasis,
        width: "50%",
        marginBottom: "20px"
    },
    disabledCheckbox: {
        "&.MuiCheckbox-colorPrimary.Mui-disabled": {
            color: theme.palette.checkbox.disabled
        }
    }
  }));

function TargetPhenotypes(props) {
    const classes = useStyles()
    const [state, send] = useMachine(phenoTargetMachine);
    const [rows, setRows] = useState(props.modelPhenotypes)
    // const [validated, setValidated] = useState()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [checked, setChecked] = useState(null)
    const [rp, setRp] = useState(null)
    const [tp, setTp] = useState(null)
    const [ep, setEp] = useState(null)


    const columns = [
        "Select",
        "Phenotype name", 
        "Created", 
        "Type of Pertubation", 
        "Steady-State Variants"
    ]

    useEffect(()=>{
        if (props.modelId) {
            props.getPhenoModel({modelId: props.modelId})
        }
    }, [props.modelId])

    useEffect(()=>{
        if (props.phenotypeId) {
            let reference = rows?.filter((row)=>{return row.id == props.phenotypeId})
            setRp(reference?.[0])
            if (state.value === 'stepOne') {
                send('NEXT')
            }
        }
    }, [props.phenotypeId, rows])

    useEffect(()=>{
        setRows(props.modelPhenotypes)
    }, [props.modelPhenotypes])

    const getMatches = (matches, input) => {
        setRows(input !== "" ? matches : props.modelPhenotypes)
    }

    const handleChange = (pheno) => {
        if (pheno?.id === checked) {
            setChecked(null)
        } else {
            setChecked(pheno?.id)
            checkPheno(pheno)
        }
    }

    const checkPheno = (pheno) => {
        if (state.value === 'stepOne') {
            setRp(pheno)
        } else if (state.value === 'stepTwo') {
            setTp(pheno)
        } else if (state.value === 'stepThree') {
            setEp(pheno)
        }
    }

    // add this with pehnotypes after we implement validation
    // useEffect(()=>{
    //     const validated = []
    //     props.modelPhenotypes?.map((modelPhenotype)=>{
    //         if (modelPhenotype.ssdistribution === 1 && modelPhenotype.validation === 1) {
    //             validated.push(modelPhenotype)
    //         }
    //         return validated
    //     })
    //     setValidated(validated)
    //     setRows(validated)
    // }, [props.modelPhenotypes])

    const getStatus = (status) => {
        if (status === 0) {
            return (
                <Typography 
                    variant="subtitle2" 
                    className={classes.statusRunning}
                >
                    Pending
                </Typography>)
        } else if (status === 1) {
            return <CheckIcon color="primary"/>
        } else if (status === 2) {
            return (
                <Typography variant="subtitle2" color="error">
                    Failed
                </Typography>
            )  
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };

    const handleContinue = () => {
        props.storePhenotypeIds([rp.id, tp.id, ep.id])
        props.storePhenoToAssign([rp, tp, ep])
        send('DONE')
    }

    const getTitle = () => {
        if (state.value === 'stepOne') {
            return (
                <>
                <div className={classes.basicRow}>
                    <KeyboardArrowLeftRoundedIcon color="disabled"/>
                    <KeyboardArrowRightRoundedIcon 
                        onClick={()=>{send('NEXT')}}
                    />
                    <Typography variant="subtitle2" className={classes.steps}>
                        1/3 Select from the list the Reference Phenotype (RP)
                    </Typography>
                </div>
                    <Typography 
                        variant="body2"
                        className={classes.subtitle}
                    >
                        Once the Reference Phenotype is selected, the list will be filtered for those with the corresponding model
                    </Typography>
                    </>
            )
        } else if (state.value === 'stepTwo') {
            return (
                <>
                <div className={classes.basicRow}>
                    <KeyboardArrowLeftRoundedIcon 
                        onClick={()=>{send('PREVIOUS')}}
                    />
                    <KeyboardArrowRightRoundedIcon 
                        onClick={()=>{send('NEXT')}}
                    />
                    <Typography variant="subtitle2" className={classes.steps}>
                        2/3 Select from the list the Transition Phenotype (TP)
                    </Typography>
                </div>
                    <Typography 
                        variant="body2"
                        className={classes.subtitle}
                    >
                        The list was filtered by those with the corresponding Reference Phenotype model
                    </Typography>
                    </>
            )
        } else if (state.value === 'stepThree' || state.value === 'done') {
            return (
                <>
                <div className={classes.basicRow}>
                    <KeyboardArrowLeftRoundedIcon 
                        onClick={()=>{send('PREVIOUS')}}
                    />
                    <KeyboardArrowRightRoundedIcon color="disabled"/>
                    <Typography variant="subtitle2" className={classes.steps}>
                        3/3 Select from the list the Endpoint Phenotype (EP)
                    </Typography>
                </div>
                    <Typography 
                        variant="body2"
                        className={classes.subtitle}
                    >
                        The list was filtered by those with the corresponding Reference Phenotype model
                    </Typography>
                </>
            )
        }
    }

    return (
        <Paper
            elevation={0}
            className={classes.column}
        >       
        <div>
            {getTitle()}
            <Search 
                rows={props.modelPhenotypes} 
                sendMatches={getMatches} 
                type="phenotypes"
            />
            <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow >
                       {columns.map((column)=>{
                           return (
                               <TableCell 
                                    variant="head"
                                    align={"left"} 
                                    key={column}
                                    width={column === "Select" ? "50px" : "150px"}                                   
                                >
                                    <Typography variant="subtitle2">
                                        {column}                                        
                                    </Typography>
                                </TableCell>
                           )
                       })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row)=>{
                            return(
                                <Fragment key={row.id} >
                                    <TableRow 
                                        classes={{root: classes.root}}                                        
                                    >
                                        <TableCell 
                                                className={classes.cells} 
                                            >
                                               <Checkbox
                                                    color="primary"
                                                    checked={
                                                        row.id === rp?.id || 
                                                        row.id === tp?.id || 
                                                        row.id === ep?.id
                                                    }
                                                    onChange={()=>handleChange(row)}
                                                    disabled={
                                                        (row.id === rp?.id && state.value !== "stepOne") ||
                                                        (row.id === tp?.id && state.value !== "stepTwo") ||
                                                        (row.id === ep?.id && state.value !== "stepThree")
                                                    }
                                                    classes={{disabled: classes.disabledCheckbox}}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.cells}>
                                                <Typography variant="body2" >
                                                    {row.name}
                                                </Typography>    
                                            </TableCell>
                                            <TableCell className={classes.cells} >
                                                <div className={classes.column}>
                                                    <Typography variant="body2">
                                                        {parseDate(row.createdAt)}
                                                    </Typography>
                                                    <Typography 
                                                        variant="body2" 
                                                        className={classes.time}
                                                        >
                                                        {parseTime(row.createdAt)}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.cells}>
                                                Dataset
                                            </TableCell>
                                            <TableCell className={classes.cells}>
                                                {getStatus(row.ssvariants)}
                                            </TableCell>
                                    </TableRow>
                                </Fragment>
                        )
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination 
                            classes={{toolbar: classes.pagination}}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            count={(rows && rows.length) || 0} 
                            colSpan={6}
                            rowsPerPageOptions={[]}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        <div className={classes.row}>
        {state.value === 'done' ? 
            null 
            : 
            <Button 
                variant="outlined" 
                onClick={()=>handleContinue()} 
                className={classes.outlinedBtn}
                color="primary"
                disabled={!rp || !tp || !ep}
            >
                Continue
            </Button> 
        }  
        </div>                
    <Divider/>
    </div>
</Paper>
    )
}

const mapStateToProps = (state) => {
    return {
        target: state.updates.target,
        models: state.updates.models,
        modelPhenotypes: state.updates.modelPhenotypes
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getPhenoModel: (data) => {dispatch(getPhenoModel(data))},
        storePhenotypeIds: (data) => {dispatch(storePhenotypeIds(data))},
        storePhenoToAssign: (data) => {dispatch(storePhenoToAssign(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(TargetPhenotypes)