import API from '../api'
import errorHandler from '../reducers/errorHandler'
import { getMocks } from './mock.js'
import FileDownload from 'js-file-download';

export const storePermissions = (data) => async (dispatch, getState) => {
  dispatch({ type: 'STORE_PERMISSIONS', payload: data })
}

export const storeToken = (data) => async (dispatch, getState) => {
  dispatch({ type: 'STORE_TOKEN', payload: data })
}

export const getModels = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/models', data )
    } else {
      response = getMocks()['/api/models']
     }
    dispatch({ type: 'MODELS_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'MODELS_LOADED_ERROR'}, dispatch)
  }
}

export const getAllModels = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/models/all', data )
    } else {
      response = getMocks()['/api/models/all']
     }
    dispatch({ type: 'ALL_MODELS_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'ALL_MODELS_LOADED_ERROR'}, dispatch)
  }
}

export const getPublicModels = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/library', data )
    } else {
      response = getMocks()['/api/library']
     }
    dispatch({ type: 'PUBLIC_MODELS_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'PUBLIC_MODELS_LOADED_ERROR'}, dispatch)
  }
}

export const getAllPhenotypes = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/phenotypes/all', data )
    } else {
      response = getMocks()['/api/phenotypes/all']
     }
    dispatch({ type: 'ALL_PHENOTYPES_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'ALL_PHENOTYPES_LOADED_ERROR'}, dispatch)
  }
}

export const getAllTargets = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/targets/all', data )
    } else {
      response = getMocks()['/api/targets/all']
     }
    dispatch({ type: 'ALL_TARGETS_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'ALL_TARGETS_LOADED_ERROR'}, dispatch)
  }
}

export const getAllNotebooks = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/notebooks/all', data )
    } else {
      response = getMocks()['/api/notebooks/all']
     }
    dispatch({ type: 'ALL_NOTEBOOKS_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'ALL_NOTEBOOKS_LOADED_ERROR'}, dispatch)
  }
}

export const getPhenotypes = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/phenotypes', data )
    } else {
      response = getMocks()['/api/phenotypes']
     }
    dispatch({ type: 'PHENOTYPES_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'PHENOTYPES_LOADED_ERROR'}, dispatch)

  }
}

export const getPhenotype = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/phenotypes/' + data.phenotypeId )
    } else {
      response = getMocks()['/api/phenotypes/']
     }
    dispatch({ type: 'PHENOTYPE_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'PHENOTYPE_LOADED_ERROR'}, dispatch)
  }
}

export const updatePhenotype = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/phenotype', data )
    } else {
      response = getMocks()['/api/phenotype']
     }
    dispatch({ type: 'PHENOTYPE_UPDATED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'PHENOTYPE_UPDATED_ERROR'}, dispatch)
  }
}

export const deletePhenotype = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.delete('/api/phenotype', data )
    } else {
      response = getMocks()['/api/phenotype']
     }
    dispatch({ type: 'PHENOTYPE_DELETED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'PHENOTYPE_DELETED_ERROR'}, dispatch)
  }
}

export const createPhenotype = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/api/phenotype', data )
    } else {
      response = getMocks()['/api/phenotype']
     }
    dispatch({ type: 'PHENOTYPE_CREATED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'PHENOTYPE_CREATED_ERROR'}, dispatch)
  }
}

export const getPhenoModel = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/model/phenotypes/' + data.modelId )
    } else {
      response = getMocks()['/api/model/phenotypes/:modelId']
     }
    dispatch({ type: 'PHENOMODEL_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'PHENOMODEL_LOADED_ERROR'}, dispatch)
  }
}

export const storePhenotypeIds = (data) => async (dispatch, getState) => {
  dispatch({ type: 'STORE_PHENO_IDS', payload: data })
}

export const storePhenotypeS3Names = (data) => async (dispatch, getState) => {
  dispatch({ type: 'STORE_PHENO_S3', payload: data })
}

export const storePhenoToAssign = (data) => async (dispatch, getState) => {
  dispatch({ type: 'STORE_PHENO_TO_ASSIGN', payload: data })
}

export const deleteDataset = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.delete('/api/phenotype/dataset', data)
    } else {
      response = getMocks()['/api/phenotype/dataset']
     }
    dispatch({ type: 'DATASET_DELETED', payload: data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'DATASET_DELETED_ERROR'}, dispatch)
  }
}

export const archivePhenotype = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/phenotype/archive', data)
    } else {
      response = getMocks()['/api/phenotype/archive']
     }
    dispatch({ type: 'PHENOTYPE_ARCHIVED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'PHENOTYPE_ARCHIVED_ERROR'}, dispatch)
  }
}

export const clonePhenotype = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/api/phenotype/clone', data)
    } else {
      response = getMocks()['/api/phenotype/clone']
     }
    dispatch({ type: 'PHENOTYPE_CLONED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'PHENOTYPE_CLONED_ERROR'}, dispatch)
  }
}

export const resetPhenotype = (data) => async (dispatch, getState) => {
  dispatch({ type: 'RESET_PHENOTYPE', payload: data })
}

export const resetTarget = (data) => async (dispatch, getState) => {
  dispatch({ type: 'RESET_TARGET', payload: data })
}

export const resetNewTarget = (data) => async (dispatch, getState) => {
  dispatch({ type: 'RESET_NEW_TARGET', payload: data })
}

export const resetPublicModel = (data) => async (dispatch, getState) => {
  dispatch({ type: 'RESET_PUBLIC_MODEL', payload: data })
}

export const resetPhenoToAssign = (data) => async (dispatch, getState) => {
  dispatch({ type: 'RESET_PHENO_TO_ASSIGN', payload: data })
}

export const getTargets = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/targets', data )
    } else {
      response = getMocks()['/api/targets']
    }
    dispatch({ type: 'TARGETS_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'TARGETS_LOADED_ERROR'}, dispatch)

  }
}

export const getTarget = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/targets/' + data.targetId )
    } else {
      response = getMocks()['/api/targets/']
     }
    dispatch({ type: 'TARGET_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'TARGET_LOADED_ERROR'}, dispatch)
  }
}

export const createTarget = (data) => async (dispatch, getState) => {
  let response
  try {
    response = await API.post('/api/target', data )  
    dispatch({ type: 'TARGET_CREATED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'TARGET_CREATED_ERROR'}, dispatch)
  }
}

export const updateTarget = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/target', data )
    } else {
      response = getMocks()['/api/target']
     }
    dispatch({ type: 'TARGET_UPDATED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'TARGET_UPDATED_ERROR'}, dispatch)
  }
}

export const archiveTarget = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/target/archive', data )
    } else {
      response = getMocks()['/api/target/archive']
     }
    dispatch({ type: 'TARGET_ARCHIVED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'TARGET_ARCHIVED_ERROR'}, dispatch)
  }
}

export const deleteTarget = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.delete('/api/target', data )
    } else {
      response = getMocks()['/api/target']
     }
    dispatch({ type: 'TARGET_DELETED', payload: response.data, targetId: data.targetId })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'TARGET_DELETED_ERROR'}, dispatch)
  }
}

export const resetWarning = (data) => async (dispatch, getState) => {
  dispatch({ type: 'RESET_WARNING', payload: data })
}

export const getNotebooks = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/notebooks', data )
    } else {
      response = getMocks()['/api/notebooks']
     }
    dispatch({ type: 'NOTEBOOKS_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'NOTEBOOKS_LOADED_ERROR'}, dispatch)
  }
}

export const getNotebook = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/notebooks/' + data.notebookId )
    } else {
      response = getMocks()['/api/notebooks']
     }
    dispatch({ type: 'NOTEBOOK_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'NOTEBOOK_LOADED_ERROR'}, dispatch)
  }
}

export const updateNotebook = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/notebook', data )
    } else {
      response = getMocks()['/api/notebook']
     }
    dispatch({ type: 'NOTEBOOK_UPDATED', payload: response.data, notebookId: data.notebookId })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'NOTEBOOK_UPDATED_ERROR'}, dispatch)
  }
}

export const archiveNotebook = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/notebook/archive', data )
    } else {
      response = getMocks()['/api/notebook/archive']
     }
    dispatch({ type: 'NOTEBOOK_ARCHIVED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'NOTEBOOK_ARCHIVED_ERROR'}, dispatch)
  }
}

export const deleteNotebook = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.delete('/api/notebook', data )
    } else {
      response = getMocks()['/api/notebook']
     }
    dispatch({ type: 'NOTEBOOK_DELETED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'NOTEBOOK_DELETED_ERROR'}, dispatch)
  }
}


export const getArchive = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/archive', data )
    } else {
      response = getMocks()['/api/archive']
     }
    dispatch({ type: 'ARCHIVE_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'ARCHIVE_LOADED_ERROR'}, dispatch)
  }
}
 
export const storeUser = (data) => async (dispatch, getState) => {
  dispatch({ type: 'STORE_USER', payload: data })
}

export const login = (data) => async (dispatch, getState) => {
  let response
  try {
      response = await API.post('/papi/login', data )
      dispatch({ type: 'LOGIN', payload: response.data })
  } catch(err) {
    errorHandler(err.toJSON().message, {type: 'LOGIN_ERROR'}, dispatch)
  }
}

export const resetError = (data) => async (dispatch, getState) => {
  dispatch({ type: 'RESET_ERROR', payload: data })
}

export const createModel = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/api/model', data )
    } else {
      response = getMocks()['/api/model']
     }
    dispatch({ type: 'MODEL_CREATED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'MODEL_CREATED_ERROR'}, dispatch)
  }
}

export const getModel = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/models/' + data.modelId )
    } else {
      response = getMocks()['/api/models']
     }
    dispatch({ type: 'MODEL_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'MODEL_LOADED_ERROR'}, dispatch)
  }
}

export const updateModel = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/model', data )
    } else {
      response = getMocks()['/api/model']
     }
    dispatch({ type: 'MODEL_UPDATED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'MODEL_UPDATED_ERROR'}, dispatch)
  }
}

export const cloneModel = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/api/model/clone', data )
    } else {
      response = getMocks()['/api/model/clone']
     }
    dispatch({ type: 'MODEL_CLONED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'MODEL_CLONED_ERROR'}, dispatch)
  }
}

export const uploadPublicModel = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/api/model/browse', data )
    } else {
      response = getMocks()['/api/model/browse']
     }
    dispatch({ type: 'PUBLIC_MODEL_UPLOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'PUBLIC_MODEL_UPLOADED_ERROR'}, dispatch)
  }
}

export const deleteModel = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.delete('/api/model', data )
    } else {
      response = getMocks()['/api/model']
     }
     dispatch({ type: 'MODEL_DELETED', payload: response.data, modelId: data.modelId })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'MODEL_DELETED_ERROR'}, dispatch)
  }
}

export const archiveModel = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/model/archive', data )
    } else {
      response = getMocks()['/api/model/archive']
     }
    dispatch({ type: 'MODEL_ARCHIVED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'MODEL_ARCHIVED_ERROR'}, dispatch)
  }
}

export const downloadFile = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.download('/api/download', data )
      FileDownload(response.data, `${data.key}`);
    } else {
      response = getMocks()['/api/download']
     }
    dispatch({ type: 'FILE_DOWNLOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'FILE_DOWNLOADED_ERROR'}, dispatch)
  }
}

// to fix
export const downloadZipFile = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.download('/api/download/zipfile', data )
      FileDownload(response.data, `${data.name}-${data.id}`);
    } else {
      response = getMocks()['/api/download/zipfile']
     }
    dispatch({ type: 'ZIP_FILE_DOWNLOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'ZIP_FILE_DOWNLOADED_ERROR'}, dispatch)
  }
}

export const getNotifications = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/notifications', data )
    } else {
      response = getMocks()['/api/notifications']
     }
    dispatch({ type: 'NOTIFICATIONS_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'NOTIFICATIONS_LOADED_ERROR'}, dispatch)
  }
}

export const readNotification = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/notification', data )
    } else {
      response = getMocks()['/api/notification']
     }
    dispatch({ type: 'NOTIFICATION_READ', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'NOTIFICATION_READ_ERROR'}, dispatch)
  }
}

export const getFreeDiskspace = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/statistics/diskspace', data )
    } else {
      response = getMocks()['/api/statistics/diskspace']
     }
    dispatch({ type: 'DISKSPACE_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'DISKSPACE_LOADED_ERROR'}, dispatch)
  }
}

export const getTargetsChart = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/statistics/status', data )
    } else {
      response = getMocks()['/api/statistics/status']
     }
    dispatch({ type: 'TARGETS_CHART_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'TARGETS_CHART_LOADED_ERROR'}, dispatch)
  }
}

export const getUserInfo = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/users/' + data.userId )
    } else {
      response = getMocks()['/api/users/']
     }
    dispatch({ type: 'USER_INFO_LOADED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'USER_INFO_LOADED_ERROR'}, dispatch)
  }
}

export const updateUserInfo = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/user', data )
    } else {
      response = getMocks()['/api/user']
     }
    dispatch({ type: 'USER_INFO_UPDATED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'USER_INFO_UPDATED_ERROR'}, dispatch)
  }
}

export const toggleNotifications = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/user/togglesettings', data )
    } else {
      response = getMocks()['/api/user/togglesettings']
     }
    dispatch({ type: 'NOTIFICATIONS_TOGGLED', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'NOTIFICATIONS_TOGGLED_ERROR'}, dispatch)
  }
}

// get back to this when we get the ability to run the target
export const runTarget = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/api/target/run', data )
    } else {
      response = getMocks()['/api/notification']
     }
    dispatch({ type: 'TARGET_RAN', payload: response.data })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'TARGET_RAN_ERROR'}, dispatch)
  }
}

export const getNotebookFile = (data) => async (dispatch, getState) => {
  let response
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.download('/api/download', data )
    } else {
      response = getMocks()['/api/download']
     }
    dispatch({ type: 'NB_FILE_LOADED', payload: response.data, name: data.name })
  } catch (err) {
    errorHandler(err.toJSON().message, {type: 'NB_FILE_LOADED_ERROR'}, dispatch)
  }
}

export const resetImage = (data) => async (dispatch, getState) => {
    dispatch({ type: 'IMAGE_RESET' })
}