import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux"
import { 
    getModels, 
    createPhenotype, 
} from '../actions'
import { 
    Typography, 
    Table, 
    TableBody, 
    TableHead, 
    TableRow, 
    TableCell, 
    TablePagination, 
    TableContainer, 
    Paper,
    TableFooter,
    Checkbox,
    Button,
    Divider
} from "@material-ui/core";
import Search from './search'
import { parseDate, parseTime } from "../commons";
import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from "react-router-dom";

import { BiSave } from 'react-icons/bi';
const saveIcon = <BiSave size={20} />;


const useStyles = makeStyles((theme, props) => ({
    container: {
        margin: "20px 50px",
        // width: "100%"
    },
    table: {
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        marginTop: "10px",
    },
    root: {
        border: "0px",
        verticalAlign: "top",
        "&:nth-of-type(2n)": {
            backgroundColor: theme.palette.background.row,
        },
        "&:hover": {
            background: "rgba(34, 170, 161, 0.08)"
        }
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around"
    },
    cells: {
        border: "0px",
        height: "52px"
    },
    pagination: {
        borderTop: "1px solid",
        borderColor: theme.palette.borderColor.onSurface
    },
    time: {
        color: theme.palette.onSurface.disabled
    },
    titleRow: {
        display: "flex",
        marginLeft: "50px",
        marginTop: "20px",
        alignItems: "baseline"
    },
    dataRow: {
        display: "flex",
        height: "100%"
    },
    optionsRow: {
        display: "flex",
        width: "20%",
        justifyContent: "space-around"
    },
    icon: {
        marginLeft: "30px",
        height: "30px"
    },
    textarea: {
        minWidth: "50%"
    },
    btnRow: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        alignItems: "center"
    },
    secondaryBtn: {
        height: "48px",
        "&:hover": {
            background: theme.palette.secondary.main
        }
    },
    options: {
        display: "block",
        margin: "30px"
    },
    errorBanner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 30px",
        width: "100%",
        height: "48px",
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    outlinedBtn: {
        margin: "20px 0px",
        height: "40px",
        width: "130px",
        borderColor: theme.palette.borderColor.onSurface
    },
    row: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end"
    },
    disabledTitle: {
        color: theme.palette.background.grey,
        marginTop: "20px"
    },
    title: {
        color: theme.palette.onSurface.mediumEmphasis,
        marginBottom: "20px"
    }
  }));

function CreateNewPheno(props) {
    const history = useHistory()
    const classes = useStyles()
    const [rows, setRows] = useState([])
    const [validated, setValidated] = useState()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [checked, setChecked] = useState(null)


useEffect(()=>{
    props.getModels()
}, [])

useEffect(()=>{
    const validated = []
    props.models?.map((model)=>{
        if (model.ssdistribution === 1 && model.validation === 1) {
            validated.push(model)
        }
        return validated
    })
    setValidated(validated)
    setRows(validated)
}, [props.models])

useEffect(()=>{
    if (props.phenotype) {
        history.push(`/phenotypes/${props.phenotype?.id}`)
    }
}, [props.phenotype])

const getMatches = (matches, input) => {
    setRows(input !== "" ? matches : validated)
}

const getStatus = (status) => {
    if (status === 0) {
        return (
            <Typography 
                variant="subtitle2" 
                className={classes.statusRunning}
            >
                Pending
            </Typography>)
    } else if (status === 1) {
        return <CheckIcon color="primary"/>
    } else if (status === 2) {
        return (
            <Typography variant="subtitle2" color="error">
                Failed
            </Typography>
        )  
    }
}

const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
};

const columns = [
    "Select",
    "Model name", 
    "Uploaded", 
    "SBML Check", 
    "Steady-State Distribution"
]

const handleChange = (id) => {
    if (id === checked) {
        setChecked(null)
    } else {
        setChecked(id)
    }
}

const handleSelect = () => {
    props.createPhenotype({ModeldbId: checked})
}

return (
    <Paper
        elevation={0}
        className={classes.column}
    >       
        <div className={classes.btnRow}>
            <Button 
                variant="contained" 
                color="primary" 
                disableElevation
                className={classes.secondaryBtn}
                disabled
            >
                <span>{saveIcon}&nbsp;</span>
                <Typography variant="button">
                    Save
                </Typography>
            </Button>
        </div>
        <div className={classes.titleRow}>          
            <Typography 
                variant="h5" 
            >
                UNTITLED PHENOTYPE
            </Typography>
        </div>
        <div className={classes.container}>
            <Typography 
                variant="h6"
                className={classes.title}
            >
                1. Select One Model
            </Typography>
            <Search rows={validated} sendMatches={getMatches} type="models"/>
            <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow >
                            {columns.map((column)=>{
                                return (
                                    <TableCell 
                                            variant="head"
                                            align={"left"} 
                                            key={column}
                                            width={column === "Select" ? "50px" : "150px"}                                   
                                        >
                                            <Typography variant="subtitle2">
                                                {column}                                        
                                            </Typography>
                                        </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row)=>{                         
                                return(
                                    <Fragment key={row.id} >
                                        <TableRow 
                                            classes={{root: classes.root}}                                        
                                        >
                                            <TableCell 
                                                    className={classes.cells} 
                                                >
                                                <Checkbox
                                                        color="primary"
                                                        checked={row.id === checked}
                                                        onChange={()=>handleChange(row.id)}
                                                    />
                                                </TableCell>
                                                <TableCell 
                                                    className={classes.cells} 
                                                    >
                                                    <Typography variant="body2" >
                                                    {row.name}
                                                    </Typography>                            
                                                </TableCell>
                                                <TableCell className={classes.cells} >
                                                    <div className={classes.column}>
                                                        <Typography variant="body2">
                                                            {parseDate(row.createdAt)}
                                                        </Typography>
                                                        <Typography 
                                                            variant="body2" 
                                                            className={classes.time}
                                                        >
                                                            {parseTime(row.createdAt)}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.cells}>
                                                    {getStatus(row.validation)}
                                                </TableCell>
                                                <TableCell className={classes.cells}>
                                                    {getStatus(row.ssdistribution)}
                                                </TableCell>
                                        </TableRow>
                                    </Fragment>
                            )
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination 
                                classes={{toolbar: classes.pagination}}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                count={(rows && rows.length) || 0} 
                                colSpan={6}
                                rowsPerPageOptions={[]}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <div className={classes.row}>
            <Button 
                variant="outlined" 
                onClick={()=>handleSelect()} 
                className={classes.outlinedBtn}
                color="primary"
                disabled={!checked}
            >
                Select
            </Button>   
            </div>                
        <Divider/>
        <Typography 
            variant="h6"
            className={classes.disabledTitle}
        > 
            2. Add pertubations
        </Typography>
        </div>
    </Paper>
    )
}

const mapStateToProps = (state) => {
    return {   
        phenotype: state.updates.phenotype,
        models: state.updates.models,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getModels: (data) => {dispatch(getModels(data))},
        createPhenotype: (data) => {dispatch(createPhenotype(data))},
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewPheno)

