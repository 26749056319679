import { useEffect, useState } from 'react';
import API from '../api'
import { connect } from 'react-redux'
import clsx from 'clsx';
import { login, storeToken, storePermissions, storeUser } from '../actions'
import { makeStyles } from '@material-ui/core/styles';
import { 
    Button, 
    Typography, 
    InputAdornment, 
    FilledInput, 
    FormControl, 
    InputLabel, 
    IconButton, 
    Fab
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { FaUser, FaKey } from 'react-icons/fa'; // mauro 
import { StylesContext } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.onPrimary.dark,  
        width: "400px",  // mauro: it was "338px"
        "&:after": {
            borderColor: "gray" // it was "#22AAA1"
        },
        "&:hover": {
            background: "rgba(255, 255, 255, 0.08)",
        },
    },
    focused: {
        color: "gray",
        background: "rgba(255, 255, 255, 0.08)",
    },
    button: {
        width: "140px",   // mauro: it was "338px"
        height: "48px",
        color: "white",
        borderRadius: "unset",
        background: theme.palette.primary.main,
        marginTop: "30px",
        marginBottom: "10px",
        "&:hover": {
            background: theme.palette.primary.main,
        },
    },  
    label: {
        color: "gray",
        "&.Mui-focused": {
            color: theme.palette.primary.main
        },
        "&.MuiInputLabel-shrink": {
            color: theme.palette.primary.main
        }
    },
    marginLarge: {
        marginBottom: "30px" // it was "30px"
    },
    margin: {
        marginBottom: "10px" // it was "30px"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline"
    },
    caption: {
        color: "rgba(255, 255, 255, 0.5)",
        width: "338px"
    },
    bold: {
        margin: "10px",
        cursor: "pointer",
        color: "gray"
    },
    link: {
        marginTop: "20px",
        color: theme.palette.primary.main,
        textDecoration: "none",   // mauro: don't like underline
        cursor: "pointer"
    }
  }));

function Login(props) {
    const classes = useStyles()
    const [showPass, setShowPass] = useState()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loggedOutUser, setLoggedOutUser] = useState({})

    useEffect(()=>{
        if (props.loggedInUser) {
            API.setAuthToken(props.loggedInUser.token)
            localStorage.setItem('token', props.loggedInUser.token)
            localStorage.setItem('permissions', JSON.stringify(props.loggedInUser.permissions))
            localStorage.setItem('user', JSON.stringify(props.loggedInUser.user))
            props.storePermissions(props.loggedInUser.permissions)
            props.storeUser(props.loggedInUser.user)
            props.storeToken(props.loggedInUser.token)
        } else {
           const loggedOutUser = localStorage.getItem('user')
           setLoggedOutUser(JSON.parse(loggedOutUser))
        }   
    }, [props.loggedInUser])

    const handleClick = () => {
        setShowPass(!showPass)
    }

    const handleLogin = () => {
        props.login({email: loggedOutUser ? loggedOutUser.email : email, password})
    }

    const handleForgetUser = () => {
        localStorage.removeItem('user')
        setLoggedOutUser()
    }

    // mauro: added link to website
    const handleCreateAccount = () => {
        window.open("https://netabolics.ai/#contacts", "_blank")
    }

    return (
        <>
            {loggedOutUser ? 
                <>
                    <Typography 
                        variant="body1" 
                        className={classes.margin}
                    >
                        Hello, {loggedOutUser.name} {loggedOutUser.lastName}!
                    </Typography>
                </>
                :
                <>
                    <Typography 
                        variant="body1"
                        className={classes.marginLarge}
                    >
                            Sign in with your email address.
                    </Typography>
                    <FormControl variant="filled" className={classes.margin}>
                        <InputLabel 
                            className={classes.label} 
                        >
                            <FaUser /> Email Address
                        </InputLabel>
                        <FilledInput
                            variant="filled" 
                            classes={{root: classes.root, focused: classes.focused}}
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                            name="email"
                        />
                    </FormControl>
                </>
            }
            <FormControl variant="filled" className={classes.margin}>
                <InputLabel className={classes.label}><FaKey /> Password</InputLabel>
                <FilledInput
                    variant="filled" 
                    classes={{root: classes.root, focused: classes.focused}}
                    type={showPass ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClick}
                                edge="end"
                            >
                                {showPass ? 
                                    <Visibility color="primary"/> 
                                    : 
                                    <VisibilityOff color="primary"/>
                                }
                            </IconButton>
                        </InputAdornment>
                    }
                    value={password}
                    onChange={(e)=>{setPassword(e.target.value)}}
                    name="password"
                />
            </FormControl>
            <Button 
                className={classes.button}
                onClick={()=>{handleLogin()}}
            >
                Sign in
            </Button>
            {loggedOutUser ? 
                <>
                    <div>
                        <Typography 
                            variant="body2"
                            display="inline"
                        >
                            Not you?
                        </Typography> 
                        &nbsp;
                        <Typography 
                            variant="subtitle2" 
                            onClick={()=>handleForgetUser()}
                            className={classes.link}
                            display="inline"
                        >
                            Use a different account.
                        </Typography>
                    </div>
                    <Typography 
                        variant="body2"
                        className={classes.caption}
                    >
                        To protect your privacy, remember to sign you out when you're done.
                    </Typography>
                </>
                : 
                null
            }
            <div>
                <Typography 
                    variant="body2"
                    display="inline"
                >
                    No account?
                </Typography> 
                &nbsp;
                <Typography 
                    variant="subtitle2" 
                    onClick={()=>handleCreateAccount()}
                    className={classes.link}
                    display="inline"
                >
                    Contact us.
                </Typography>
            </div>
        </>
    )
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {   
        loggedInUser: state.updates.loggedInUser,
        user: state.updates.user
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        login: (data) => {dispatch(login(data))},
        storeToken: (data) => {dispatch(storeToken(data))},
        storeUser: (data) => {dispatch(storeUser(data))},
        storePermissions: (data) => {dispatch(storePermissions(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Login)