import { connect } from 'react-redux'
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    width: "338px",
    height: "48px",
    color: theme.palette.primary.contrastText,
    margin: "20px 0"
  },
  primary: {
    background: theme.palette.primary.main,
    marginBottom: "30px",
    "&:hover": {
      // add specific rule for hover
      background: theme.palette.primary.main,
    }
  },
  secondary: {
    background: theme.palette.primary.variant,
    // add specific rule for hover
    "&:hover": {
      background: theme.palette.primary.variant,
    }
  }
}));

function LoginSignup(props) {
  const classes = useStyles();
  const history = useHistory()

  return (
    <>
        <Typography variant="body1">
          Do you already have an account?
        </Typography>
        <Button 
            className={clsx(classes.button, classes.primary)} 
            onClick={()=>{history.push("/login")}}
        >
          <Typography variant="button">LOG IN</Typography>
        </Button>
        <Typography variant="body1">
          No account?
        </Typography>
        <Button className={clsx(classes.button, classes.secondary)}>
          <Typography variant="button">Contact us</Typography>
        </Button>
    </>
   );
}


const mapStateToProps = (state /*, ownProps*/) => {
  return {
  
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginSignup)
