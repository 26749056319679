import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { useParams } from 'react-router';
import clsx from 'clsx';
import { getNotebook, downloadZipFile } from '../actions'
import { parseDate } from '../commons';
import { 
    Typography,
    Button,
    CircularProgress
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import exportIcon from './assets/export-icon.svg'
//import shareIcon from './assets/share-icon.svg'

import { RiShareLine } from 'react-icons/ri';
const shareIcon = <RiShareLine />;


const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "500px",
        backgroundColor: theme.palette.background.row,
        marginTop: "10px",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly"
    },
    column: {
        display: "flex",
        flexDirection: "column"
    },
    statusColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "35%",
        padding: "0px 60px",
        marginTop: "2%"
    },
    downloadBtn: {
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        marginBottom: "20px",
        height: "36px",
        color: theme.palette.primary.main
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    status: {
        marginLeft: "30px"
    },
    pending: {
        color: theme.palette.surface.overlay
    },
    exportIcon: {
        marginRight: "10px"
    },
    primaryBtn: {    
        height: "36px",
        margin: "5px 0px",
        "&:hover": {
            background: theme.palette.primary.main
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.onPrimary.disabled 
        }      
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    }
  }));

function NotebookStatus(props) {
const params = useParams()
const classes = useStyles()
const [status, setStatus] = useState()

useEffect(()=>{
    // if (props.status === "done") {
        props.getNotebook({notebookId: params.notebookId})
    // }
}, [props.status])

useEffect(()=>{
    setStatus(props.notebook?.Target?.status)
}, [props.notebook])

const getStatusIcon = () => {
    if (props.status === "done" || status === 1) {
        return <CheckIcon color="primary"/>
    } else if (props.status === "pending" || status === 0) {
        return <CircularProgress variant="determinate" value={100} className={classes.pending}/>
    } else if (props.status === "in progress" || status === 3) {
        return <CircularProgress variant="indeterminate" color="secondary"/>
    } else if (props.status === "error" || status === 2) {
        return <CloseIcon color="error"/>
    }
}

const getStatus = () => {
    if (status === 1) {
        return <Typography 
                    className={classes.status} 
                    color="primary" 
                    variant="subtitle2"
                >
                    DONE
                </Typography>
    } else if (status === 0) {
        return <Typography 
                    className={classes.status} 
                    variant="subtitle2"
                >
                    PENDING
                </Typography>
    } else if (status === 2) {
        return <Typography 
                    className={classes.status} 
                    color="error" 
                    variant="subtitle2"
                >
                    FAILED
                </Typography>
    } else {
        return <Typography 
                    className={classes.status} 
                    color="primary" 
                    variant="subtitle2"
                >
                    IN PROGRESS
                </Typography>
    }

}

const handleDownload = (filename) => {
    props.downloadZipFile({key: filename, name: props.notebook?.name, id: props.notebook?.id})
}

    return (
        <div className={classes.statusColumn}>
        <Button
            className={classes.primaryBtn} 
            color="primary" 
            variant="contained" 
            disableElevation
            fullWidth
            disabled={props.status === 1 ? false : true}
        >
            <span>{shareIcon}&nbsp;</span>
            <Typography variant="button">
                Share in Public Library
            </Typography>
        </Button>
        <div className={classes.container}>
            <Typography variant="h6">
                Status
            </Typography>
            <div className={classes.row}>
            <Typography 
                variant="subtitle2"
                className={classes.mediumEmphasis}
            >
                Target analysis
            </Typography>
            <Typography 
                variant="subtitle2"
                className={clsx(classes.status, classes.mediumEmphasis)}
            >
                Validation
            </Typography>
            </div>
            {getStatusIcon()}
            <div className={classes.row}>
                <Typography  variant="body2">
                    Your target is 
                </Typography>
                {getStatus()}           
            </div>
            <div className={classes.row}>
                <Typography variant="body2">
                    Run at
                </Typography>
                <Typography variant="body2" className={classes.status}>
                    {parseDate(props.notebook?.createdAt)}
                </Typography>
            </div>
            <div className={classes.row}>
                <img 
                    src={exportIcon} 
                    alt="export icon" 
                    className={classes.exportIcon}
                />
            <Typography variant="subtitle2" >
                Export
            </Typography>
            </div>
            <div className={classes.column}>
                <Button
                    disabled={props.notebook?.status === 1 ? false : true}
                    className={classes.downloadBtn}
                    onClick={()=>handleDownload(props.notebook?.s3name)}
                >
                    <Typography variant="button">
                        Results
                    </Typography>
                </Button>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = (state ) => {
    return {   
        notebook: state.updates.notebook,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getNotebook: (data) => {dispatch(getNotebook(data))},
        downloadZipFile: (data) => {dispatch(downloadZipFile(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(NotebookStatus)