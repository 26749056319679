import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { useParams } from 'react-router';
import { getModel, downloadFile, downloadZipFile } from '../actions'
import { 
    Typography,
    Button,
    CircularProgress
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import exportIcon from './assets/export-icon.svg'


const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "500px",
        backgroundColor: theme.palette.background.row,
        marginTop: "30px",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly"
    },
    column: {
        display: "flex",
        flexDirection: "column"
    },
    downloadBtn: {
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        marginBottom: "20px",
        height: "36px",
        color: theme.palette.primary.main
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    status: {
        marginLeft: "10px"
    },
    pending: {
        color: theme.palette.surface.overlay
    },
    exportIcon: {
        marginRight: "10px"
    }
  }));

function ModelStatus(props) {
const params = useParams()
const classes = useStyles()
const [ssdistributionOutput, setSsdistributionOutput] = useState()
const [validationOutput, setValidationOutput] = useState()

useEffect(()=>{ 
    setSsdistributionOutput(props.model?.data?.output?.distribution)
    setValidationOutput(props.model?.data?.output?.validation)
}, [props.model, props.publicModel])

useEffect(()=>{
    if (props.uploadStatus === "done") {
        props.getModel({modelId: params.modelId})
    }
}, [props.uploadStatus])

const getStatusIcon = (status) => {
    if (status === "done") {
        return <CheckIcon color="primary"/>
    } else if (props.uploadStatus === "in progress" || status === "in progress") {
        return <CircularProgress variant="indeterminate" color="secondary"/>
    } else if (status === "pending") {
        return <CircularProgress variant="determinate" value={100} className={classes.pending}/>
    }  else if (status === "error") {
        return <CloseIcon color="error"/>
    }
}

const getStatus = (val, ssd) => {
    if (ssd === "done" && val === "done") {
        return <Typography 
                    className={classes.status} 
                    color="primary" 
                    variant="subtitle2"
                >
                    DONE
                </Typography>
    } else if (props.uploadStatus === "in progress") {
        return  <Typography 
                    className={classes.status} 
                    color="primary" 
                    variant="subtitle2"
                >
                    IN PROGRESS
                </Typography>
    } else if (props.uploadStatus === "pending" || (ssd === "pending" && val === "pending")) {
        return <Typography 
                    className={classes.status} 
                    variant="subtitle2"
                >
                    PENDING
                </Typography>
    } else if (props.uploadStatus === "error" || (ssd === "error" && val === "error")) {
        return <Typography 
                    className={classes.status} 
                    color="error" 
                    variant="subtitle2"
                >
                    FAILED
                </Typography>
    } 

}

const handleDownload = (filename) => {
    props.downloadFile({key: filename})
}

const handleZipDownload = (files) => {
    let fileArr = []
    if (files.type === "flux") {
        files.ssdistributionOutput.map((file)=>{
            if (file.includes("fdistr")) {
                return fileArr.push(file)
            }
            return fileArr
        })
    } else if (files.type === "conc") {
        files.ssdistributionOutput.map((file)=>{
            if (file.includes("cdistr")) {
                return fileArr.push(file)
            }
            return fileArr
        })
    }
    props.downloadZipFile({key: fileArr, name: props.model?.name, id: props.model?.id})
}

    return (
        <div className={classes.container}>
            <Typography variant="h6">
                Status
            </Typography>
            <Typography variant="subtitle2">
                SBML Check
            </Typography>

            {getStatusIcon(props.validationStatus)}

            <Typography variant="subtitle2">
                Steady-State Distributions
            </Typography>

            {getStatusIcon(props.distributionStatus)}

            <div className={classes.row}>
                <Typography  variant="subtitle2">
                    Your model is 
                </Typography>
                    {getStatus(props.validationStatus, props.distributionStatus)}   
            </div>
            <div className={classes.row}>
                <img 
                    src={exportIcon} 
                    alt="export icon" 
                    className={classes.exportIcon}
                />
            <Typography variant="subtitle2" >
                Export
            </Typography>
            </div>
            <div className={classes.column}>
                <Button
                    disabled={
                        validationOutput ? 
                        false 
                        : 
                        true
                    }
                    className={classes.downloadBtn}
                    onClick={()=>handleDownload(props.model?.s3name)}
                >
                    <Typography variant="button">
                        SBML
                    </Typography>
                </Button>
                <Button
                    disabled={ssdistributionOutput ? false : true}
                    className={classes.downloadBtn}
                    onClick={()=>handleZipDownload({ssdistributionOutput, type: "flux"})}
                >
                    <Typography variant="button">
                        Fluxes
                    </Typography>
                </Button>
                <Button
                    disabled={validationOutput ? false : true}
                    className={classes.downloadBtn}
                    onClick={()=>handleZipDownload({ssdistributionOutput, type: "conc"})}
                >
                    <Typography variant="button">
                        Concentrations
                    </Typography>
                </Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state ) => {
    return {   
        model: state.updates.model,
        publicModel: state.updates.publicModel
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getModel: (data) => {dispatch(getModel(data))},
        downloadFile: (data) => {dispatch(downloadFile(data))},
        downloadZipFile: (data) => {dispatch(downloadZipFile(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ModelStatus)