import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './themes/theme'
import './App.css';

import Layout from './components/layout'
import LoginLayout from './components/loginlayout'
import Dashboard from './components/dashboard'
import ModelsList from './components/modelslist'
import PhenoList from './components/phenolist'
import TargetList from './components/targetlist'
import NotebookList from './components/notebooklist'
import Model from './components/model'
import Notebook from './components/notebook';
import Phenotype from './components/phenotype';
import Archive from './components/archive'
import ErrorSnackbar from './components/errorsnackbar'
import Target from './components/target'
import NewTarget from './components/newtarget';
import Settings from './components/settings';
import CreateNewPheno from './components/createnewpheno';

import Documentation from './components/documentation'; // mauro
import Support from './components/support'; // mauro



const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function App(props) {

  return (
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path="/settings" name="Settings" render={propsInner => props.token?<Layout {...props}><Settings  /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/documentation" name="Documentation" render={propsInner => props.token?<Layout {...props}><Documentation  /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/support" name="Support" render={propsInner => props.token?<Layout {...props}><Support  /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/archive" name="Archive" render={propsInner => props.token?<Layout {...props}><Archive /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/notebooks/:notebookId" name="Notebook" render={propsInner => props.token?<Layout {...props}><Notebook /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/notebooks" name="Notebooks" render={propsInner => props.token?<Layout {...props}><NotebookList /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/targets/new" name="Targets" render={propsInner => props.token?<Layout {...props}><NewTarget /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/targets/:targetId" name="Target" render={propsInner => props.token?<Layout {...props}><Target /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/targets" name="Targets" render={propsInner => props.token?<Layout {...props}><TargetList /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/phenotypes/new" name="New Phenotype" render={propsInner => props.token?<Layout {...props}><CreateNewPheno /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/phenotypes/:phenotypeId" name="Phenotype" render={propsInner => props.token?<Layout {...props}><Phenotype /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/phenotypes" name="Phenotypes" render={propsInner => props.token?<Layout {...props}><PhenoList /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/models/:modelId" name="Model" render={propsInner => props.token?<Layout {...props}><Model /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/models" name="Models" render={propsInner => props.token?<Layout {...props}><ModelsList /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/login" name="Login" render={propsInner => props.token?<Layout {...props}><Dashboard /></Layout>:<LoginLayout {...propsInner} />} />
          <Route path="/" name="Home" render={propsInner => props.token?<Layout {...props}><Dashboard /></Layout>:<LoginLayout {...propsInner} />} />
        </Switch>
        <ErrorSnackbar />
      </React.Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    token: state.updates.token
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
