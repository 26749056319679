import { connect } from 'react-redux'
import { useLocation } from 'react-router';
import LoginSignup from './loginsignup';
import Login from './login'
import { makeStyles } from '@material-ui/core/styles';
import molecule1 from './assets/molecule-bg.svg'
import molecule2 from './assets/molecule-bg2.svg'
//import logo from './assets/netabolics-logo.svg'
import { Typography } from '@material-ui/core';

import { FaGem } from 'react-icons/fa'; // mauro 
//import { IoDiamond } from 'react-icons/io'; // mauro 
//import { BiNetworkChart } from 'react-icons/bi'; // mauro
//import { GiGems } from 'react-icons/gi'; // mauro 

import logo from './assets/Netabolics-Logo-v3-B-small.png' // mauro

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.primary.light,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  bottomIcon: {
    filter: "invert(62%) sepia(0%) saturate(0%) hue-rotate(153deg) brightness(86%) contrast(88%)", // mauro: done with https://codepen.io/sosuke/pen/Pjoqqp (set target color)
    // note that to have a perfect match, you have to set fill-opacity=1 in the svg file
    width: "800px",
    position: "fixed",
    right: 0,
    bottom: 0
  },
  topIcon: {
    filter: "invert(62%) sepia(0%) saturate(0%) hue-rotate(153deg) brightness(86%) contrast(88%)",
    width: "800px",    
    position: "fixed",
    left: 0,
    top: 0
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.dark,
    paddingTop: "60px",
    paddingBottom: "40px",
    paddingLeft: "80px",
    paddingRight: "80px",
    borderRadius: "5px",
    zIndex: "10"
  },
  logo: {
    width: "200px",
    marginBottom: "30px"
  },
  platformTitle: {
    marginBottom: "20px"
  },
  platformIcon: {
    /* color: theme.palette.primary.main */
  },
  imgLogo: {
    verticalAlign: "sub"
  }
}));



function LoginLayout(props) {
  const classes = useStyles();
  const location = useLocation()

  // mauro: modified something below (no logo; no need for the loginsignup page)

  return (
    <div className={classes.container}>
      <img src={molecule1} alt="netabolics icon" className={classes.topIcon}/> 
      <div className={classes.loginContainer}>
        {/* <img src={logo} alt="netabolics logo" className={classes.logo}/> */}
        <Typography
          variant="h4"
          className={classes.platformTitle}
        >
          <img className={classes.imgLogo} height="48px" src={logo} alt="logo" />
          {/* &reg;  &nbsp;<FaGem className={classes.platformIcon}/>&nbsp; 
          <b>IntelliGEM&trade;</b>*/}
        </Typography>
        {/* {location.pathname === "/login" ? <Login /> : <LoginSignup />} */}
        <Login />
      </div>
      <img src={molecule2} alt="netabolics icon" className={classes.bottomIcon}/>
    </div>
   );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
  
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginLayout)
