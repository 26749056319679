import { useEffect, useState } from 'react'
import { InputBase } from '@material-ui/core'
import searchIcon from './assets/search-icon.svg'
import { makeStyles } from '@material-ui/core/styles';
import { parseDate } from '../commons'

const useStyles = makeStyles((theme) => ({
    input: {
        height: "48px",
        background: theme.palette.background.grey,
        paddingLeft: "20px"
    },
    icon: {
        marginRight: "20px"
    }
  }));

function Search(props) {
    const classes = useStyles();
    const [input, setInput] = useState("");


    useEffect(()=>{
        const matches = []
        props.rows?.map((row) => {
            if (row.name?.toLowerCase().includes(input.toLowerCase())) {
                matches.push(row)
            } else if (row.data?.notes?.toLowerCase().includes(input.toLowerCase())) {
                matches.push(row)
            } else if (parseDate(row.createdAt)?.includes(input)) {
                matches.push(row)
            } else if (row.Key?.toLowerCase().includes(input.toLowerCase())) {
                matches.push(row)
            } else {
                return matches
            }
        })
        props.sendMatches(matches, input)
    }, [input])

    return (
        <InputBase
            className={classes.input}
            startAdornment={
                <img 
                    className={classes.icon} 
                    src={searchIcon} 
                    alt="search-icon"
                />
            }
            fullWidth
            placeholder={`Search ${props.type}`}
            type="string"
            value={input}
            onChange={(e) => setInput(e.target.value)}
        />
    )
}

export default Search