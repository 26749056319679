import { format, parse } from 'date-format-parse';

export const parseDate = (item) => {
  const d = parse(item, "YYYY-MM-DDTHH:mm:ss")
  return format(d, "DD/MM/YYYY")
}

export const parseDateFormat = (item) => {
  const d = parse(item, "YYYY-MM-DDTHH:mm:ss")
  return format(d, "YYYY-MM-DD")
}

export const parseDateAndTime = (item) => {
  const d = parse(item, "YYYY-MM-DDTHH:mm:ss")
  return format(d, "DD/MM/YYYY hh:mm:ss")
}

export const parseTime = (item) => {
  const d = parse(item, "YYYY-MM-DDTHH:mm:ss")
  return format(d, "hh:mm")
}

export const hasPermissions = (ownedPermissions, toCheck) => {
    for (var resource in toCheck) { // Go through all resource permissions to check
      if (ownedPermissions[resource]) {
        for (let permission of toCheck[resource]) {
          if (!ownedPermissions[resource].includes(permission)) {
            return false
            }
          }
      } else { // Does not have this resource...
        return false
      }
    }
  
    return true
  }

 export const getPercent = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
 }

const Units = ['bytes', 'Kb', 'Mb', 'Gb'];
   
export const getByteSize = (x) => {
    let l = 0, n = parseInt(x, 10) || 0;
        while(n >= 1024 && ++l){
            n = n/1024;
        }        
    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + Units[l]);
}

export const isFlag = (value, flag) => {
  return value & flag
}