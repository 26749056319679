import { 
    MenuList, 
    MenuItem, 
    Typography, 
    Collapse 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { connect } from 'react-redux';
import { readNotification } from '../actions'
import { isFlag } from '../commons';

const useStyles = makeStyles((theme) => ({
    success: {
        background: "#17C948",
        color: "white",
        height: "40px",
        width: "40px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "20px"
    },
    failure: {
        background: "#FF004B",
        color: "white",
        height: "40px",
        width: "40px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "20px"
    },
    row: {
        display: "flex",
        alignItems: "center",
        width: "auto",
        height: "100px"
    },
    collapse: {
        overflow: "visible"
    }
}));

const SUCCESS = 2

function Notifications(props) {
    const classes = useStyles()

    const handleClick = (id) => {
        props.readNotification({notificationId: id})
    }

    return (
        <Collapse 
            is={props.isOpen ? "true" : "false"} 
            className={classes.collapse}
            placement=""
        >
            <MenuList>
                {props.unreadNotifications?.map((notification)=>{
                    return (
                    <MenuItem 
                        className={classes.row} 
                        key={notification.id}
                    >
                        <div 
                            className={
                                isFlag(notification.status, SUCCESS) ? 
                                    classes.success 
                                    : 
                                    classes.failure
                            }
                            onClick={()=>handleClick(notification.id)}
                        >
                            {
                                isFlag(notification.status, SUCCESS) ? 
                                    <CheckIcon /> 
                                    : 
                                    <CloseIcon />
                            }
                        </div>
                        <Typography variant="body2">
                            {notification.text}
                        </Typography>
                    </MenuItem>
                    )
                })
                }
            </MenuList>
        </Collapse>
    )
}

const mapStateToProps = (state ) => {
    return {
        unreadNotifications: state.updates.unreadNotifications
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        readNotification: (data) => {dispatch(readNotification(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);