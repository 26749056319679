import {Fragment, useState} from 'react'
import { 
    TableCell, 
    TableRow, 
    Button, 
    Typography, 
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ActionsMenu from './actionsmenu'
import { parseDate, parseTime, isFlag } from '../commons'
import actionsIcon from './assets/actions-icon.svg'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "20px 50px"
    },
    table: {
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        marginTop: "30px",
    },
    root: {
        border: "0px",
        "&:nth-of-type(2n)": {
            backgroundColor: theme.palette.background.row,
        },
        "&:hover": {
            background: theme.palette.background.rowHover,
        }
    },
    rootExpanded: {
        background: theme.palette.background.rowHover,
        verticalAlign: "top",
        height: "52px",
        whiteSpace: "wrap"
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around"
    },
    row: {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-start",
        width: "50%",
    },
    cells: {
        border: "0px",
        height: "52px",
    },
    visible: {
        visibility: "visible",
        cursor: "pointer",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "baseline"
    },
    hidden: {
        visibility: "hidden",
        cursor: "pointer",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "baseline"
    },
    cellCollapsed: {
        paddingBottom: 0, 
        paddingTop: 0,
        borderBottom: 0
    },
    rowExpanded: {
        padding: "20px",
        background: theme.palette.background.rowHover,
    },
    rowActive: {
        background: theme.palette.background.rowHover,
    },
    pagination: {
        borderTop: "1px solid",
        borderColor: theme.palette.borderColor.onSurface
    },
    statusRunning: {
        color: theme.palette.secondary[600]
    },
    time: {
        color: theme.palette.onSurface.disabled
    },
    details: {
        marginLeft: "50px",
        marginBottom: "20px"
    },btnRow: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        alignItems: "center"
    },
    optionsRow: {
        display: "flex",
        width: "20%",
        marginLeft: "40px"
    },
    primaryBtn: {
        height: "48px",
        justifySelf: "flex-end",
        "&:hover": {
            background: theme.palette.primary.main
        }
    },
    notes: {
        overflow: "hidden",
        maxWidth: "100px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        border: "0px",
        alignSelf: "flex-start"
    },
    chip: {
        background: theme.palette.secondary[600],
        color: "white",
        borderRadius: "unset",
        "&:hover": {
            background: theme.palette.secondary[600],
            color: "white",
        }
    },
    expandIcon: {
        alignSelf: "center",
    },
    iconRoot: {
        height: "0.8em"
    },
    rowName: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    listTitle: {
        fontFamily: theme.typography.listTitle.fontFamily,
        fontWeight: theme.typography.listTitle.fontWeight,
        fontSize: theme.typography.listTitle.fontSize,
        lineHeight: theme.typography.listTitle.lineHeight,
        letterSpacing: theme.typography.listTitle.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis,
        marginBottom: "20px"
    },
    actionsBtn: {
        "&:hover": {
            backgroundColor: "unset"
        }
    }
  }));

function ArchiveRows({rows, type, typeName, unarchive, deleteType}){
const classes = useStyles()
const [rowId, setRowId] = useState()
const [isMenuOpen, setIsMenuOpen] = useState(false)
const [anchorEl, setAnchorEl] = useState(null)
const [isArchived, setIsArchived] = useState()
const Archived = 1

const handleToggle = (e, id, origin) => {
    setRowId(id)
    setIsArchived(isFlag(origin, Archived))
    setIsMenuOpen(!isMenuOpen)
    setAnchorEl(e.currentTarget)
}
  
  return (
      <>
        {rows?.map((row)=>{
            return(
                <Fragment key={row.id}>
            <TableRow 
                key={row.id} 
                classes={{root: classes.root}}  
                >
                    <TableCell 
                        className={clsx(classes.column, classes.cells)} 
                    >
                        <Typography variant="body2" >
                            {row.name}
                        </Typography>
                    </TableCell>
                        
                    <TableCell className={classes.cells} >
                        <div className={classes.column}>
                            <Typography variant="body2">
                                {parseDate(row.createdAt)}
                            </Typography>
                            <Typography 
                                variant="body2" 
                                className={classes.time}
                            >
                                {parseTime(row.createdAt)}
                            </Typography>
                        </div>
                    </TableCell>
                    <TableCell className={classes.cells}>
                    {isFlag(row.origin, Archived) ? "No": "Yes"}
                    </TableCell>
                    <TableCell className={classes.cells}>
                        {typeName}
                    </TableCell>
                    <TableCell align="center" className={classes.cells} >
                        <Button onClick={(e)=>handleToggle(e, row.id, row.origin)}>
                             <img src={actionsIcon} alt="actions icon" /> 
                        </Button>
                        <ActionsMenu 
                            id={rowId}
                            type={type}
                            isMenuOpen={isMenuOpen} 
                            anchorEl={anchorEl} 
                            handleToggle={(e)=>handleToggle(e)}
                            delete={()=>deleteType(row.id, type)}
                            unarchive={unarchive}
                            isArchived={isArchived}
                            clone={false}
                            rename={false}
                        />
                    </TableCell>
            </TableRow>
        </Fragment>
    )
})}
</>
    )
}
export default ArchiveRows