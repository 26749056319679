import {
    MenuList,
    Popper,
    MenuItem,
    ClickAwayListener,
    Paper,
    Typography
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    menu: {
        width: "128px",
        boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.012), 0px 5px 5px rgba(0, 0, 0, 0.02)",
        zIndex: "100"
    }
  }));

function ActionsMenu(props) {
    const history = useHistory()
    const classes = useStyles()

    return (
        <Popper
            open={props.isMenuOpen}
            anchorEl={props.anchorEl}
            transition
            disablePortal
            placement="bottom-start"
            className={classes.menu}
        >
            <Paper>
                <ClickAwayListener onClickAway={props.handleToggle}>
                    <MenuList>
                        <MenuItem onClick={()=>history.push(`/${props.type}/${props.id}`)}>
                            <Typography variant="body2">Open</Typography>
                        </MenuItem>
                        {props.type === "targets" || props.type === "notebooks" || props.clone === false ? 
                            null
                            :
                            <MenuItem onClick={()=>{props.clone(props.id)}}>
                                <Typography variant="body2">Clone</Typography>
                            </MenuItem>
                        }
                        {props.rename === false ?
                            null
                            :
                            <MenuItem >
                                <Typography variant="body2" onClick={()=>{props.setRename(props.id)}}>Rename</Typography>
                            </MenuItem>
                        }
                        {props.type === "notebooks" ? 
                        null
                        :
                        <MenuItem onClick={()=>{props.delete(props.id)}}>
                            <Typography variant="body2">Delete</Typography>
                        </MenuItem>
                        }
                        {props.unarchive ? 
                            <MenuItem onClick={()=>{props.unarchive(props.id, props.type)}}>
                                <Typography variant="body2">
                                    {props.isArchived === 1 ? 'Unarchive' : 'Archive'}
                                </Typography>
                            </MenuItem>
                            :
                            <MenuItem onClick={()=>{props.archive(props.id)}}>
                                <Typography variant="body2">Archive</Typography>
                            </MenuItem>
                        }
                    </MenuList>
                </ClickAwayListener>
            </Paper>
        </Popper>
    )
}

export default ActionsMenu